import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { sucessToast } from '../utils/toast'

function Sidenav() {
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const emailDataStatic = JSON.parse(localStorage.getItem("sgfinserv"))?.email
  const navigate = useNavigate()
  const showLogout = () => {
    // localStorage.setItem("sgEmail",JSON.stringify(emailDataStatic))
     localStorage.removeItem("sgfinserv")
     sucessToast("Logout successfully")
     navigate("/")
  }

  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];
if(path[parseInt(path.length) - 1] !== "client-start-scoring" && path[parseInt(path.length) - 2] !== "client-scoring-financial" && path[parseInt(path.length) - 2] !== "client-scoring-non-financial"){
  localStorage.removeItem("startScoringData")
}
  // console.log("pathMatch: " + path[parseInt(path.length) - 2])
  return (
   <>
   <div className="SidenavBar">
   <ul>
     {adminData?.userType === "STAFF" ?
      <>
    
    {adminData?.permission?.some(
            (ele) =>
              ele.name == "Dashboard" &&
              (ele.read == true || ele.fullAccess == true)
          ) && (
            <li className={pathMatch == "dashboard" ? "active" : ""}>
            <Link to="/dashboard">
              <span>
                <i className="fa fa-users" />
              </span>{" "}
              Dashboard
            </Link>
          </li>
          )}
     {/* {adminData?.permission?.some(
            (ele) =>
              ele.name == "dashboard" &&
              (ele.read == true || ele.fullAccess == true)
          ) && ( */}
            {/* <li className={pathMatch == "dashboard" || pathMatch=="subadmin-dashboard"? "active" : ""} style={{"padding":"20px"}} >
              <Link to="/dashboard">
                <span className="Icon">
                  <i class="fa fa-tachometer"></i>
                </span>
                <span className="Text"> Dashboard</span>
              </Link>
            </li> */}
          {/* )} */}

{adminData?.permission?.some(
            (ele) =>
              ele.name == "Anchor-Management" &&
              (ele.read == true || ele.fullAccess == true)
          ) && (
            <li className={pathMatch == "anchor-management" ? "active" : ""}>
            <Link to="/anchor-management">
              <span>
                <i className="fa fa-users" />
              </span>{" "}
              Anchor Management
            </Link>
          </li>
          )}

   
{adminData?.permission?.some(
            (ele) =>
              ele.name == "Client-Management" &&
              (ele.read == true || ele.fullAccess == true || ele.approve_disapprove == true)
          ) && (
            <li className={pathMatch == "client-management" ? "active" : ""}>
            <Link to="/client-management">
              <span>
                <i className="fa fa-male" />
              </span>{" "}
              Client Management
            </Link>
          </li>
          )}
    



    {adminData?.permission?.some(
            (ele) =>
              ele.name == "Score-Management" &&
              (ele.read == true || ele.fullAccess == true)
          ) && (
            <li className={pathMatch == "score-management" ? "active" : ""}>
            <Link to="/score-management">
              <span>
                <i className="fa fa-file-text-o" />
              </span>{" "}
              Score &amp; Weightage Management
            </Link>
          </li>
          )}


{adminData?.permission?.some(
            (ele) =>
              ele.name == "Staff-Management" &&
              (ele.read == true || ele.fullAccess == true)
          ) && (
            <li className={pathMatch == "staff-management" ? "active" : ""}>
            <Link to="/staff-management">
              <span>
                <i className="fa fa-usd" />
              </span>{" "}
              Staff Management
            </Link>
          </li>
          )}



{/* {adminData?.permission?.some(
            (ele) =>
              ele.name == "setting" &&
              (ele.read == true || ele.fullAccess == true)
          ) && ( */}
            <li className={pathMatch == "setting" ? "active" : ""}>
      <Link to="/setting">
        <span>
          <i className="fa fa-cog" />
        </span>{" "}
        Settings
      </Link>
    </li>
          {/* )} */}

   
 
    <li>
      <a onClick={showLogout}>
        <span>
          <i className="fa fa-sign-out" />
        </span>{" "}
        Logout
      </a>
    </li>
 
     </>  :
      <>
    
     
    
    <li className={pathMatch == "dashboard" ? "active" : ""}>
      <Link to="/dashboard">
        <span>
          <i className="fa fa-tachometer" />
        </span>{" "}
        Dashboard
      </Link>
    </li>
    
    <li className={pathMatch == "anchor-management" ? "active" : ""}>
      <Link to="/anchor-management">
        <span>
          <i className="fa fa-users" />
        </span>{" "}
        Anchor Management
      </Link>
    </li>
    <li className={pathMatch == "client-management" ? "active" : ""}>
      <Link to="/client-management">
        <span>
          <i className="fa fa-male" />
        </span>{" "}
        Client Management
      </Link>
    </li>
    <li className={pathMatch == "score-management" ? "active" : ""}>
      <Link to="/score-management">
        <span>
          <i className="fa fa-file-text-o" />
        </span>{" "}
        Score &amp; Weightage Management
      </Link>
    </li>
    <li className={pathMatch == "staff-management" ? "active" : ""}>
      <Link to="/staff-management">
        <span>
          <i className="fa fa-usd" />
        </span>{" "}
        Staff Management
      </Link>
    </li>
    <li className={pathMatch == "setting" ? "active" : ""}>
      <Link to="/setting">
        <span>
          <i className="fa fa-cog" />
        </span>{" "}
        Settings
      </Link>
    </li>
    <li>
      <a onClick={showLogout}>
        <span>
          <i className="fa fa-sign-out" />
        </span>{" "}
        Logout
      </a>
    </li>
 
     </>

     }
  </ul>
</div>

   </>
  )
}

export default Sidenav