import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { userAnchorListAction } from '../redux/actions/anchorAction';
import Loader from './Loader';
import Pagination from 'react-js-pagination'
import { checkArray } from '../utils/CheckType';
import Header from './Header';
import Sidenav from './Sidenav';
import * as XLSX from 'xlsx';
import { DownloadTableExcel } from 'react-export-table-to-excel';
const init = {
    search:""
}
function Anchoruserlist() {
    const[iState, updateState] = useState(init);
    const{search } = iState;
    const anchorRef = useRef();
    const [activePage, updateActivePage] = useState(1);
    const [serialNo, updateSerialNo] = useState(10);
   
   const dispatch = useDispatch();
   const navigate = useNavigate();
   let loader = false;

    
    // const{anchorUserListData, loader} = useSelector((state ) => state.mainReducer)
    const [anchorUserListData, setAnchorListData] = useState({})
    // console.log('anchorUserListData', anchorUserListData)
    const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
    useEffect(() => {
        dispatch(userAnchorListAction()).then((res) =>{
          setAnchorListData(res)        
        })
      },[])
     
     
      const handlePageChange = (pageNumber) => {
       let ser = pageNumber * 10;   
       updateSerialNo(ser);
       updateActivePage(pageNumber);
       dispatch(userAnchorListAction(search,pageNumber)).then((res) =>{
        setAnchorListData(res)        
      })
     }

     const handleSearch = (e) => {
        dispatch(userAnchorListAction(e.target.value)).then((res) =>{
          setAnchorListData(res)        
        })
        updateState({...iState, search:e.target.value})
      }
    console.log('---------->',anchorUserListData?.data?.[0]?.totalCount[0]?.count)

      const[downloadDataExcel, setDownloadDataDownload] = useState([])

      const downloadCSV = async () => {
        try {
      
          dispatch(userAnchorListAction("","",anchorUserListData?.data?.[0]?.totalCount[0]?.count)).then((res) => {
            console.log("---------->", res)
            setDownloadDataDownload(res)
               setTimeout(() =>{
                var table_elt = document.getElementById("my-table-id");
  
                var workbook = XLSX.utils.table_to_book(table_elt);
                // const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(downloadDataExcel);
                XLSX.utils.book_append_sheet(workbook, worksheet, 'AnchorUserList');
                XLSX.writeFile(workbook, 'AnchorUserList.xlsx');
                setDownloadDataDownload([])
               }, 1000)
           
  
  
          })
         
          
        } catch (error) {
          console.error('Error downloading CSV:', error);
        }
      };
  return (
   <>
   <Header/>
   <Sidenav/>
    <div className="WrapperArea">
  <div className="WrapperBox">
  <div className="TitleBox">
            <h4 className="Title">
              <Link to="/dashboard" className="backArrow">
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </Link>{" "}
             User Management
            </h4>
          </div>
    <div className="Small-Wrapper">
    {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "staff-management" &&
                      ele.fullAccess == true
                  ) && (
                    <>
                     <div className="TitleBox">
                    <h4 className="Title">User List</h4>
                    <div className="sms">
         <a onClick={downloadCSV}  className="TitleLink"> Download CSV </a>
 
                   </div>
                    {/* <Link className="TitleLink" to="/staff-management-add">
                      Add New Staff
                    </Link> */}
                  </div>
                
                    </>
                   
                  )}
                </>
              ) : (
                <> 
                     <div className="TitleBox">
        <h4 className="Title">User List</h4>
        <div className="sms">
         
        

                  <a onClick={downloadCSV}  className="TitleLink"> Download CSV </a>

               
       </div>
        {/* <Link className="TitleLink" to="/staff-management-add">
          Add New Staff
        </Link> */}
      </div>
     
                </>
              )}
             
      
      <div className="Filter">
                    <div className="form-group">
                      <label>Search</label>
                      <input type="text" className="form-control" placeholder="Search" onChange={handleSearch} />
                    </div>
                  </div>
                  
      <div className="TableList">
        <table style={{ width: "100%" }} >
          <thead>
          <tr>
              <th>S. No</th>
              <th>User ID</th>
              <th>User Full Name</th>
              <th>Designation</th>
              <th>Email Address</th>
              <th>Registered On</th>
              <th>Anchor Company Name</th>
             
             
            </tr>
          </thead>
          <tbody>
            {loader ? <>
            <Loader/>
            </>
            :

            checkArray(anchorUserListData?.data?.[0]?.paginationData) ? 
            anchorUserListData?.data?.[0]?.paginationData?.map((ele,i) => {    
                // checkArray(anchorUserListData?.data) ? 
                // anchorUserListData?.data?.map((ele,i) => {          
              return(
                <>
                            <tr>
                            <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.user_number}</td>
              <td>{ele?.name}</td>
              <td>{ele?.designation}</td>
              <td>{ele?.email}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              <td>{ele?.company}</td>
              {/* <td>{checkArray(ele?.anchorArray) && ele?.anchorArray?.map((ele,i) => (
                  <>
                  {i == 0 ? (
                    <> {ele}</>
                  ) : (
                    <>, {ele}</>
                  )}
                </>

                      
            ))}</td> */}
              {/* <td>
                {ele?.status === "ACTIVE"?  <span className="Green">Active</span> :  <span className="Red">Inactive</span>}
               
              </td> */}
             
            </tr>

                </>
              )
            })
            : <p>No Data Found</p>
            }
           
           
           
          </tbody>
        </table>
      </div>
      <div class="pagination">
              <p>
                {" "}
                {/* Total records : <span>{anchorUserListData?.result?.[0]?.totalCount?.[0]?.count}</span> */}
              </p>
            
              <ul>
                {anchorUserListData?.data?.[0]?.totalCount?.[0]?.count && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={anchorUserListData?.data?.[0]?.totalCount?.[0]?.count}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                 )} 
              </ul>
             
            </div>
    
   
    </div>
  </div>
</div>

<div style={{display:"none"}} className="TableList">
        <table id='my-table-id' style={{ width: "100%" }} >
          <thead>
          <tr>
              <th>S. No</th>
              <th>User ID</th>
              <th>User Full Name</th>
              <th>Designation</th>
              <th>Email Address</th>
              <th>Registered On</th>
              <th>Anchor Company Name</th>
             
             
            </tr>
          </thead>
          <tbody>
            {loader ? <>
            <Loader/>
            </>
            :

            checkArray(downloadDataExcel?.data?.[0]?.paginationData) ? 
            downloadDataExcel?.data?.[0]?.paginationData?.map((ele,i) => {    
                // checkArray(anchorUserListData?.data) ? 
                // anchorUserListData?.data?.map((ele,i) => {          
              return(
                <>
                            <tr>
                            <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.user_number}</td>
              <td>{ele?.name}</td>
              <td>{ele?.designation}</td>
              <td>{ele?.email}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              <td>{ele?.company}</td>
              {/* <td>{checkArray(ele?.anchorArray) && ele?.anchorArray?.map((ele,i) => (
                  <>
                  {i == 0 ? (
                    <> {ele}</>
                  ) : (
                    <>, {ele}</>
                  )}
                </>

                      
            ))}</td> */}
              {/* <td>
                {ele?.status === "ACTIVE"?  <span className="Green">Active</span> :  <span className="Red">Inactive</span>}
               
              </td> */}
             
            </tr>

                </>
              )
            })
            : <p>No Data Found</p>
            }
           
           
           
          </tbody>
        </table>
      </div>
   </>
  )
}

export default  Anchoruserlist