import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { dashobardAction, getLowestListAction, getTopListAction } from '../redux/actions/authAction';
import Loader from './Loader';
import { checkArray } from '../utils/CheckType';
const init = {
  dashboardList:[]
}
function Dashboard() {
  const[iState, updateState] = useState(init)
  const{dashboardList} =iState
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {lowestListData, loader,topListData} = useSelector((state ) => state.mainReducer)
  
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  useEffect(() => {

    dispatch(dashobardAction()).then((res) => {
      updateState({...iState,dashboardList:res})
      
    })



    dispatch(getLowestListAction())
    dispatch(getTopListAction())
    // dispatch(dashobardAction((res)=> {}))
  },[])
  return (
    <>
    <Header/>
    <Sidenav/>
    <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">Dashboard Statistics</h4>
      </div>
{ adminData?.userType == "STAFF" ? 
           <>
              <div className="DashboardBox">
        <ul>
        {adminData?.permission?.some(
            (ele) =>
              ele.name == "staff-management" &&
              (ele.read == true || ele.fullAccess == true)
          ) ?  (
            <li>
            <Link to="/staff-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalSubadmin}</h3>
                <p>Total No. of Sub-Admins </p>
              </aside>
            </Link>
          </li>
          ) : <>
          <li>
            <Link to="/staff-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalSubadmin}</h3>
                <p>Total No. of Sub-Admins </p>
              </aside>
            </Link>
          </li>
          </>}
         



          {/* {adminData?.permission?.some(
            (ele) =>
              ele.name == "staff-management" &&
              (ele.read == true || ele.fullAccess == true)
          ) ?  (
            <li>
            <Link to="/staff-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalSgFlUser}</h3>
                <p>Total No. of SGFL Users</p>
              </aside>
            </Link>
          </li>
          ) : <>
          <li>
            <Link to="/staff-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalSgFlUser}</h3>
                <p>Total No. of SGFL Users</p>
              </aside>
            </Link>
          </li>
          </>} */}




          
          {adminData?.permission?.some(
            (ele) =>
              ele.name == "anchor-management" &&
              (ele.read == true || ele.fullAccess == true)
          ) ?  (
            <li>
            <Link to="/anchor-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalAnchor}</h3>
                <p>Total No. of Anchors</p>
              </aside>
            </Link>
          </li>
          ) : <>
          <li>
            <Link to="/anchor-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalAnchor}</h3>
                <p>Total No. of Anchors</p>
              </aside>
            </Link>
          </li>
          </>}





          {adminData?.permission?.some(
            (ele) =>
              ele.name == "anchor-management" &&
              (ele.read == true || ele.fullAccess == true)
          ) ?  (
            <li>
          <Link to="/anchor-user-list">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalAnchorUsers}</h3>
                <p>Total No. of Anchors (Users) </p>
              </aside>
              </Link>
          </li>
          ) : <>
          <li>
            <a >
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalAnchorUsers}</h3>
                <p>Total No. of Anchors (Users) </p>
              </aside>
            </a>
          </li>
          </>}




          {adminData?.permission?.some(
            (ele) =>
              ele.name == "client-view-score" &&
              (ele.read == true || ele.fullAccess == true)
          ) ?  (
            
          <li>
          <Link to="/client-view-score">
            <aside>
              <span className="Icon">
                <i className="fa fa-user-secret" aria-hidden="true" />
              </span>
              <h3>{dashboardList?.totalDealors}</h3>
              <p>Total No. of Dealers</p>
            </aside>
          </Link>
        </li>
          ) : <>
          
          <li>
            <Link to="/client-view-score">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalDealors}</h3>
                <p>Total No. of Dealers</p>
              </aside>
            </Link>
          </li>
          </>}
         
       
        </ul>
      </div>
      <div className="ScoringCountList">
        <ul>

        {adminData?.permission?.some(
            (ele) =>
              ele.name == "client-score-pending" &&
              (ele.read == true || ele.fullAccess == true)
          ) ?  (
            
            <li>
            <Link to="/client-score-pending">
              <span className="Icon">
                <img src="images/Driver.png" />
              </span>
              <span className="Text">Scoring Pending for approval</span>
              <span className="Count">{dashboardList?.totalPendingScore}</span>
              <span className="Badge">{dashboardList?.totalPendingScore}</span>
            </Link>
          </li>
          ) : <>
          
          <li>
            <Link to="/client-score-pending">
              <span className="Icon">
                <img src="images/Driver.png" />
              </span>
              <span className="Text">Scoring Pending for approval</span>
              <span className="Count">{dashboardList?.totalPendingScore}</span>
              <span className="Badge">{dashboardList?.totalPendingScore}</span>
            </Link>
          </li>
          </>}
        


          {adminData?.permission?.some(
            (ele) =>
              ele.name == "client-score-pending" &&
              (ele.read == true || ele.fullAccess == true)
          ) ?  (
            
            <li>
            <Link to="">
              <span className="Icon">
                <img src="images/Driver1.png" />
              </span>
              <span className="Text">Users Pending for approval</span>
              <span className="Count">{dashboardList?.totalUserForPending}</span>
              <span className="Badge">{dashboardList?.totalUserForPending}</span>
            </Link>
          </li>
          ) : <>
          
          <li>
            <Link to="/anchor-pending-approval">
              <span className="Icon">
                <img src="images/Driver1.png" />
              </span>
              <span className="Text">Users Pending for approval</span>
              <span className="Count">{dashboardList?.totalUserForPending}</span>
              <span className="Badge">{dashboardList?.totalUserForPending}</span>
            </Link>
          </li>
          </>}
         
        </ul>
      </div>
           </>
           
             : 
             <>
              <div className="DashboardBox">
        <ul>
          <li>
            <Link to="/staff-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalSubadmin}</h3>
                <p>Total No. of Sub-Admins </p>
              </aside>
            </Link>
          </li>
          {/* <li>
            <Link to="/staff-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalSgFlUser}</h3>
                <p>Total No. of SGFL Users</p>
              </aside>
            </Link>
          </li> */}
          <li>
            <Link to="/anchor-management">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalAnchor}</h3>
                <p>Total No. of Anchors</p>
              </aside>
            </Link>
          </li>
          <li>
            <Link to = "/anchor-user-list" >
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalAnchorUsers}</h3>
                <p>Total No. of Anchors (Users) </p>
              </aside>
            </Link>
          </li>
          <li>
            <Link to="/client-view-score">
              <aside>
                <span className="Icon">
                  <i className="fa fa-user-secret" aria-hidden="true" />
                </span>
                <h3>{dashboardList?.totalDealors}</h3>
                <p>Total No. of Dealers</p>
              </aside>
            </Link>
          </li>
        </ul>
      </div>
      <div className="ScoringCountList">
        <ul>
          <li>
            <Link to="/client-score-pending">
              <span className="Icon">
                <img src="images/Driver.png" />
              </span>
              <span className="Text">Scoring Pending for approval</span>
              <span className="Count">{dashboardList?.totalPendingScore}</span>
              <span className="Badge">{dashboardList?.totalPendingScore}</span>
            </Link>
          </li>
          <li>
            <Link to="/anchor-pending-approval">
              <span className="Icon">
                <img src="images/Driver1.png" />
              </span>
              <span className="Text">Users Pending for approval</span>
              <span className="Count">{dashboardList?.totalUserForPending}</span>
              <span className="Badge">{dashboardList?.totalUserForPending}</span>
            </Link>
          </li>
        </ul>
      </div>
             </>
             }
      
   
      {/* <div className="Filter Filter1">
        <div className="form-group">
          <label>Search Anchor </label>
          <input
            type="search"
            className="form-control"
            placeholder="Search Here"
          />
          <span>
            <i className="fa fa-search" aria-hidden="true" />
          </span>
        </div>
      </div> */}
      <div className="TopScoringList">
        <div className="TableList">
          <div className="TitleBox">
            <h4 className="Title">Top 10 Performing Dealers</h4>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Dealer Name</th>
                <th>Parent Company</th>
                <th>Avg Score</th>
              </tr>
            </thead>
            <tbody>
             

            {loader ? <>
            <Loader/>
            </>
            :

            checkArray(topListData?.result?.[0]?.paginationData) ? 
            topListData?.result?.[0]?.paginationData?.map((ele,i) => {  
                  
              return(
                <>
  <tr>
                <td>{i+1}</td>
                <td>{ele?.dealerName}</td>
                <td>{ele?.anchorData?.[0]?.companyName}</td>
                <td>{ele?.score}</td>
              </tr>
                </>
              )
            })
            : <p>No data found</p>
            }
            
         
            </tbody>
          </table>
        </div>
        <div className="TableList">
          <div className="TitleBox">
            <h4 className="Title">Lowest performing Dealers</h4>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Dealer Name</th>
                <th>Parent Company</th>
                <th>Avg Score</th>
              </tr>
            </thead>
            <tbody>

            {loader ? <>
            <Loader/>
            </>
            :

            checkArray(lowestListData?.result?.[0]?.paginationData) ? 
            lowestListData?.result?.[0]?.paginationData?.map((ele,i) => {  
              console.log("elelelelee",ele)         
              return(
                <>
  <tr>
                <td>{i+1}</td>
                <td>{ele?.dealerName}</td>
                <td>{ele?.anchorData?.[0]?.companyName}</td>
                <td>{ele?.score}</td>
              </tr>
                </>
              )
            })
            : <p>No data found</p>
            }
           
           
            
           
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default Dashboard