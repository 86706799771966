import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { errorToast, sucessToast } from '../utils/toast'
import { sendOtpOnEmailAction } from '../redux/actions/authAction'
 
const init = {
email:"",
errors:{}
}
const Loginforgot = () => {
  const [iState, updateState] = useState(init);
  const {email, errors} = iState;
  const adminemail = JSON.parse(localStorage.getItem("sgEmail"))

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleValidation = () => {
    let error = {};
    let formIsValid = true;
    if(!email){
      error.emailError = "Please Enter Your Email"
      formIsValid = false;
    } else {
      if(email){
        if(email){
          if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
              // error.emailError = "Please Enter A Valid Email";
              // formIsValid = false;
            }
      } 
      }

  
    }
      updateState({...iState, errors:error})
      return formIsValid
    }
  
  
    const handleEmail = (e) => {
      const {name,value} = e.target;
     updateState({...iState, [name] :value})
  
    }
    const sendOtp = async (e) => { 
      e.preventDefault();
      let formIsValid = handleValidation();
      if(formIsValid){
        await dispatch(sendOtpOnEmailAction({ email }))
        .then((res) => {
          // console.log("ressssssssssssss",res)
          if (res.code === 200) {
            sucessToast("Otp has been sent to email address")
            // sucessToast(res.otp)
            localStorage.setItem("sgEmail",JSON.stringify(email))
            navigate("/login-verification",{state:email} )
          }
        })
        .catch((err) => {
          console.log("eeeeee",err)
        errorToast(err.response.data.message)
          console.log(err);
        });
      }
     
    }; 

  return (
   <>
     <div className="LoginArea">
  <div className="LoginBox">
    <div className="LoginLeft">
      <h2>
        SG <span>Finserve</span>
      </h2>
      <h3>
        <span>Forgot password</span>
      </h3>
      <form>
        <div className="form-group">
          <label>Enter your email address</label>
          <input
            type="text"
            placeholder="Enter Email Address"
            className="form-control"
            name='email'
            value={email}
            onChange={handleEmail}
          />
          <span className="Icon">
            <i className="fa fa-envelope" />
          </span>
        </div>
        <span style={{color:"red", fontSize:"14px"}}>{errors?.emailError}</span>
        <button className="Button" onClick={sendOtp}>
          Send OTP
        </button>
        {/* <button>Send OTP</button> */}
      </form>
    </div>
    <div className="LoginRight">
      <figure>
        <img src="images/logo.png" />
      </figure>
      <h5>
        Let's Started <span>SG Finserve</span>
      </h5>
      {/* <h6>To keep connected wit us please login .</h6> */}
    </div>
  </div>
</div>

   </>
      
    
  )
}

export default Loginforgot