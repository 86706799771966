import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { errorToast, sucessToast } from '../utils/toast';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkEmail, checkPhone } from '../utils/CheckType';
import { addstaffManagementAction } from '../redux/actions/staffAction';
 const init = {
    "name": "",
    "phoneNumber": "",
    "email": "",
    "designation": "",
    errors:{},
    employeeId:""
 }
function Staffmanagmentadd() {
  const [iState, updateState] = useState(init);
  const{name, phoneNumber,email,designation,errors,employeeId} = iState;
  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const handleInput = (e) => {
    const{name,value} = e.target;
    updateState({...iState, [name]:value})
  }

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };

  const [modulePermissions, setModulePermissions] = useState([]);


  const modules = [
    { name: 'Dashboard'},
    { name: 'Anchor-Management' },
    { name: 'Staff-Management' },
    { name: 'Score-Management' },   
    // { name: 'Client-View-Score' },
    // { name: 'Start-Scoring' },
    // { name: 'Score-Pending-Approval' },
    { name: 'Client-Management' },
    // { name: 'Score-Management-Details'},
  ];


  const handlePermissionChange = (index, field, value) => {
    console.log("index, field, value", index, field, value);
    const updatedPermissions = [...modulePermissions];
    updatedPermissions[index] = {
      ...updatedPermissions[index],
      [field]: value,
    };
    
    // If the selected field is either 'readAccess' or 'fullAccess', set the other to false
    if (field === 'read'  && value == true) {
      updatedPermissions[index].fullAccess = !value;
      updatedPermissions[index].read = value;
    } else if (field === 'fullAccess' && value == true) {
      updatedPermissions[index].read = !value;
      updatedPermissions[index].fullAccess = value;
    }
    setModulePermissions(updatedPermissions);
  }; 
  const updatePermissionData = () => {
    console.log("modulePermissions",modulePermissions)
    const updatedPermissions = modules.map(module => ({
        name:  module.name ,
        read: false,
        fullAccess: false,
        approve_disapprove:false
        // access: null
      }));

      setModulePermissions(updatedPermissions);
  };
  useEffect(() => {
    updatePermissionData(); 
    const element = document.getElementById('subadmin-id');
  if (element) {
    element.scrollIntoView();
  } 
  },[])


  const renderRows = () => {
    return modulePermissions.map((module, index) => (
      <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <strong>{module.name}</strong>
      </td>
      <td>
        <div className="Read">
        <input
                type="checkbox"
                name={`read${index}`}
                checked={module.read}
                onChange={(e) => handlePermissionChange(index, 'read', e.target.checked)}
              />
          <span>
            <i className="fa fa-eye" />
          </span>
        </div>
      </td>
      <td>
        <div className="Access">
        <input
                type="checkbox"
                name={`fullAccess${index}`}
                checked={module.fullAccess}
                onChange={(e) => handlePermissionChange(index, 'fullAccess', e.target.checked)}
              />
          <span>
            <i className="fa fa-lock" />
          </span>
        </div>
      </td>
      <td>
        {module.name === "Client-Management" ? 
         <div className="Access">
         <input type="checkbox"        
          name={`approve_disapprove${index}`}
          checked={module.approve_disapprove}
          onChange={(e) => handlePermissionChange(index, 'approve_disapprove', e.target.checked)}
          
          />
         <span>
           <i className="fa fa-lock" />
         </span>
       </div>
       :
       "-"
        }
       
      </td>
    </tr>
 
    ));
  };



  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
  

    if(!email){
      error.emailError = "Email can't be empty";
      formIsValid = false
    }
    if(email){
      if(!checkEmail(email)){
        error.emailError = "Please enter a valid email";
        formIsValid  = false;
      }
    }

    if(!name){
      error.nameError = "Name can't be empty";
      formIsValid = false
    }

    if(!employeeId){
      error.employeeIdError = "Employee Id can't be empty";
      formIsValid = false
    }

    if(!phoneNumber){
      error.phoneNumberError = "Phone number can't be empty";
      formIsValid = false
    }
    if(phoneNumber){
      if(!checkPhone(phoneNumber)){
        error.phoneNumberError = "Please enter a valid number"
        formIsValid=false
      }
    }

    if(!designation){
      error.designationError = "Designation can't be empty";
      formIsValid = false
    }

    updateState({...iState, errors:error})
    return formIsValid
  }

  const addStaffFunc = (e) => {
    e.preventDefault()
    let formIsValid = handleValidation();
    if(formIsValid){
      let data = {
       name, designation, email,phoneNumber,permission:modulePermissions, employeeId
      }
      console.log('dataaaaaaa,',data)
      dispatch(addstaffManagementAction(data)).then((res) => {
        console.log("resss", res)
        updateState(init)
        sucessToast("Staff added successfully")
        navigate("/staff-management")
      
      }).catch((err) => {
        console.log("errrrr",err)

        errorToast(err.response.data.message)
      })

  
    }
  
  }

  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="TitleBox">
      <h4 className="Title">
        <Link to="/staff-management" className="backArrow">
          <i className="fa fa-arrow-left" aria-hidden="true" />
        </Link>{" "}
        Add Staff
      </h4>
    </div>
    <div className="InformationBox">
      <h4>Basic Details</h4>
      <div className="AnchorArea">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Full Name </label>
              <input
                type="text"
                placeholder="Enter Full Name"
                className="form-control"
                name='name'
                value={name}
                onChange={handleInput}
              />
               <span style={{color:"red", fontSize:"14px"}}>{errors?.nameError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Designation</label>
              {/* <select className="form-control" name='designation' value={designation} onChange={handleInput}>
                <option value="">--Select--</option>
                <option value="">Manager</option>
                <option value="CEO">CEO</option>
                <option value="CTO">CTO</option>
                <option value="CFO">CFO</option>
              </select> */}
              <input
                type="text"
                placeholder="Enter Designation"
                className="form-control"
                name='designation'
                value={designation}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.designationError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Email Address</label>
              <input
                type="text"
                placeholder="Enter  Email Address"
                className="form-control"
                name='email'
                value={email}
                onChange={handleInput}
              />
               <span style={{color:"red", fontSize:"14px"}}>{errors?.emailError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Contact Number</label>
              <input
                type="text"
                placeholder="Enter  Contact Number"
                className="form-control"
                name='phoneNumber'
                value={phoneNumber}
                maxLength={10}
                onChange={handleInputNumber}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.phoneNumberError}</span>
            </div>
          </div>


          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Employee Id</label>
              <input
                type="text"
                placeholder="Enter Employee Id"
                className="form-control"
                name='employeeId'
                value={employeeId}
                // maxLength={10}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.employeeIdError}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="InformationBox">
      <h4>Define Access</h4>
      <div className="AdminAccess">
        <table className="table">
          <thead>
            <tr>
              <th>Sr. N.</th>
              <th>Module</th>
              <th width="150px">Read</th>
              <th width="150px">Manage</th>
              <th width="150px">Approve/Disapprove</th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    </div>
    {/* <button class="Button">Add Staff</button> */}
    <div className="newBtn">
      <a onClick={addStaffFunc} className="Button">
        Add Staff
      </a>
    </div>
  </div>
</div>

   </>
  )
}

export default Staffmanagmentadd