import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clientDetailsAction,
  downloadReportAction,
  getFinanceListAction,
  getScoreAction,
  updateFinancialManagementAction,
} from "../redux/actions/clientAction";
import Header from "./Header";
import Sidenav from "./Sidenav";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { checkArray } from "../utils/CheckType";
import { sucessToast } from "../utils/toast";
import { usePDF } from "react-to-pdf";
import { saveAs } from "file-saver";
const init = {
  rangeId: "",
  disabledState: true,
  submitRatingModal: false,
};
function ClientAnchorrating() {
  const [iState, updateState] = useState(init);
  const [iRState, setIState] = useState({});
  const { rangeId, disabledState, submitRatingModal } = iState;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { anchorId, clientId } = useParams();
  const [clientDetailsData, setClientDetailsData] = useState([]);
  const [showDetailsData, setShowDetailsData] = useState([]);

  const { financeRiskListData, loader } = useSelector(
    (state) => state.mainReducer
  );
  const { toPDF, targetRef } = usePDF({ filename: "AnchorRating.pdf" });
  const dispatch = useDispatch();
  const { state } = useLocation();
  // useEffect(( ) => {
  //   dispatch(getFinanceListAction()).then(()=>{
  //     dispatch(clientDetailsAction(clientId)).then((res) => {
  //       // setClientDetailsData(res?.result?.[0]?.financeratingsData );
  //       setClientDetailsData(res?.result?.[0] );
  //       res?.result?.[0]?.financeratingsData.forEach(val=>{
  //         updateState(prev=>({...prev,[val?.parameterId+"rangeId"]: val.valueId}));

  //         setData(prev=>([...prev,{ range: val?.range, score: val?.score, valueId: val?.valueId }]))
  //       })
  //     })
  //   })

  // },[clientId,dispatch]).

  useEffect(() => {
    dispatch(clientDetailsAction(state?._id, state?.anchorId)).then((res) => {
      setClientDetailsData(res?.result?.[0]);
      // res?.result?.[0]?.anchorRatingData.forEach(val=>{

      //   updateState(prev=>({...prev,[val?.parameterId+"rangeId"]: val.valueId}));
      //   if(val.parameterName == undefined){

      //   }
      //   setData(prev=>([...prev,{ range: val?.range, score: val?.score, valueId: val?.valueId }]))
      // })
    });
  }, [clientId, dispatch]);

  useEffect(() => {
    if (clientDetailsData?.anchorRatingData?.length > 0) {
      const groupedData = clientDetailsData?.anchorRatingData?.reduce(
        (acc, item) => {
          if (!acc[item.parameterName]) {
            acc[item.parameterName] = [];
          }
          acc[item.parameterName].push(item);
          console.log("acccccccc------------>", acc);
          return acc;
        },
        {}
      );

      const groupedArray = Object.keys(groupedData).map((parameter) => ({
        parameter,
        data: groupedData[parameter],
      }));
      setShowDetailsData(groupedArray);
    }
  }, [clientDetailsData]);

  console.log("datatata", data);
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 3;
  let pathMatch = path[pathLength];

  const handleInput = (e, ele, index) => {
    const { name, value } = e.target;
    //     // debugger
    //     const res=financeRiskListData?.result.find((data)=>data._id===ele._id).financeRiskData.find(data => data._id===value)

    //     let newData = [...data];
    //     newData[index] = { range: res?.range, score: res?.score, valueId: res?._id };

    // setData(newData)
    //     updateState({ ...iState, [name]: value });
    updateState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getScoreFunc = () => {
    dispatch(getScoreAction(rangeId)).then((res) => {});
  };

  console.log("showDetailData", showDetailsData);

  // useEffect(() => {
  //   if(financeRiskListData){
  //     financeRiskListData?.result?.forEach(obj => {
  //       obj.financeRiskData.forEach(item => {

  //       setData(prev=>
  //         {

  //           let jsonObject = prev.map(obj=>JSON.stringify(obj));
  //           let uniqueSet = new Set(jsonObject);
  //           const clientDataArray2 = Array.from(uniqueSet).map(JSON.parse);
  //           // console.log("databaseeee",clientDataArray2)
  //           return ([...clientDataArray2,{ range:obj?.financeRiskData?.[0]?.range, score:obj?.financeRiskData?.[0]?.score, valueId:obj?.financeRiskData?.[0]?._id}]);
  //         })

  //       setData(prev=>([...prev,{ range:obj?.financeRiskData?.[0]?.range, score:obj?.financeRiskData?.[0]?.score, valueId:obj?.financeRiskData?.[0]?._id}]));

  //       });
  //   });

  //   }
  // },[financeRiskListData ])

  useEffect(() => {
    getScoreFunc();
  }, [rangeId]);

  const showSubmitRatingModal = () => {
    updateState({ ...iState, submitRatingModal: true });
  };

  const hideSubmitRatingModal = () => {
    updateState({ ...iState, submitRatingModal: false });
  };

  const addFinacialDataFunc = () => {
    let dataa = {
      anchorId: state?.anchorId,
      clientId: state?._id,
      financeRatingData: data,
    };
    dispatch(updateFinancialManagementAction(dataa)).then((res) => {
      console.log("resssss", res);
      sucessToast("Finance rating updated successfully");
      navigate("/client-nonfinancial-rating", { state });
      hideSubmitRatingModal();
      updateState({ ...iState, disabledState: !disabledState });
    });
    // let dataP = {financeRatindData:data,state }
    // setData([])
    //  navigate(`/client-scoring-non-financial/${id}`, {state:dataP})
  };

  const downloadRatingFunc = () => {
    let financeData = clientDetailsData?.financeratingsData?.map((ele, i) => {
      // // // console.log(ele)
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
        value: ele?.value,
      };
    });

    let businessNonFinacePdfData =
      clientDetailsData?.nonfinanceratingsData?.map((ele, i) => {
        if (ele?.outsideTitle === "Business Risk parameters") {
          //  console.log("elelelelelelle",ele)
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });

    let accountNonFinacePdfData = clientDetailsData?.nonfinanceratingsData?.map(
      (ele, i) => {
        // console.log("Elelelelelel",ele)
        if (ele?.outsideTitle === "Account Conduct Risk parameters") {
          console.log("elelelelelelle", ele);
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      }
    );

    let managementNonFinacePdfData =
      clientDetailsData?.nonfinanceratingsData?.map((ele, i) => {
        if (ele?.outsideTitle === "Management Risk parameters") {
          // console.log("elelelelelelle",ele)
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });

    let anchorData = clientDetailsData?.anchorRatingData?.map((ele, i) => {
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
      };
    });

    let anchorRiskData = clientDetailsData?.overallRatingAnchorRisk
      ? clientDetailsData?.overallRatingAnchorRisk.toFixed(2)
      : 0;
    let financeRiskData = clientDetailsData?.overallRatingFinanceRisk
      ? clientDetailsData?.overallRatingFinanceRisk.toFixed(2)
      : 0;
    let businessRiskData = clientDetailsData?.overallRatingBuisnessRisk
      ? clientDetailsData?.overallRatingBuisnessRisk.toFixed(2)
      : 0;
    let managementRiskData = clientDetailsData?.overallRatingManagementRisk
      ? clientDetailsData?.overallRatingManagementRisk.toFixed(2)
      : 0;
    let accountRiskData = clientDetailsData?.overallRatingAccountRisk
      ? clientDetailsData?.overallRatingAccountRisk.toFixed(2)
      : 0;
    let overAllRiskData = clientDetailsData?.overallRating
      ? clientDetailsData?.overallRating.toFixed(2)
      : 0;
    let categoryColor =
      clientDetailsData?.overallRating < 2
        ? "Green"
        : clientDetailsData?.overallRating < 3
        ? "Yellow"
        : clientDetailsData?.overallRating < 4
        ? "Orange"
        : clientDetailsData?.overallRating < 5
        ? "Red"
        : "";

    let data = {
      clientId: clientDetailsData?._id,
      financeData,
      businessNonFinacePdfData: businessNonFinacePdfData.filter(
        (item) => item !== null
      ),
      accountNonFinacePdfData: accountNonFinacePdfData.filter(
        (item) => item !== null
      ),
      managementNonFinacePdfData: managementNonFinacePdfData.filter(
        (item) => item !== null
      ),

      // nonFinanceData,
      anchorData,
      anchorRiskData,
      financeRiskData,
      businessRiskData,
      managementRiskData,
      accountRiskData,
      overAllRiskData,
      categoryColor,
    };
    dispatch(downloadReportAction(data)).then((res) => {
      // // // console.log("resssssssss",res)
      window.open(res.link, "_blank");
      // const blob = new Blob([data], { type: 'application/pdf' })
      //   saveAs(blob, "tickets.pdf")
      // href={
      //   studentHelpdetails?.askedRequest
      //     ?.tutorAttachDocument?.[0]?.link
      // }
      // target="_self"
    });
  };

  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">
              {state?.UrlLink === "pending" ? (
                <Link
                  to={`/client-scoring-details/${clientId}`}
                  state={state}
                  className="backArrow"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link>
              ) : (
                <Link to="/client-view-score" className="backArrow">
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link>
              )}
              View Score
            </h4>
          </div>
          <div className="Small-Wrapper">
            <div className="CommonLinks">
              <ul>
                <li
                  className={
                    pathMatch == "client-financial-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-financial-rating" state={state}>
                    Financial Rating
                  </Link>
                </li>
                <li
                  className={
                    pathMatch == "client-nonfinancial-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-nonfinancial-rating" state={state}>
                    Non-Financial Rating
                  </Link>
                </li>
                <li
                  className={
                    pathMatch == "client-anchor-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-anchor-rating" state={state}>
                    Anchor Rating
                  </Link>
                </li>
              </ul>
              {/* {DIS} */}
              {/* {state?.UrlLink === "pending" ?  <></> : <>
              <div className='d-flex align-items-center'>
              {disabledState ?  <a onClick={() => updateState({...iState,disabledState:!disabledState })} className="Green">
          <i className="fa fa-pencil" />
        </a>  : <></>} 
        </div></>} */}
              {/* <div className='d-flex align-items-center'>
              {disabledState ?  <a onClick={() => updateState({...iState,disabledState:!disabledState })} className="Green">
          <i className="fa fa-pencil" />
        </a> : <></>}  */}
              {/* <a
                  onClick={() =>
                    updateState({ ...iState, disabledState: !disabledState })
                  }
                  className="Green"
                >
                  <i className="fa fa-pencil" />
                </a> */}
              <a onClick={downloadRatingFunc} className="Button active ml-3">
                Download PDF
              </a>
              {/* </div> */}
            </div>
            <div className="TableList TableFinance">
              <table ref={targetRef} style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>Range</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <Loader />
                    </>
                  ) : checkArray(showDetailsData) ? (
                    showDetailsData?.map((ele, i) => {
                      console.log("=======>", ele?.data);
                      return (
                        <>
                          <tr>
                            <td>{ele?.parameter}</td>
                            <td>
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  disabled
                                  // name={ele._id + "rangeId"}
                                  // value={iState[ele._id + "rangeId"]}
                                  // onChange={(e) => handleInput(e, ele, i)}
                                >
                                  {checkArray(ele?.data) &&
                                    ele?.data?.map((child, i) => {
                                      // const valueIdArr =  clientDetailsData.map((itemm)=>itemm.valueId);
                                      // const comparison = valueIdArr.find(it=>it === child._id);
                                      return (
                                        <option
                                          value={child?._id}

                                          // selected={comparison !== undefined ? comparison : ""}
                                          // selected={clientDetailsData.map((itemm)=>itemm.valueId).find((idd)=>idd == child._id) != undefined ? true : false}
                                        >
                                          {child?.range}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="text"
                                  // placeholder={5}
                                  className="form-control"
                                  disabled
                                  // name={ele._id + "value"}
                                  // onChange={(e) => {
                                  //   updateState(prevState => ({
                                  //     ...prevState,
                                  //     [e.target.name]: e.target.value
                                  //   }));
                                  // }}
                                  value={ele?.data[0]?.score}
                                  // {
                                  //   iState[ele._id + "value"] ||
                                  //   ele.data.reduce((acc, cur) => {
                                  //     if (cur._id === iState[ele._id + "rangeId"]) {
                                  //       return (acc += cur.score);
                                  //     }
                                  //     return acc;
                                  //   }, 0)
                                  // }
                                  // disabled={iState.disabledState}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <p>No data found</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* {disabledState === false ? (
            <div className="newBtn">
              <button className="Button" onClick={showSubmitRatingModal}>
                Submit financial rating
              </button>
            </div>
          ) : (
            <></>
          )} */}
        </div>
      </div>

      <Modal
        show={submitRatingModal}
        onHide={hideSubmitRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={hideSubmitRatingModal}
            >
              ×
            </a>
            <h3>Are you sure you want to submit the ratings? </h3>
            <div className="newBtn d-flex">
              <button className="Button active" onClick={addFinacialDataFunc}>
                Yes,Submit
              </button>{" "}
              &nbsp; &nbsp;
              <button className="Button active" onClick={() => toPDF()}>
                Download Rating
              </button>{" "}
              &nbsp; &nbsp;
              <button className="Button" onClick={hideSubmitRatingModal}>
                No,Review
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClientAnchorrating;
