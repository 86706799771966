import React from 'react'
import { useNavigate } from 'react-router-dom'
import { sucessToast } from '../utils/toast'

function Header() {
  const navigate = useNavigate()
  const emailDataStatic = JSON.parse(localStorage.getItem("sgfinserv"))?.email
  const showLogout = () => {
    // localStorage.setItem("sgEmail",JSON.stringify(emailDataStatic))
     localStorage.removeItem("sgfinserv")
     sucessToast("Logout successfully")
     navigate("/")
  }
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));

  return (
    <>
    <div className="Header">
  <div className="Logo">
    <img src="images/logo.png" />
  </div>
  <div className="Navigation">
    {/* <div class="Bell">
          <a href="pushNotification.html">
              <i class="fa fa-bell" aria-hidden="true"></i>
          </a>
      </div> */}
    <div className="Avater">
      {adminData?.userType === "ADMIN" ? <>
      <a href="javascript:void(0);">
        <figure>
          <img src="images/logo.png" />
        </figure>
        SG Finserve
      </a>
      </> : <>
      <a href="javascript:void(0);">
        <figure>
          <img src="images/logo.png" />
        </figure>
       {adminData?.name}
      </a>
      </>}
     
      <ul>
      {adminData?.userType === "ADMIN" ? <>
      <li>
          <figure>
            <img src="images/logo.png" />
          </figure>
          <h4>
            {" "}
            SG Finserve <span>Administrator</span>
          </h4>
        </li>
      </> : <>
      <li>
          <figure>
            <img src="images/logo.png" />
          </figure>
          <h4>
            {" "}
            {adminData?.name} <br/><span>SubAdmin</span>
          </h4>
        </li>
      </>}
       
        <li>
          <a onClick={showLogout}>
            <span>
              <i className="fa fa-sign-out" />
            </span>{" "}
            Logout
          </a>
        </li>
      </ul>
    </div>
    <div className="clear" />
  </div>
</div>

    </>
  )
}

export default Header