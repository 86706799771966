import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { anchorListAction, changeAnchorStatusAction, removeAnchorAction } from '../redux/actions/anchorAction'
import { checkArray } from '../utils/CheckType'
import NoDataFound from './NoDataFound'
import Loader from './Loader'
import Pagination from 'react-js-pagination'
import { sucessToast } from '../utils/toast'
// import deleteAlert from '../assets/images/deleteAlert.png'
import moment from 'moment';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Modal } from 'react-bootstrap'
import * as XLSX from 'xlsx';
const init = {
  search:"",startDate:"",endDate:"", timeFrame:"",
  blockModal:false,unblockModal:false, modalId:"", status:"",
  deleteModal:false,modalName:""
}
function Anchormanagement() {
  const [iState, updateState] = useState(init);
  const anchorRef = useRef()
  const{search,startDate,endDate, timeFrame,blockModal, unblockModal, modalId,status, deleteModal,modalName} = iState
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  let loader = false;
  const [anchorListData, setAnchorListData] = useState([])
  // const {anchorListData} = useSelector((state ) => state.mainReducer)
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  useEffect(() => {
    dispatch(anchorListAction()).then((res) => {
      setAnchorListData(res)
    }) 
  },[])

  const applyFilter = () => {
    dispatch(anchorListAction(search,startDate, endDate, timeFrame )).then((res) => {
      setAnchorListData(res)
    }) 
  }
  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    let payloadData = {
      search,
      startDate,
      endDate,
      timeFrame,
      // city,
      page:pageNumber
    };
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    dispatch(anchorListAction(search,
      startDate,
      endDate,
      timeFrame,
      // city,
      pageNumber)).then((res) => {
        setAnchorListData(res)
      }) 
  }


  const removeAnchorFunc = () => {
    dispatch(changeAnchorStatusAction({id :modalId, status:"DELETED"})).then((res) => {
      console.log("ressss",res)
      sucessToast("Anchor removed successfully")
      dispatch(anchorListAction()).then((res) => {
        setAnchorListData(res)
      }) 
      hideDeleteModal()
    })

  }


  const changeAnchorStatusFunc = ( ) => {
    dispatch(changeAnchorStatusAction({id:modalId, status})).then((res) => {
      console.log("ressss",res)
      sucessToast("Status changed successfully")
      dispatch(anchorListAction()).then((res) => {
        setAnchorListData(res)
      }) 
      if(status == "ACTIVE"){
        console.log(status)
        hideUnblockModal()
      } else{
        hideBlockModal()
        // hideUnblockModal()
      }
    })
  }

  const handleInput = (e) => {
    const{name,value} = e.target;
    updateState({...iState, [name]:value})
  }


  const handleRefresh = () => {
    updateState(init)
    dispatch(anchorListAction("", "","","",activePage)).then((res) => {
      setAnchorListData(res)
    }) 
  }

  const showBlockModal = (_id, status, name) => {
    console.log('nameemememe', name)
    updateState({...iState, blockModal:true, modalId:_id, status:status, modalName:name})
  }

  
  const hideBlockModal = () => {
    updateState({...iState, blockModal:false, modalName:''})
  }


  const showUnblockModal = (_id, status, name) => {
    updateState({...iState, unblockModal:true, modalId:_id, status:status, modalName:name})
  }

  
  const hideUnblockModal = () => {
    updateState({...iState, unblockModal:false, modalName:""})
  }

  const showDeleteModal = (_id, name) => {
    updateState({...iState, deleteModal:true, modalId:_id, modalName:name})
  }
  
  
  const hideDeleteModal = () => {
    updateState({...iState, deleteModal:false, modalId:"", modalName:""})
  }

  const handleEnter = (e) =>{
    if(e.keyCode == 13 ){
      dispatch(anchorListAction(search,startDate, endDate, timeFrame )).then((res) => {
        setAnchorListData(res)
      }) 


    }}


    
    const[downloadDataExcel, setDownloadDataDownload] = useState([])

    const downloadCSV = async () => {
      try {
    
        dispatch(anchorListAction("","","","","",anchorListData?.result?.[0]?.totalCount[0]?.count)).then((res) => {
          console.log("---------->", res)
          setDownloadDataDownload(res)
             setTimeout(() =>{
              var table_elt = document.getElementById("my-table-id");

              var workbook = XLSX.utils.table_to_book(table_elt);
              // const workbook = XLSX.utils.book_new();
              const worksheet = XLSX.utils.json_to_sheet(downloadDataExcel);
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Anchors');
              XLSX.writeFile(workbook, 'anchors.xlsx');
              setDownloadDataDownload([])
             }, 1000)
         


        })
       
        
      } catch (error) {
        console.error('Error downloading CSV:', error);
      }
    };
  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">Anchor Listing</h4>
        {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "anchor-management" &&
                      ele.fullAccess == true
                  ) && (
                    <div className="sms">
         
                    
                             <a onClick={downloadCSV}  className="TitleLink"> Download CSV </a>
          
                         
                  </div>
                  )}
                </>
              ) : (
                <> 
                  <div className="sms">
         
         {/* <DownloadTableExcel
                   filename="Anchor List"
                   sheet="Anchor List"
                   currentTableRef={anchorRef.current}
               > */}

                  <a onClick={downloadCSV}  className="TitleLink"> Download CSV </a>

               {/* </DownloadTableExcel> */}
       </div>
                </>
              )}

      
      </div>


      <div className="Filter">
        <div className="form-group">
          <label>Search</label>
          <input type="text" className="form-control" placeholder="Search by anchor name" name='search' value={search} onChange={handleInput} onKeyDown={handleEnter}/>
        </div>
        <div className="form-group">
          <label>From</label>
          <input type="date" className="form-control" name='startDate' value={startDate} max={moment().format("YYYY-MM-DD")} disabled={timeFrame} onChange={handleInput} />
        </div>
        <div className="form-group">
          <label>To</label>
          <input type="date" className="form-control" name='endDate' value={endDate}  min={moment(startDate).format("YYYY-MM-DD")} disabled={timeFrame} onChange={handleInput}/>
        </div>
        <div className="form-group">
          <label>Select From</label>
          <select className="form-control" name='timeFrame' value={timeFrame} onChange={handleInput} disabled = {startDate || endDate}>
          <option value="">Select</option>
            <option value="Today">Today</option>
            <option value="Week">Last Week</option>
            <option value="Month">Last Month</option>
          </select>
        </div>
        <div className="form-group">
          <label>&nbsp; </label>
          <button onClick={applyFilter} className="Button">Apply</button> &nbsp;&nbsp;
          <button onClick={handleRefresh} className="Button Cancel">
            <i className="fa fa-refresh" />
          </button>
        </div>
      </div>
      {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "anchor-management" &&
                      ele.fullAccess == true
                  ) && (
                    <>
                       
      <div className="user-panding">
        <Link className="TitleLink" to="/anchor-pending-approval">
          Users Pending for approval
           {/* <span className="numbering"></span> */}
        </Link>
        <Link className="TitleLink" to="/anchor-add">
          Add Anchor.
        </Link>
      </div>
                    </>
               
                  )}
                </>
              ) : (
                <>
              
      <div className="user-panding">
        <Link className="TitleLink" to="/anchor-pending-approval">
          Users Pending for approval
           {/* <span className="numbering"></span> */}
        </Link>
        <Link className="TitleLink" to="/anchor-add">
          Add Anchor.
        </Link>
      </div>
                 </>
              )}

{/* ref={anchorRef} */}
      <div className="TableList">
        <table  >
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>Anchor ID</th>
              <th>Anchor Name</th>
              <th>Contact Person</th>
              <th>Contact Number</th>
              <th>Email Address</th>
              <th>Date of Onboarding</th>
              <th>Dealers</th>
              <th>Status</th>
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Anchor-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <th>Action</th>
                  )}
                </>
              ) : (
                <> 
                 
                   <th>Action</th>
               
                </> 
            )}
            
            </tr>
          </thead>
          <tbody>
            {loader ? <>
            <Loader/>
            </>
            :

            checkArray(anchorListData?.result?.[0]?.paginationData) ? 
            anchorListData?.result?.[0]?.paginationData?.map((ele,i) => {           
              return(
                <>
 <tr>
              <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.anchor_number}</td>
              <td>{ele?.companyName}</td>
              <td>{ele?.contactPersonName}</td>
              <td>{ele?.contactPersonNumber}</td>
              <td>{ele?.contactPersonEmail}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              <td>{ele?.dealers}</td>
              <td>
                {ele?.status === "ACTIVE"?  <span className="Green">Active</span> :  <span className="Red">Inactive</span>}
               
              </td>
             
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Anchor-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <td>
                    <div className="Actions">
                    {ele?.status === "ACTIVE" ? (
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    onChange={() =>
                                      showBlockModal(ele?._id, "INACTIVE", ele?.companyName)
                                    }
                                  />
                                  <span className="slider"></span>
                                </label>
                              ) : (
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={false}
                                    onChange={() =>
                                      showUnblockModal(ele?._id, "ACTIVE",ele?.companyName)
                                    }
                                  />
                                  <span className="slider"></span>
                                </label>
                              )}
                    
                      <Link className="Blue" to="/anchor-details" state={ele?._id}>
                        <i className="fa fa-eye" />
                      </Link>
                      <Link className="Green" to="/anchor-edit" state={ele?._id}>
                        <i className="fa fa-pencil" />
                      </Link>
                      <a
                        className="Red"
                         onClick={() => showDeleteModal(ele?._id,ele?.companyName)}
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                    </td>
                  )}
                </>
              ) : (
                <> 
                   <td>
                      <div className="Actions">
                {ele?.status === "ACTIVE" ? (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={true}
                                onChange={() =>
                                  showBlockModal(ele?._id, "INACTIVE", ele?.companyName)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          ) : (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={false}
                                onChange={() =>
                                  showUnblockModal(ele?._id, "ACTIVE",ele?.companyName)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          )}
                 
                
                  <Link className="Blue" to="/anchor-details" state={ele?._id}>
                    <i className="fa fa-eye" />
                  </Link>
                  <Link className="Green" to="/anchor-edit" state={ele?._id}>
                    <i className="fa fa-pencil" />
                  </Link>
                  {ele?.dealers == 0  &&  ele?.userCount == 0 ? <>
                    <a
                    className="Red"
                     onClick={() => showDeleteModal(ele?._id, ele?.companyName)}
                  >
                    <i className="fa fa-trash" />
                  </a>
                  </> : <></>}
                 
                </div>
                </td>
                </> 
            )}

            
           
            </tr>
                </>
              )
            })
            : <p>No data found</p>
            }
           
           
           
          </tbody>
        </table>
      </div>
      <div class="pagination">
              <p>
                {" "}
                {/* Total records : <span>{anchorListData?.result?.[0]?.totalCount?.[0]?.count}</span> */}
              </p>
              <ul>
                {anchorListData?.result?.[0]?.totalCount?.[0]?.count && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={anchorListData?.result?.[0]?.totalCount?.[0]?.count}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </ul>
            </div>

    </div>
  </div>
</div>



      
<Modal
        show={blockModal}
        onHide={hideBlockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideBlockModal}>
    ×
  </a>
  <h3>Are you sure you want to block {modalName} Anchor ? </h3>
  <div className="newBtn">
    <button
      className="Button active"
       onClick={() =>changeAnchorStatusFunc("ACTIVE")}
    >
      Yes
    </button>  &nbsp;  &nbsp; 
    <button className="Button" onClick={hideBlockModal}>
      No
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>


            
<Modal
        show={unblockModal}
        onHide={hideUnblockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideUnblockModal}>
    ×
  </a>
  <h3>Are you sure you want to unblock {modalName} Anchor ? </h3>
  <div className="newBtn">
    <button
      className="Button"
       onClick={() =>changeAnchorStatusFunc("INACTIVE")}
    >
      Yes
    </button>  &nbsp;  &nbsp; 
    <button className="Button" onClick={hideUnblockModal}>
      No
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>



      <Modal
        show={deleteModal}
        onHide={hideDeleteModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
     

<div className="Category">
  <button onClick={hideDeleteModal} className="CloseModal">×</button>
  <h3>Delete</h3>
  <figure>
    <img src="images/deleteAlert.png" width={50} />
  </figure>
  <p>Are you sure you want to delete this {modalName} Anchor ?</p>
  <h4>
  <button className="Button" onClick={removeAnchorFunc}>Delete</button>   &nbsp;  &nbsp; 
    <button onClick={hideDeleteModal} className="Button">Cancel</button> 
   
  </h4>
</div>


        </Modal.Body>
      </Modal>



      <div style={{display:"none"}} className="TableList">
        <table  id="my-table-id">
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>Anchor ID</th>
              <th>Anchor Name</th>
              <th>Contact Person</th>
              <th>Contact Number</th>
              <th>Email Address</th>
              <th>Date of Onboarding</th>
              <th>Dealers</th>
              <th>Status</th>
             
            
            </tr>
          </thead>
          <tbody>
            {loader ? <>
            <Loader/>
            </>
            :

            checkArray(downloadDataExcel?.result?.[0]?.paginationData) ? 
            downloadDataExcel?.result?.[0]?.paginationData?.map((ele,i) => {           
              return(
                <>
 <tr>
              <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.anchor_number}</td>
              <td>{ele?.companyName}</td>
              <td>{ele?.contactPersonName}</td>
              <td>{ele?.contactPersonNumber}</td>
              <td>{ele?.contactPersonEmail}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              <td>{ele?.dealers}</td>
              <td>
                {ele?.status === "ACTIVE"?  <span className="Green">Active</span> :  <span className="Red">Inactive</span>}
               
              </td>
             
              

            
           
            </tr>
                </>
              )
            })
            : <p>No data found</p>
            }
           
           
           
          </tbody>
        </table>
      </div>
   </>
  )
}

export default Anchormanagement