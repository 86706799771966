import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { checkArray, checkEmail, checkPhone } from "../utils/CheckType";
import {
  anchorDropdownAction,
  anchorListAction,
} from "../redux/actions/anchorAction";
import {
  uploadBulkDataAction,
  uploadJSON,
} from "../redux/actions/clientAction";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Modal } from "react-bootstrap";
import { errorToast, sucessToast } from "../utils/toast";
import { excel } from "react-export-table-to-excel/lib/lib";
import { update } from "lodash";
import LoadingSpinner from "./LoadingSpinner";
const init = {
  files: "",
  anchorId: "",
  dealerName: "",
  baseYear: "",
  panNumber: "",
  gstinNumber: "",
  errors: {},
  relationId: "",
  expiryDate: "",
  jsonExcel: "",
  bulkVendorModal: false,
  uploadDataCheck: false,
  // reportData:[],
  bulkDataError: {},
  checkState: false,
  aadharNumber:"",
  dateOfIncorporation:"",
  mobileNumber:"",
  emailAddress:"",
  address:"",
  itrType:"",addValue:"", lessValue:"",
  loader:false,
  nonFinance: [
    {
      anchorId: "",
      parameterId: "",
      paramterName: "",
      score: "",
      weightageNewCustomer: "",
    },
  ],
};
function Clientstartscoring() {
  const [iState, updateState] = useState(init);
  // const[set]
  const [reportData, setReportData] = useState([]);
  const [bulkFinancialRatingData, setBulkFinancialRatingData] = useState([]);
  const {
    files,
    anchorId,
    dealerName,
    baseYear,
    relationId,
    panNumber,
    gstinNumber,
    nonFinance,
    errors,
    expiryDate,
    bulkVendorModal,
    jsonExcel,
    bulkDataError,
    uploadDataCheck,
    aadharNumber,
    dateOfIncorporation,
    mobileNumber,
    emailAddress,
    address,
    loader,
    // reportData,
    checkState,
    itrType,addValue, lessValue,
  } = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { anchorDropdownData } = useSelector((state) => state.mainReducer);
  const StartScoringData = JSON.parse(localStorage.getItem("startScoringData"));
  console.log("StartScoringData",StartScoringData);
      const {state}=useLocation()
      console.log("State",state);

  useEffect(() => {
    dispatch(anchorDropdownAction());
    updateState({
      ...iState,
      anchorId: state?.anchorId,
      dealerName: state?.dealerName,
      baseYear: state?.baseYear,
      relationId: state?.relationId,
      panNumber: state?.panNumber,
      gstinNumber: state?.gstinNumber,
      expiryDate: state?.expiryDate,
      address : state?.address,
      mobileNumber : state?.mobileNumber,
      emailAddress : state?.emailAddress,
      dateOfIncorporation : state?.dateOfIncorporation,
      aadharNumber: state?.aadharNumber,
      itrType: state?.itrType,
    });
    // dispatch(anchorListAction())
  }, []);
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!files) {
      error.filesError = "Files can't be empty";
      // formIsValid = false
    }

    if (!dealerName) {
      error.dealerNameError = "Dealer name can't be empty";
      formIsValid = false;
    }
    // /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!baseYear) {
      error.baseYearError = "Base year can't be empty";
      formIsValid = false;
    }

    if (!expiryDate) {
      error.expiryDateError = "Base year can't be empty";
      formIsValid = false;
    }

    if (!anchorId) {
      error.anchorIdError = "Anchor can't be empty";
      formIsValid = false;
    }

    if (!gstinNumber) {
      error.gstinNumberError = "GST can't be empty";
      // formIsValid = false;
    }
    if (!address) {
      error.addressError = "Address can't be empty";
      formIsValid = false;
    }

    if (!mobileNumber) {
      error.mobileNumberError = "Mobile number can't be empty";
      formIsValid = false;
    }
    if (mobileNumber) {
      if(!checkPhone(mobileNumber)){
        error.mobileNumberError = "Please enter a valid phone number";
        formIsValid = false;
      }   
    }


    if (!emailAddress) {
      error.emailAddressError = "Email address can't be empty";
      formIsValid = false;
    }
    if (emailAddress) {
      if(!checkEmail(emailAddress)){
        error.emailAddressError = "Please enter a valid email address";
        formIsValid = false;
      }
    }

    // if (aadharNumber ) {
    //   if(aadharNumber.length != 12){
    //     error.aadharNumberError = "Please enter a valid aadhar number";
    //     formIsValid = false;
    //   }
    // }
    if (!dateOfIncorporation) {
      error.dateOfIncorporationError = "Date of incorporation can't be empty";
      formIsValid = false;
    }

    if (!relationId) {
      error.relationIdError = "Relation can't be empty";
      formIsValid = false;
    }
    if (gstinNumber) {
      if (gstinNumber.length != 15) {
        error.gstinNumberError = "Please enter a valid GST number";
        formIsValid = false;
      }
    }

    if (!panNumber) {
      error.panNumberError = "Pan number can't be empty";
      formIsValid = false;
    }
    if (panNumber) {
      if (panNumber.length !== 10) {
        error.panNumberError = "Please enter a valid pan number";
        formIsValid = false;
      }
    }
    if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(panNumber)) {
      error.panNumberError = "Please enter a valid pan number";
      formIsValid = false;
    }

    updateState({ ...iState, errors: error });
    return formIsValid;
  };
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };

  const showBulkVendor = () => {
    updateState({ ...iState, bulkVendorModal: true });
  };

  const hideBulkVendor = () => {
    updateState({ ...iState, bulkVendorModal: false , bulkDataError:{}, loader:false});
  };

  const startRatingFUnc = (profileDetails) => {
    let formIsValid = handleValidation();
    if (formIsValid) {
      let data = {
        anchorId,
        dealerName,
        baseYear,
        panNumber,
        gstinNumber,
        expiryDate,
        relationId,
        address,
        mobileNumber,
        emailAddress,
        dateOfIncorporation,
        aadharNumber,
        itrType,
        reportData: reportData,
        // financeratingData:getFinanceData
      };
      let dataTwo = {
        anchorId,
        dealerName,
        baseYear,
        panNumber,
        gstinNumber,
        expiryDate,
        relationId,
        address,
        mobileNumber,
        emailAddress,
        dateOfIncorporation,
        aadharNumber,
        itrType,
      };
      localStorage.setItem("startScoringData", JSON.stringify(dataTwo));
      navigate("/client-scoring-financial", { state: data });
    }
  };
  // const [loader, setLoader ] = useState(true)
  const handleInputFile = (e) => {
    // setLoader(true)
    const file = e.target.files[0];
    if (file) {
      updateState({
        ...iState,
        files: file,
      });
    
    }
    // setLoader(false)
    
    e.target.files = null;
  };

  const handleBulValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!jsonExcel) {
      error.jsonExcelError = "Please select type of file";
      formIsValid = false;
    }
    if (!files) {
      error.filesError = "Please select the file";
      formIsValid = false;
    }
    if(jsonExcel === "JSON"){
      if (!addValue) {
        error.addValueError = "Please enter the add value";
        formIsValid = false;
      }
      // if(addValue){
      //   if (+addValue <0) {
      //     error.addValueError = "Please enter the valid add value";
      //     formIsValid = false;
      //   }
      // }
    
     
      if (!lessValue) {
        error.lessValueError = "Please enter the less value";
        formIsValid = false;
      }
      // if(lessValue){
      //   if (+lessValue <0) {
      //     error.lessValueError = "Please enter the valid less value";
      //     formIsValid = false;
      //   }
      // }
      
      if (!itrType) {
        error.itrTypeError = "Please select the itr type";
        formIsValid = false;
      }
    }
  
    updateState({ ...iState, bulkDataError: error });
    return formIsValid;
  };

  const handleBulkVendor = (e) => {
    e.preventDefault();
  
    let formIsValid = handleBulValidation();
    if (formIsValid) {
      if (jsonExcel === "JSON") {
       updateState({...iState, loader:true})
       
        dispatch(uploadJSON({ files, clientType: relationId, anchorId, itrType, addValue, lessValue }))
     
          .then((res) => {
            console.log("+====ree", res?.profileDetails);
            setReportData(res?.storeData);
            sucessToast("Bulk data uploaded successfully");
            updateState({
              ...iState,
              aadharNumber:res?.profileDetails?.aadharNumber,
              dealerName: res?.profileDetails?.name,
              dateOfIncorporation:res?.profileDetails?.dateOfIncorporation,
              emailAddress:res?.profileDetails?.emailAddress,
              panNumber: res?.profileDetails?.pan,
              mobileNumber:res?.profileDetails?.mobileNumber,
              address:res?.profileDetails?.address,
              // dateOfIncorporation:res?.profileDetails?.dateOfIncorporation,
              // emailAddress:res?.profileDetails?.emailAddress,
              // panNumber: res?.profileDetails?.pan,
              // mobileNumber:res?.profileDetails?.mobileNumber,
              // expiryDate:res?.profileDetails?.expiryDate,
              bulkVendorModal: false,
              uploadDataCheck:true,
              loader:false
            });
            localStorage.setItem(
              "financeratingData",
              JSON.stringify(res?.financeRatingArr)
            );
           
           
            // startRatingFUnc(res?.storeData, res?.financeRatingArr,res?.profileDetails)
            // // updateState({...iState, bulkVendorModal:false, dealerName:res?.profileDetails?.name })
            // hideBulkVendor()
          })
          .catch((err) => {
            errorToast("Please enter valid JSON.")
            console.log("errr----->", err);
            updateState({...iState, loader:false})
          });
       
       
      } else {
        updateState({...iState, loader:true})
        dispatch(uploadBulkDataAction({ files, clientType: relationId, anchorId }))
          .then((res) => {
            console.log("+====ree", res?.storeData);
            setReportData(res?.storeData);
            sucessToast("Bulk data uploaded successfully");
            localStorage.setItem(
              "financeratingData",
              JSON.stringify(res?.financeRatingArr)
            );

            // updateState({...iState, reportData:res?.storeData, checkState:true, bulkVendorModal:false})
            // startRatingFUnc(res?.storeData, res?.financeRatingArr)
            hideBulkVendor();
          })
          .catch((err) => {
            console.log("errr----->", err);
          });
          updateState({...iState, loader:false})
      }
    }

    // }
  };

  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="TitleBox">
              <h4 className="Title">
                <Link to="/client-management" className="backArrow">
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link>
                Add Dealer Details
              </h4>
            </div>
            <div className="AnchorArea">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Enter Dealer Name </label>
                    <input
                      type="text"
                      placeholder="Enter Dealer Name"
                      className="form-control"
                      name="dealerName"
                      value={dealerName}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.dealerNameError}
                    </span>
                  </div>
                </div>
              </div>
              <div className="AnchorFatchData">
                <ul>
                  <li>
                    <div className="form-group">
                      <label>Enter PAN Number</label>
                      <input
                        type="text"
                        placeholder="Enter PAN Number"
                        className="form-control"
                        name="panNumber"
                        value={panNumber}
                        maxLength={10}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.panNumberError}
                      </span>
                    </div>
                  </li>
                
                </ul>
                {/* <ul>
                  <li>
                    <div className="form-group">
                      <label>Enter GSTIN Number</label>
                      <input
                        type="text"
                        placeholder="Enter GSTIN Number"
                        className="form-control"
                        name="gstinNumber"
                        value={gstinNumber}
                        maxLength={15}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.gstinNumberError}
                      </span>
                    </div>
                  </li>
                 
                </ul> */}
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Enter Aadhar Number</label>
                    <input
                      type="text"
                      placeholder="Enter Aadhar Number"
                      className="form-control"
                      name="aadharNumber"
                      value={aadharNumber}
                      maxLength={12}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.aadharNumberError}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Date of incorporation</label>
                    <input
                      type="text"
                      placeholder="Enter date of incorporation"
                      className="form-control"
                      name="dateOfIncorporation"
                      value={dateOfIncorporation}
                      // maxLength={4}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.dateOfIncorporationError}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      placeholder="Enter  Mobile Nummber..."
                      className="form-control"
                      name="mobileNumber"
                      value={mobileNumber}
                      maxLength={10}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.mobileNumberError}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="Enter email address"
                      className="form-control"
                      name="emailAddress"
                      value={emailAddress}
                      // maxLength={4}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.emailAddressError}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      placeholder="Enter address..."
                      className="form-control"
                      name="address"
                      value={address}
                      // maxLength={4}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.addressError}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Enter Base Year</label>
                    <input
                      type="text"
                      placeholder="Enter Base Year"
                      className="form-control"
                      name="baseYear"
                      value={baseYear}
                      maxLength={4}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.baseYearError}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Enter Expiry Date</label>
                    <input
                      type="date"
                      placeholder="Enter Base Year"
                      className="form-control"
                      name="expiryDate"
                      value={expiryDate}
                      // maxLength={4}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.expiryDateError}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Select Anchor</label>
                    <select
                      name="anchorId"
                      value={anchorId}
                      onChange={handleInput}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {checkArray(anchorDropdownData?.result) ? (
                        anchorDropdownData?.result?.map((ele, i) => {
                          return (
                            <>
                              <option value={ele?._id}>
                                {ele?.companyName}
                              </option>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.anchorIdError}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Dealer Relation</label>
                    <select
                      name="relationId"
                      value={relationId}
                      onChange={handleInput}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      <option value="Existing">Existing</option>
                      <option value="New">New</option>
                    </select>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.relationIdError}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Constitution Type</label>
                    <select
                      name="itrType"
                      value={itrType}
                      onChange={handleInput}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      <option value="itr3">Proprietorship</option>
                      <option value="itr5">Partnership</option>
                      <option value="itr6">Company (Private/ Public)</option>
                    </select>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.itrTypeError}
                    </span>
                  </div>
                </div>
              </div>
            
              <button
              onClick={showBulkVendor}
              className="Button newBtn"
              disabled={!anchorId || !relationId || !itrType}
            >
              Upload Data
            </button>
            {!uploadDataCheck ? <></>
            : 
            <span  className="newBtn" style={{color:"red", marginLeft:"20px"}}>Data is already uploaded</span>
             }
             
            </div>
          </div>
          {/* <div className="InformationBox">
            <h4>Basic Details</h4>
            <div className="Information">
              <article>
                <aside>
                  <p>
                    <strong>Dealer ID</strong> <span>#2364965097</span>
                  </p>
                  <p>
                    <strong>Anchor Company Name</strong>{" "}
                    <span> DCG Private Technologies</span>
                  </p>
                  <p>
                    <strong>Dealer Name</strong>{" "}
                    <span> DCG Private Technologies</span>
                  </p>
                  <p>
                    <strong>Dealer Address</strong>{" "}
                    <span>
                      14/223 Bhishma Pitamah Marg, New Delhi, Delhi 110067
                    </span>
                  </p>
                  <p>
                    <strong>Key Person Name</strong>{" "}
                    <span>Mr. Kamal Kumar </span>
                  </p>
                  <p>
                    <strong>Key Person Contact Number</strong>{" "}
                    <span>+91824097093</span>
                  </p>
                </aside>
                <aside>
                  <p>
                    <strong> Key Person Email Address</strong>{" "}
                    <span>Abc@gmail.com</span>
                  </p>
                  <p>
                    <strong>Nature of Business</strong>{" "}
                    <span> Manufacturing</span>
                  </p>
                  <p>
                    <strong>Date Registered on</strong> <span> 12/03/1992</span>
                  </p>
                  <p>
                    <strong> PAN number </strong> <span> 2364965097</span>
                  </p>
                  <p>
                    <strong>GSTIN Number </strong> <span> 2364965097</span>
                  </p>
                  <p>
                    <strong>CIN Number</strong> <span> 2364965097</span>
                  </p>
                </aside>
              </article>
            </div>
          </div> */}
          <a onClick={startRatingFUnc} className="Button newBtn">
            Start Rating
          </a>
        </div>
      </div>

      <Modal
        show={bulkVendorModal}
        onHide={hideBulkVendor}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={hideBulkVendor}
            >
              ×
            </a>
            <h3>Upload Data</h3>
            <div class="form-group">
              <label>Select</label>
              <select
                type="text"
                class="form-control"
                name="jsonExcel"
                value={jsonExcel}
                accept=".xlsx"
                onChange={handleInput}
              >
                <option value="">Select</option>
                <option value="JSON">JSON</option>
                <option value="EXCEL">Excel</option>
              </select>
              <span style={{ color: "red", fontSize: "14px" }}>
                {bulkDataError?.jsonExcelError}
              </span>
            
            </div>
            {jsonExcel === "JSON" ? 
            <>
            <div class="form-group">
              <label>Quasi Equity <a style={{marginLeft:"5px"}} title="USL taken from related parties"> <i class="fa fa-info" aria-hidden="true"></i></a></label>
              <input
                type="text"
                class="form-control"
                name="addValue"
                value={addValue}
                onChange={handleInput}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {bulkDataError?.addValueError}
              </span>

            
            </div>

            <div class="form-group">
              <label> Money taken out of business <a style={{marginLeft:"5px"}} title="Loan and advances given to related parties, investment made out the business etc"> <i class="fa fa-info" aria-hidden="true"></i></a></label>
              <input
                type="text"
                class="form-control"
                name="lessValue"
                value={lessValue}
                onChange={handleInput}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {bulkDataError?.lessValueError}
              </span>

            </div>
            </> : <></>
            }

             {jsonExcel === "JSON" ?   <div class="form-group">
              <label>Upload File</label>
            
              <input
                type="file"
                class="form-control"
                name="files"
                disabled = {!lessValue || !addValue}
                onChange={handleInputFile}
                />
                 
                {uploadDataCheck ? <span style={{ color: "red", fontSize: "14px" }}>Data is already uploaded</span>:""}
              <span style={{ color: "red", fontSize: "14px" }}>
                {bulkDataError?.filesError}
              </span>

              {/* <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.rangeError}
              </span> */}
            </div> : 
              <div class="form-group">
              <label>Upload File</label>
              <input
                type="file"
                class="form-control"
                name="files"
               
                onChange={handleInputFile}
              />
              {uploadDataCheck ? <span style={{ color: "red", fontSize: "14px" }}>Data is already uploaded</span>:""}
              <span style={{ color: "red", fontSize: "14px" }}>
                {bulkDataError?.filesError}
              </span>
              {/* {loader ? (
                          <LoadingSpinner
                            color="#fac6cf"
                            loading={loader}
                            size={30}
                          />
                        ) : 
                            <> </>} */}

              {/* <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.rangeError}
              </span> */}
            </div>
            }
          
            <div class="newBtn">
              <button class="Button" onClick={handleBulkVendor} disabled={loader}>
              {loader ? (
                           <>
                             {" "}
                           <span
                             className="spinner-border spinner-border-md"
                             role="status"
                             aria-hidden="true"
                           ></span>
                           </>
                        
                         ) : "Upload" }
                
              </button>
            </div>

            {/* <div className="newBtn">
  <input type="file" className=''  name="files" onChange={handleInputFile}/> 
    <button className="Button" onClick={hideBulkVendor}>
      No
    </button>
  </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Clientstartscoring;
