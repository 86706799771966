import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { useDispatch, useSelector } from 'react-redux'
import { anchorListAction } from '../redux/actions/anchorAction'
import NoDataFound from './NoDataFound'
import Loader from './Loader'
import { checkArray } from '../utils/CheckType'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import { filter } from 'lodash'
const init = {
  search:""
}
const Scoremanagement = () => {
  const [iState, updateState] = useState(init);
  const{search} = iState
  const dispatch = useDispatch();
  const {anchorListData, loader} = useSelector((state ) => state.mainReducer)
  const [activePage, updateActivePage] = useState(1);
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const [serialNo, updateSerialNo] = useState(10);
  useEffect(() => {
    dispatch(anchorListAction())
  },[])

  const applyFilter = () => {
    dispatch(anchorListAction(search, ))
  }
  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    let payloadData = {
      // search,
      // status,
      // startDate,
      // endDate,
      // timeFrame,
      // city,
      page:pageNumber
    };
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    dispatch(anchorListAction(
      search,
      "",
      "",
      "",
    pageNumber))
  }

  const filterSearch = (e) => {
    updateState({...iState, search:e.target.value})
    dispatch(anchorListAction(e.target.value))
  }

  // const handleEnter = (e) =>{
  //   if(e.keyCode == 13 ){
  //     dispatch(anchorListAction(e.target.value ))


  //   }}
  return (
    <>
    <Header/>
    <Sidenav/>
    <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">Score &amp; Weightage Management</h4>
      </div>

      {/* {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "score-management" &&
                      ele.fullAccess == true
                  ) && (
                    <>
                       <div className="Filter">
        <div className="form-group">
          <label>Search</label>
          <input type="text" className="form-control" placeholder="Search" name='search' value={search} onChange={filterSearch} />
        </div>
      </div>
                    </>
               
                  )}
                </>
              ) : (
                <>  */}
                  <div className="Filter">
        <div className="form-group">
          <label>Search</label>
          <input type="text" className="form-control" placeholder="Search" name='search' value={search} onChange={filterSearch}  />
        </div>
      </div>
                {/* </>
              )} */}
     
    
      <div className="TableList">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>Anchor ID</th>
              <th>Anchor Name</th>
              <th>Contact Person</th>
              <th>Contact Number</th>
              <th>Email Address</th>
              <th>Status</th>
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Score-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <th>configuring Scor &amp; Weightage</th>
                 
               
                  )}
                </>
              ) : (
                <>
                 <th>configuring Scor &amp; Weightage</th>
                 </>
              )}
             
            </tr>
          </thead>
          <tbody>
           
          {loader ? <>
            <Loader/>
            </>
            :

            checkArray(anchorListData?.result?.[0]?.paginationData) ? 
            anchorListData?.result?.[0]?.paginationData?.map((ele,i) => {           
              return(
                <>
 <tr>
              <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.anchor_number}</td>
              <td>{ele?.companyName}</td>
              <td>{ele?.contactPersonName}</td>
              <td>{ele?.contactPersonNumber}</td>
              <td>{ele?.contactPersonEmail}</td>
      
              <td>
                {ele?.status === "ACTIVE"?  <span className="Green">Active</span> :  <span className="Red">Inactive</span>}
               
              </td>
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Score-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <>
                       <td>
                <Link to ="/score-management-details" state={ele}>
                  <span>
                    <img src="images/settings.png" alt="" />
                  </span>
                </Link>
              </td>
                    </>
               
                  )}
                </>
              ) : (
                <>
                 <td>
                <Link to ="/score-management-details" state={ele}>
                  <span>
                    <img src="images/settings.png" alt="" />
                  </span>
                </Link>
              </td>
                 </>
              )}
             
            </tr>
                </>
              )
            })
            : <p>No Data Found</p>
            }
           
          </tbody>
        </table>
      </div>
      <div class="pagination">
              {/* <p>
                {" "}
                Total records : <span>{anchorListData?.result?.[0]?.totalCount?.[0]?.count}</span>
              </p> */}
              <ul>
                {anchorListData?.result?.[0]?.totalCount?.[0]?.count && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={anchorListData?.result?.[0]?.totalCount?.[0]?.count}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </ul>
            </div>
    </div>
  </div>
</div>

    </>
  )
}

export default  Scoremanagement