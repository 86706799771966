export const authAction = {
    ADMIN_LOGIN_INITIATE: "ADMIN_LOGIN_INITIATE",
    ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
    ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  
    ADMIN_DETAILS_INITIATE: "ADMIN_DETAILS_INITIATE",
    ADMIN_DETAILS_SUCCESS: "ADMIN_DETAILS_SUCCESS",
    ADMIN_DETAILS_FAILURE: "ADMIN_DETAILS_FAILURE",
  
    SEND_OTP_INITIATE: "SEND_OTP_INITIATE",
    SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
    SEND_OTP_FAILURE: "SEND_OTP_FAILURE",
  
    VERIFY_OTP_INITIATE: "VERIFY_OTP_INITIATE",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  
    FORGET_PASSWORD_INITIATE: "FORGET_PASSWORD_INITIATE",
    FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
    FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",
  
    CHANGE_PASSWORD_INITIATE: "CHANGE_PASSWORD_INITIATE",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  
    ADMIN_LOGOUT_INITIATE: "ADMIN__LOGOUT_INITIATE",
    ADMIN_LOGOUT_SUCCESS: "ADMIN__LOGOUT_SUCCESS",
    ADMIN_LOGOUT_FAILURE: "ADMIN__LOGOUT_FAILURE",
  
    DASHBOARD_INITITATE :"DASHBOARD_INITITATE",
    DASHBOARD_SUCCESS:"DASHBOARD_INITITATE",
    DASHOBARD_FAILURE:"DASHBOARD_FAILURE",

    STATE_LIST_INITIATE:"STATE_LIST_INITIATE",
    STATE_LIST_SUCCESS:"STATE_LIST_SUCCESS",
    STATE_LIST_FAILURE:"STATE_LIST_FAILURE",

    CITY_LIST_INITIATE:"CITY_LIST_INITIATE",
    CITY_LIST_SUCCESS:"CITY_LIST_SUCCESS",
    CITY_LIST_FAILURE:"CITY_LIST_FAILURE",

    CHANGE_PASSWORD_INITIATE: "CHANGE_PASSWORD_INITIATE",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",


    TOP_CLIENT_LIST_INITIATE: "TOP_CLIENT_LIST_INITIATE",
    TOP_CLIENT_LIST_SUCCESS: "TOP_CLIENT_LIST_SUCCESS",
    TOP_CLIENT_LIST_FAILURE: "TOP_CLIENT_LIST_FAILURE",

    LOWEST_CLIENT_LIST_INITIATE: "LOWEST_CLIENT_LIST_INITIATE",
    LOWEST_CLIENT_LIST_SUCCESS: "LOWEST_CLIENT_LIST_SUCCESS",
    LOWEST_CLIENT_LIST_FAILURE: "LOWEST_CLIENT_LIST_FAILURE",

    
  
    //   ADMIN_EMAIL_VERIFY_INITIATE: "ADMIN_EMAIL_VERIFY_INITIATE",
    //   ADMIN_EMAIL_VERIFY_SUCCESS: "ADMIN_EMAIL_VERIFY_SUCCESS",
    //   ADMIN_EMAIL_VERIFY_FAILURE: "ADMIN_EMAIL_VERIFY_FAILURE",
  };


  export const anchorAction = {
    ADD_ANCHOR_INITIATE: "ADD_ANCHOR_INITIATE",
    ADD_ANCHOR_SUCCESS: "ADD_ANCHOR_SUCCESS",
    ADD_ANCHOR_FAILURE: "ADD_ANCHOR_FAILURE",
  
    ANCHOR_LIST_INITIATE: "ANCHOR_LIST_INITIATE",
    ANCHOR_LIST_SUCCESS: "ANCHOR_LIST_SUCCESS",
    ANCHOR_LIST_FAILURE: "ANCHOR_LIST_FAILURE",

    ANCHOR_DETAILS_INITIATE: "ANCHOR_DETAILS_INITIATE",
    ANCHOR_DETAILS_SUCCESS: "ANCHOR_DETAILS_SUCCESS",
    ANCHOR_DETAILS_FAILURE: "ANCHOR_DETAILS_FAILURE",

    ANCHOR_STATUS_CHANGE_INITIATE: "ANCHOR_STATUS_CHANGE_INITIATE",
    ANCHOR_STATUS_CHANGE_SUCCESS: "ANCHOR_STATUS_CHANGE_SUCCESS",
    ANCHOR_STATUS_CHANGE_FAILURE: "ANCHOR_STATUS_CHANGE_FAILURE",

    UPDATE_ANCHOR_INITIATE: "UPDATE_ANCHOR_INITIATE",
    UPDATE_ANCHOR_SUCCESS: "UPDATE_ANCHOR_SUCCESS",
    UPDATE_ANCHOR_FAILURE: "UPDATE_ANCHOR_FAILURE",

    REMOVE_ANCHOR_INITIATE: "REMOVE_ANCHOR_INITIATE",
    REMOVE_ANCHOR_SUCCESS: "REMOVE_ANCHOR_SUCCESS",
    REMOVE_ANCHOR_FAILURE: "REMOVE_ANCHOR_FAILURE",

    USER_LIST_INITIATE: "USER_LIST_INITIATE",
    USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
    USER_LIST_FAILURE: "USER_LIST_FAILURE",

    USER_APPROVED_INITIATE: "USER_APPROVED_INITIATE",
    USER_APPROVED_SUCCESS: "USER_APPROVED_SUCCESS",
    USER_APPROVED_FAILURE: "USER_APPROVED_FAILURE",

    ANCHOR_DROPDOWN_INITIATE: "ANCHOR_DROPDOWN_INITIATE",
    ANCHOR_DROPDOWN_SUCCESS: "ANCHOR_DROPDOWN_SUCCESS",
    ANCHOR_DROPDOWN_FAILURE: "ANCHOR_DROPDOWN_FAILURE",

    USER_DETAILS_INITIATE: "USER_DETAILS_INITIATE",
    USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
    USER_DETAILS_FAILURE: "USER_DETAILS_FAILURE",

    

    UPDATE_USER_LIST_INITIATE: "UPDATE_USER_LIST_INITIATE",
    UPDATE_USER_LIST_SUCCESS: "UPDATE_USER_LIST_SUCCESS",
    UPDATE_USER_LIST_FAILURE: "UPDATE_USER_LIST_FAILURE",


    ANCHOR_USER_LIST_INITIATE: "ANCHOR_USER_LIST_INITIATE",
    ANCHOR_USER_LIST_SUCCESS: "ANCHOR_USER_LIST_SUCCESS",
    ANCHOR_USER_LIST_FAILURE: "ANCHOR_USER_LIST_FAILURE",


    CHANGE_USER_STATUS_INITIATE: "CHANGE_USER_STATUS_INITIATE",
    CHANGE_USER_STATUS_SUCCESS: "CHANGE_USER_STATUS_SUCCESS",
    CHANGE_USER_STATUS_FAILURE: "CHANGE_USER_STATUS_FAILURE",

    ANCHOR_USER_LIST_INITIATE: "ANCHOR_USER_LIST_INITIATE",
    ANCHOR_USER_LIST_SUCCESS: "ANCHOR_USER_LIST_SUCCESS",
    ANCHOR_USER_LIST_FAILURE: "ANCHOR_USER_LIST_FAILURE",

  };



  export const staffManagementAction = {
    ADD_STAFF_INITIATE: "ADD_STAFF_INITIATE",
    ADD_STAFF_SUCCESS: "ADD_STAFF_SUCCESS",
    ADD_STAFF_FAILURE: "ADD_STAFF_FAILURE",
  
    STAFF_LIST_INITIATE: "STAFF_LIST_INITIATE",
    STAFF_LIST_SUCCESS: "STAFF_LIST_SUCCESS",
    STAFF_LIST_FAILURE: "STAFF_LIST_FAILURE",

    STAFF_DETAILS_INITIATE: "STAFF_DETAILS_INITIATE",
    STAFF_DETAILS_SUCCESS: "STAFF_DETAILS_SUCCESS",
    STAFF_DETAILS_FAILURE: "STAFF_DETAILS_FAILURE",

    STAFF_STATUS_CHANGE_INITIATE: "STAFF_STATUS_CHANGE_INITIATE",
    STAFF_STATUS_CHANGE_SUCCESS: "STAFF_STATUS_CHANGE_SUCCESS",
    STAFF_STATUS_CHANGE_FAILURE: "STAFF_STATUS_CHANGE_FAILURE",

    UPDATE_STAFF_INITIATE: "UPDATE_STAFF_INITIATE",
    UPDATE_STAFF_SUCCESS: "UPDATE_STAFF_SUCCESS",
    UPDATE_STAFF_FAILURE: "UPDATE_STAFF_FAILURE",

   
  };
  



  export const scoreManagementAction = {
  
    OVERALL_WEIGHTAGE_LIST_INITIATE: "OVERALL_WEIGHTAGE_LIST_INITIATE",
    OVERALL_WEIGHTAGE_LIST_SUCCESS: "OVERALL_WEIGHTAGE_LIST_SUCCESS",
    OVERALL_WEIGHTAGE_LIST_FAILURE: "OVERALL_WEIGHTAGE_LIST_FAILURE",

    ANCHOR_RISK_LIST_INITIATE: "ANCHOR_RISK_LIST_INITIATE",
    ANCHOR_RISK_LIST_SUCCESS: "ANCHOR_RISK_LIST_SUCCESS",
    ANCHOR_RISK_LIST_FAILURE: "ANCHOR_RISK_LIST_FAILURE",

    NON_FINANCIAL_RISK_LIST_INITIATE: "NON_FINANCIAL_RISK_LIST_INITIATE",
    NON_FINANCIAL_RISK_LIST_SUCCESS: "NON_FINANCIAL_RISK_LIST_SUCCESS",
    NON_FINANCIAL_RISK_LIST_FAILURE: "NON_FINANCIAL_RISK_LIST_FAILURE",

    OVERALL_WEIGHTAGE_DETAILS_INITIATE: "OVERALL_WEIGHTAGE_DETAILS_INITIATE",
    OVERALL_WEIGHTAGE_DETAILS_SUCCESS: "OVERALL_WEIGHTAGE_DETAILS_SUCCESS",
    OVERALL_WEIGHTAGE_DETAILS_FAILURE: "OVERALL_WEIGHTAGE_DETAILS_FAILURE",

    STAFF_STATUS_CHANGE_INITIATE: "STAFF_STATUS_CHANGE_INITIATE",
    STAFF_STATUS_CHANGE_SUCCESS: "STAFF_STATUS_CHANGE_SUCCESS",
    STAFF_STATUS_CHANGE_FAILURE: "STAFF_STATUS_CHANGE_FAILURE",

    UPDATE_STAFF_INITIATE: "UPDATE_STAFF_INITIATE",
    UPDATE_STAFF_SUCCESS: "UPDATE_STAFF_SUCCESS",
    UPDATE_STAFF_FAILURE: "UPDATE_STAFF_FAILURE",


   ADD_MORE_NON_FINANCIAL_INITIATE: "ADD_MORE_NON_FINANCIAL_INITIATE",
    ADD_MORE_NON_FINANCIAL_SUCCESS: "ADD_MORE_NON_FINANCIAL_SUCCESS",
    ADD_MORE_NON_FINANCIAL_FAILURE: "ADD_MORE_NON_FINANCIAL_FAILURE",

    ADD_MORE_ANCHOR_INITIATE: "ADD_MORE_ANCHOR_INITIATE",
    ADD_MORE_ANCHOR_SUCCESS: "ADD_MORE_ANCHOR_SUCCESS",
    ADD_MORE_ANCHOR_FAILURE: "ADD_MORE_ANCHOR_FAILURE",

    
   ADD_MORE_FINANCIAL_INITIATE: "ADD_MORE_FINANCIAL_INITIATE",
   ADD_MORE_FINANCIAL_SUCCESS: "ADD_MORE_FINANCIAL_SUCCESS",
   ADD_MORE_FINANCIAL_FAILURE: "ADD_MORE_FINANCIAL_FAILURE",

    REMOVE_NON_FINANCIAL_INITIATE:"REMOVE_NON_FINANCIAL_INITIATE",
    REMOVE_NON_FINANCIAL_SUCCESS:"REMOVE_NON_FINANCIAL_SUCCESS",
    REMOVE_NON_FINANCIAL_FAILURE:"REMOVE_NON_FINANCIAL_FAILURE",

    REMOVE_OVERALL_INITIATE:"REMOVE_OVERALL_INITIATE",
    REMOVE_OVERALL_SUCCESS:"REMOVE_OVERALL_SUCCESS",
    REMOVE_OVERALL_FAILURE:"REMOVE_OVERALL_FAILURE",

    REMOVE_ANCHOR_RISK_INITIATE:"REMOVE_ANCHOR_RISK_INITIATE",
    REMOVE_ANCHOR_RISK_SUCCESS:"REMOVE_ANCHOR_RISK_SUCCESS",
    REMOVE_ANCHOR_RISK_FAILURE:"REMOVE_ANCHOR_RISK_FAILURE",


    UPDATE_OVERALL_INITIATE: "UPDATE_OVERALL_INITIATE",
    UPDATE_OVERALL_SUCCESS: "UPDATE_OVERALL_SUCCESS",
    UPDATE_OVERALL_FAILURE: "UPDATE_OVERALL_FAILURE",

    UPDATE_ANCHOR_RISK_INITIATE: "UPDATE_ANCHOR_RISK_INITIATE",
    UPDATE_ANCHOR_RISK_SUCCESS: "UPDATE_ANCHOR_RISK_SUCCESS",
    UPDATE_ANCHOR_RISK_FAILURE: "UPDATE_ANCHOR_RISK_FAILURE",

    UPDATE_FINANCIAL_INITIATE: "UPDATE_FINANCIAL_INITIATE",
   UPDATE_FINANCIAL_SUCCESS: "UPDATE_FINANCIAL_SUCCESS",
   UPDATE_FINANCIAL_FAILURE: "UPDATE_FINANCIAL_FAILURE",


    UPDATE_NON_FINANCIAL_INITIATE: "UPDATE_NON_FINANCIAL_INITIATE",
    UPDATE_NON_FINANCIAL_SUCCESS: "UPDATE_NON_FINANCIAL_SUCCESS",
    UPDATE_NON_FINANCIAL_FAILURE: "UPDATE_NON_FINANCIAL_FAILURE",

    
  };
  


  
  export const clientManagementAction = {

    CLIENT_LIST_INITIATE: "CLIENT_LIST_INITIATE",
    CLIENT_LIST_SUCCESS: "CLIENT_LIST_SUCCESS",
    CLIENT_LIST_FAILURE: "CLIENT_LIST_FAILURE",

    CLIENT_DETAILS_INITIATE: "CLIENT_DETAILS_INITIATE",
    CLIENT_DETAILS_SUCCESS: "CLIENT_DETAILS_SUCCESS",
    CLIENT_DETAILS_FAILURE: "CLIENT_DETAILS_FAILURE",

    CLIENT_STATUS_CHANGE_INITIATE: "CLIENT_STATUS_CHANGE_INITIATE",
    CLIENT_STATUS_CHANGE_SUCCESS: "CLIENT_STATUS_CHANGE_SUCCESS",
    CLIENT_STATUS_CHANGE_FAILURE: "CLIENT_STATUS_CHANGE_FAILURE",

    UPDATE_CLIENT_INITIATE: "UPDATE_CLIENT_INITIATE",
    UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
    UPDATE_CLIENT_FAILURE: "UPDATE_CLIENT_FAILURE",


    GET_NON_FINANCE_DETAILS_INITIATE: "GET_NON_FINANCE_DETAILS_INITIATE",
    GET_NON_FINANCE_DETAILS_SUCCESS: "GET_NON_FINANCE_DETAILS_SUCCESS",
    GET_NON_FINANCE_DETAILS_FAILURE: "GET_NON_FINANCE_DETAILS_FAILURE",

    GET_FINANCE_DETAILS_INITIATE: "GET_FINANCE_DETAILS_INITIATE",
    GET_FINANCE_DETAILS_SUCCESS: "GET_FINANCE_DETAILS_SUCCESS",
    GET_FINANCE_DETAILS_FAILURE: "GET_FINANCE_DETAILS_FAILURE",

    UNAPPROVED_LIST_INITIATE: "UNAPPROVED_LIST_INITIATE",
    UNAPPROVED_LIST_SUCCESS: "UNAPPROVED_LIST_SUCCESS",
    UNAPPROVED_LIST_FAILURE: "UNAPPROVED_LIST_FAILURE",

    
    RATING_STATUS_INITIATE: "RATING_STATUS_INITIATE",
    RATING_STATUS_SUCCESS: "RATING_STATUS_SUCCESS",
    RATING_STATUS_FAILURE: "RATING_STATUS_FAILURE",
 

    ADD_NONFINANCIAL_RATING_INITIATE: "ADD_NONFINANCIAL_RATING_INITIATE",
    ADD_NONFINANCIAL_RATING_SUCCESS: "ADD_NONFINANCIAL_RATING_SUCCESS",
    ADD_NONFINANCIAL_RATING_FAILURE: "ADD_NONFINANCIAL_RATING_FAILURE",

    GET_SCORE_INITIATE: "GET_SCORE_INITIATE",
    GET_SCORE_SUCCESS: "GET_SCORE_SUCCESS",
    GET_SCORE_FAILURE: "GET_SCORE_FAILURE",
 
    UPDATE_FINANCIAL_RATING_INITIATE: "UPDATE_FINANCIAL_RATING_INITIATE",
    UPDATE_FINANCIAL_RATING_SUCCESS: "UPDATE_FINANCIAL_RATING_SUCCESS",
    UPDATE_FINANCIAL_RATING_FAILURE: "UPDATE_FINANCIAL_RATING_FAILURE",
 

    UPDATE_NON_FINANCIAL_RATING_INITIATE: "UPDATE_NON_FINANCIAL_RATING_INITIATE",
    UPDATE_NON_FINANCIAL_RATING_SUCCESS: "UPDATE_NON_FINANCIAL_RATING_SUCCESS",
    UPDATE_NON_FINANCIAL_RATING_FAILURE: "UPDATE_NON_FINANCIAL_RATING_FAILURE",
   
  };
  

