import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendOtpOnEmailAction, verifyOtpAction } from '../redux/actions/authAction';
import { errorToast, sucessToast } from '../utils/toast';
const initialState = {
  errors: {},
  email:""
};
const Loginverification = () => {
  const [iState, updateState] = useState(initialState);
  const {  email,errors} = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const{state } = useLocation()
  const adminData =  JSON.parse(localStorage.getItem("sgEmail"))
  // const adminEmail = adminData.email;
  useEffect(() => {
   updateState({...iState, email:adminData})
  }, []);
  const [otp, setOtpState] = useState({
    dig1: "",
    dig2: "",
    dig3: "",
    dig4: "",
    dig5: "",
    dig6: "",
  })

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;

      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
     
        const next = elmnt.target.tabIndex;
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
        }
    }

  }
  const otpHandleChange = (e) => {
    const { name, value } = e.target;
    setOtpState({
      ...otp,
      [name]: value,
    });
  };


  const handleOtp = (e) => {
    const { name, value } = e.target;
    console.log("name->", name, "value->", value)
    updateState({ ...iState, [name]: value });
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    // let formIsValid = 
    await dispatch(sendOtpOnEmailAction({ email })).then((res) => {
      if (res.code === 200) {
        console.log(res.data)
        sucessToast("Otp resend successfully")
        // sucessToast(res.otp)
        setOtpState({
          dig1: "",
          dig2: "",
          dig3: "",
          dig4: "",
          dig5: "",
          dig6: "",
        })
      } else {
        errorToast("Internal server error")
      }
    });
  };
  console.log("otptpt", Object.values(otp).filter(otp=>otp).length)
  let handleValidation = () => {
    let error = {};
    let formIsValid = true;
    if (Object.values(otp).filter(otp=>otp).length !== 6) {
      error.otpError = "Please Enter a OTP";
      formIsValid = false;
    }
    updateState({...iState, errors:error})
    return formIsValid;
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
  
    let formIsValid = handleValidation();
    if (formIsValid) {
      await dispatch(verifyOtpAction({ email  ,  otp:
        otp.dig1 +
        otp.dig2 +
        otp.dig3 +
        otp.dig4 +
        otp.dig5 +
        otp.dig6,
}))
        .then((res) => {
          console.log(res)
          if (res.code === 200) {
            sucessToast("Otp verified successfully")
            // sucessToast("Otp verified successfully")
            navigate("/change-password");
          }
        })

        .catch((err) => {
          errorToast("Incorrect otp")
        });
    }
  };
  return (
     <div>
        <div className="LoginArea">
  <div className="LoginBox">
    <div className="LoginLeft">
      <h2>
        SG <span>Finserve</span>
      </h2>
      <h3>
        <span>Please Enter 6-digit OTP</span>
      </h3>
      <p>OTP has been sent to your email address</p>
      <form onSubmit={verifyOtp}>
        <div className="form-group">
          <div className="OTPBox">
          <input
                    value={otp.dig1}
                    name="dig1"
                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="1" maxLength="1" onKeyUp={e =>inputfocus(e)}


                  />
                  <input
                    value={otp.dig2}
                    name="dig2"
                    onChange={otpHandleChange}
                    type="text"
                    tabIndex="2" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    class="form-control"
                  />
                  <input
                    value={otp.dig3}
                    name="dig3"
                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="3" maxLength="1" onKeyUp={e =>inputfocus(e)}

                  />
                  <input
                    value={otp.dig4}
                    name="dig4"
                    tabIndex="4" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                  />
                  <input
                    value={otp.dig5}
                    name="dig5"
                    onChange={otpHandleChange}
                    type="text"

                    tabIndex="5" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    class="form-control"
                  />
                  <input
                    value={otp.dig6}
                    name="dig6"

                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="6" maxLength="1" onKeyUp={e =>inputfocus(e)}

                  />

          </div>
          <span style={{color:"red", fontSize:"14px"}}>{errors.otpError}</span>
          <p className="text-right" onClick={resendOtp}>
            <a>Resend OTP</a>
          </p>
        </div>
        <a onClick={verifyOtp} className="Button" >
          Verify OTP
        </a>
        {/* <button>Verify</button> */}
      </form>
    </div>
    <div className="LoginRight">
      <figure>
        <img src="images/logo.png" />
      </figure>
      <h5>
        Let's Started <span>SG Finserve</span>
      </h5>
      {/* <h6>To keep connected wit us please login .</h6> */}
    </div>
  </div>
</div>

     </div>
  )
}

export default Loginverification