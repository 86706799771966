import React from 'react'
import Lottie from "lottie-web";
import animationData from "../assets/animation/loader.json";
import ReactLottie from "react-lottie";

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};
const Loader = () => {
  return (
    <div style={{paddingLeft:'300px'}}>
        <ReactLottie
          options={animationOptions}
          width={"200px"}
          height={"200px"} 
          className="gray"
        />
    </div>
  )
}

export default Loader