import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { toast } from "react-toastify";
import Sidenav from "./Sidenav";
import Header from "./Header";
// import Logo from "../assets/images/logo.png";

const SubAdminDashboard = () => {
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  console.log(adminData);
  return (
    <div>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
      <div className="center__box">
        <img src="images/logo.png" />
        <h1>
          Welcome to <br /> SG finserv <br />
          {adminData?.name}
           (sub-admin)
        </h1>
      </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default SubAdminDashboard;
