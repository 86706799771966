import React from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { Link } from 'react-router-dom'

function Clientmanagement() {
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  return (
    <>
    <Header/>
    <Sidenav/>
    <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">Client Management Dashboard</h4>
      </div>
      <div className="DashboardBox">
        <ul>
          <li>
            <Link to="/client-view-score">
              <aside>
                <span className="Icon">
                  <i className="fa fa-eye" aria-hidden="true" />
                </span>
                <p>View Score </p>
              </aside>
            </Link>
          </li>
        
                   <li>
            <Link to="/client-start-scoring">
              <aside>
                <span className="Icon">
                  <i className="fa fa-sort-amount-asc" aria-hidden="true" />
                </span>
                <p>Start Scoring </p>
              </aside>
            </Link>
          </li>
               
        
          <li>
            <Link to="/client-score-pending">
              <aside>
                <span className="Icon">
                  <i className="fa fa-superpowers" aria-hidden="true" />
                </span>
                <p>Scoring Pending for approval</p>
              </aside>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default Clientmanagement