import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  changeClientStatusAction,
  clientDownloadListAction,
  clientListAction,
} from "../redux/actions/clientAction";
import Loader from "./Loader";
import { checkArray } from "../utils/CheckType";
import moment from "moment";
import Pagination from "react-js-pagination";
import { sucessToast } from "../utils/toast";
import * as XLSX from "xlsx";
import { Modal } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
const init = {
  search: "",
  startDate: "",
  endDate: "",
  timeFrame: "",
  blockModal: false,
  unblockModal: false,
  modalId: "",
  status: "",
  deleteModal: false,
  modalName: "",
  setDownloadData: [],
};
function Clientviewscore() {
  const [iState, updateState] = useState(init);
  const tableRef = useRef(null);
  const {
    search,
    startDate,
    endDate,
    timeFrame,
    blockModal,
    modalName,
    unblockModal,
    modalId,
    status,
    deleteModal,
    setDownloadData,
  } = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  let loader = false;
  const [clientListData, setClientListData] = useState([]);
  // const {clientListData, loader} = useSelector((state ) => state.mainReducer)
  console.log("clientList", clientListData);
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  useEffect(() => {
    dispatch(clientListAction()).then((res) => {
      setClientListData(res);
    });
  }, [dispatch]);

  const handleRefresh = () => {
    updateState(init);
    dispatch(clientListAction()).then((res) => {
      setClientListData(res);
    });
  };
  const applyFilter = () => {
    dispatch(clientListAction(search, startDate, endDate, timeFrame)).then(
      (res) => {
        setClientListData(res);
      }
    );
  };

  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    let payloadData = {
      search,
      startDate,
      endDate,
      timeFrame,
      page: pageNumber,
    };
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    dispatch(
      clientListAction(search, startDate, endDate, timeFrame, pageNumber)
    ).then((res) => {
      setClientListData(res);
    });
  };

  const changeAnchorStatusFunc = () => {
    dispatch(changeClientStatusAction({ id: modalId, status })).then((res) => {
      console.log("ressss", res);
      sucessToast("Status changed successfully");
      dispatch(clientListAction()).then((res) => {
        setClientListData(res);
      });
      if (status == "ACTIVE") {
        console.log(status);
        hideUnblockModal();
      } else {
        hideBlockModal();
        // hideUnblockModal()
      }
    });
  };

  const removeAnchorFunc = () => {
    dispatch(changeClientStatusAction({ id: modalId, status: "DELETED" })).then(
      (res) => {
        console.log("ressss", res);
        sucessToast("Client removed successfully");
        dispatch(clientListAction()).then((res) => {
          setClientListData(res);
        });
        hideDeleteModal();
      }
    );
  };

  const showBlockModal = (_id, status, name) => {
    updateState({
      ...iState,
      blockModal: true,
      modalId: _id,
      status: status,
      modalName: name,
    });
  };

  const hideBlockModal = () => {
    updateState({ ...iState, blockModal: false, modalName: "" });
  };

  const showUnblockModal = (_id, status, name) => {
    updateState({
      ...iState,
      unblockModal: true,
      modalId: _id,
      status: status,
      modalName: name,
    });
  };

  const hideUnblockModal = () => {
    updateState({ ...iState, unblockModal: false, modalName: "" });
  };

  const showDeleteModal = (_id, name) => {
    updateState({
      ...iState,
      deleteModal: true,
      modalId: _id,
      modalName: name,
    });
  };

  const hideDeleteModal = () => {
    updateState({ ...iState, deleteModal: false, modalId: "" });
  };

  const handleEnter = (e) => {
    if (e.keyCode == 13) {
      dispatch(clientListAction(search, startDate, endDate, timeFrame)).then(
        (res) => {
          setClientListData(res);
        }
      );
    }
  };

  const [downloadDataExcel, setDownloadDataDownload] = useState([]);

  const downloadCSV = async () => {
    try {
      dispatch(
        clientDownloadListAction(
          "",
          "",
          "",
          "",
          "",
          clientListData?.result?.[0]?.totalCount[0]?.count
        )
      ).then((res) => {
        console.log("---------->", res);
        setDownloadDataDownload(res);
        setTimeout(() => {
          var table_elt = document.getElementById("my-table-id");

          var workbook = XLSX.utils.table_to_book(table_elt);
          // const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(downloadDataExcel);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Client");
          XLSX.writeFile(workbook, "Client.xlsx");
          setDownloadDataDownload([]);
        }, 1000);
      });

      // const blob = new Blob([anchorListData?.result?.[0]?.paginationData], { type: 'text/csv;charset=utf-8;' });
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.setAttribute('href', url);
      // link.setAttribute('download', 'data.csv');
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };
  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="TitleBox">
              <h4 className="Title">
                <a onClick={() => navigate(-1)} className="backArrow">
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </a>
                Client Listing
              </h4>
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "client-view-score" && ele.fullAccess == true
                  ) && (
                    <div className="sms">
                      <a onClick={downloadCSV} className="TitleLink">
                        Download CSV
                      </a>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="sms">
                    {/* <DownloadTableExcel
                    filename="Client List"
                    sheet="Client List"
                    currentTableRef={tableRef.current}
                > */}
                    <a onClick={downloadCSV} className="TitleLink">
                      Download CSV
                    </a>

                    {/* </DownloadTableExcel> */}
                  </div>
                </>
              )}
            </div>
            {adminData?.userType == "STAFF" ? (
              <>
                {adminData?.permission?.some(
                  (ele) =>
                    ele.name == "client-view-score" && ele.fullAccess == true
                ) && (
                  <div className="Filter">
                    <div className="form-group">
                      <label>Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        value={search}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <label>From</label>
                      <input
                        type="date"
                        className="form-control"
                        name="startDate"
                        value={startDate}
                        max={moment().format("YYYY-MM-DD")}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <label>To</label>
                      <input
                        type="date"
                        className="form-control"
                        name="endDate"
                        value={endDate}
                        min={moment(startDate).format("YYYY-MM-DD")}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <label>Select From</label>
                      <select
                        className="form-control"
                        name="timeFrame"
                        value={timeFrame}
                        onChange={handleInput}
                      >
                        <option value="">Select</option>
                        <option value="Today">Today</option>
                        <option value="Week">Last Week</option>
                        <option value="Month">Last Month</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>&nbsp;</label>
                      <button onClick={applyFilter} className="Button">
                        Apply
                      </button>{" "}
                      &nbsp; &nbsp;
                      <button onClick={handleRefresh} className="Button Cancel">
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="Filter">
                  <div className="form-group">
                    <label>Search</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      name="search"
                      value={search}
                      onChange={handleInput}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <div className="form-group">
                    <label>From</label>
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={startDate}
                      max={moment().format("YYYY-MM-DD")}
                      disabled={timeFrame}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group">
                    <label>To</label>
                    <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={endDate}
                      min={moment(startDate).format("YYYY-MM-DD")}
                      disabled={timeFrame}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group">
                    <label>Select From</label>
                    <select
                      className="form-control"
                      name="timeFrame"
                      value={timeFrame}
                      disabled={startDate || endDate}
                      onChange={handleInput}
                    >
                      <option value="">Select</option>
                      <option value="Today">Today</option>
                      <option value="Week">Last Week</option>
                      <option value="Month">Last Month</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>&nbsp;</label>
                    <button onClick={applyFilter} className="Button">
                      Apply
                    </button>{" "}
                    &nbsp; &nbsp;
                    <button onClick={handleRefresh} className="Button Cancel">
                      <i className="fa fa-refresh" />
                    </button>
                  </div>
                </div>
              </>
            )}
            {/* ref={tableRef} */}
            <div className="TableList">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Sl. No</th>
                    <th>Dealer ID</th>
                    <th>Dealer Name</th>
                    <th>Dealer Address</th>
                    <th>Relation</th>
                    <th>Parent Anchor</th>
                    <th>Mobile Number</th>
                    <th>Email</th>
                    <th>CIN Number</th>
                    <th>Expiry Date</th>
                    <th>Date of Registration</th>
                    {adminData?.userType == "STAFF" ? (
                      <>
                        {adminData?.permission?.some(
                          (ele) =>
                            ele.name == "Client-Management" &&
                            ele.fullAccess == true
                        ) && (
                          <>
                            <th>View Score</th>
                            <th>Action</th>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <th>View Score</th>

                        <th>Action</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <Loader />
                    </>
                  ) : checkArray(
                      clientListData?.result?.[0]?.paginationData
                    ) ? (
                    clientListData?.result?.[0]?.paginationData?.map(
                      (ele, i) => {
                        console.log("elellelelee", ele);
                        return (
                          <>
                            <tr>
                              <td>{i + 1 + serialNo - 10}</td>
                              <td>{ele?.dealer_number}</td>
                              <td>{ele?.dealerName}</td>
                              <td>{ele?.basicDetails?.dealerAddress}</td>
                              <td>{ele?.relationId}</td>
                              <td>{ele?.anchorData?.[0]?.companyName}</td>
                              <td>{ele?.basicDetails?.keyPersonContact}</td>
                              <td>{ele?.basicDetails?.keyPersonEmail}</td>
                              <td>{ele?.anchorData?.[0]?.cnnNumber}</td>
                              <td>{ele?.expiryDate?.split("T")[0]}</td>
                              <td>{ele?.createdAt?.split("T")[0]}</td>
                              {adminData?.userType == "STAFF" ? (
                                <>
                                  {adminData?.permission?.some(
                                    (ele) =>
                                      ele.name == "Client-Management" &&
                                      ele.fullAccess == true
                                  ) && (
                                    <>
                                      <td>
                                        <Link
                                          to="/client-financial-rating"
                                          state={ele}
                                        >
                                          <img src="images/eye.png" alt="" />
                                        </Link>
                                      </td>

                                      <td>
                                        <div className="Actions">
                                          {ele?.status === "ACTIVE" ? (
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                checked={true}
                                                onChange={() =>
                                                  showBlockModal(
                                                    ele?._id,
                                                    "INACTIVE"
                                                  )
                                                }
                                              />
                                              <span className="slider"></span>
                                            </label>
                                          ) : (
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                checked={false}
                                                onChange={() =>
                                                  showUnblockModal(
                                                    ele?._id,
                                                    "ACTIVE"
                                                  )
                                                }
                                              />
                                              <span className="slider"></span>
                                            </label>
                                          )}
                                          <Link
                                            className="Blue"
                                            to="/client-view-score-details"
                                            state={ele}
                                          >
                                            <i className="fa fa-eye" />
                                          </Link>

                                          <a
                                            className="Red"
                                            onClick={() =>
                                              showDeleteModal(ele?._id)
                                            }
                                          >
                                            <i className="fa fa-trash" />
                                          </a>
                                        </div>
                                      </td>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <td>
                                    <Link
                                      to="/client-financial-rating"
                                      state={ele}
                                    >
                                      <img src="images/eye.png" alt="" />
                                    </Link>
                                  </td>

                                  <td>
                                    <div className="Actions">
                                      {ele?.status === "ACTIVE" ? (
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={true}
                                            onChange={() =>
                                              showBlockModal(
                                                ele?._id,
                                                "INACTIVE",
                                                ele?.dealerName
                                              )
                                            }
                                          />
                                          <span className="slider"></span>
                                        </label>
                                      ) : (
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={false}
                                            onChange={() =>
                                              showUnblockModal(
                                                ele?._id,
                                                "ACTIVE",
                                                ele?.dealerName
                                              )
                                            }
                                          />
                                          <span className="slider"></span>
                                        </label>
                                      )}
                                      <Link
                                        className="Blue"
                                        to="/client-view-score-details"
                                        state={ele}
                                      >
                                        <i className="fa fa-eye" />
                                      </Link>

                                      <a
                                        className="Red"
                                        onClick={() =>
                                          showDeleteModal(
                                            ele?._id,
                                            ele?.dealerName
                                          )
                                        }
                                      >
                                        <i className="fa fa-trash" />
                                      </a>
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          </>
                        );
                      }
                    )
                  ) : (
                    <p>No data found</p>
                  )}
                </tbody>
              </table>
            </div>
            <div class="pagination">
              <p>
                {" "}
                {/* Total records : <span>{anchorListData?.result?.[0]?.totalCount?.[0]?.count}</span> */}
              </p>
              <ul>
                {clientListData?.result?.[0]?.totalCount?.[0]?.count && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={
                      clientListData?.result?.[0]?.totalCount?.[0]?.count
                    }
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={blockModal}
        onHide={hideBlockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={hideBlockModal}
            >
              ×
            </a>
            <h3>Are you sure you want to block {modalName} client ? </h3>
            <div className="newBtn">
              <button
                className="Button active"
                onClick={() => changeAnchorStatusFunc("ACTIVE")}
              >
                Yes
              </button>{" "}
              &nbsp; &nbsp;
              <button className="Button" onClick={hideBlockModal}>
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={unblockModal}
        onHide={hideUnblockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={hideUnblockModal}
            >
              ×
            </a>
            <h3>Are you sure you want to unblock {modalName} client ? </h3>
            <div className="newBtn">
              <button
                className="Button"
                onClick={() => changeAnchorStatusFunc("INACTIVE")}
              >
                Yes
              </button>{" "}
              &nbsp; &nbsp;
              <button className="Button" onClick={hideUnblockModal}>
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteModal}
        onHide={hideDeleteModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <button onClick={hideDeleteModal} className="CloseModal">
              ×
            </button>
            <h3>Delete</h3>
            <figure>
              <img
                src="./static/media/deleteAlert.488dad788fffd1c04acd.png"
                width={50}
              />
            </figure>
            <p>Are you sure you want to delete this {modalName} Client ?</p>
            <h4>
              <button className="Button" onClick={removeAnchorFunc}>
                Delete
              </button>{" "}
              &nbsp; &nbsp;
              <button onClick={hideDeleteModal} className="Button">
                Cancel
              </button>
            </h4>
          </div>
        </Modal.Body>
      </Modal>

      {/* ref={tableRef} */}
      <div style={{ display: "none" }} className="TableList">
        <table id="my-table-id" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>Dealer ID</th>
              <th>Dealer Name</th>
              <th>Dealer Address</th>
              <th>Relation</th>
              <th>Parent Anchor</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>CIN Number</th>
              <th>Expiry Date</th>
              <th>Date of Registration</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <>
                <Loader />
              </>
            ) : checkArray(downloadDataExcel?.result?.[0]?.paginationData) ? (
              downloadDataExcel?.result?.[0]?.paginationData?.map((ele, i) => {
                console.log("elellelelee", ele);
                return (
                  <>
                    <tr>
                      <td>{i + 1 + serialNo - 10}</td>
                      <td>{ele?.dealer_number}</td>
                      <td>{ele?.dealerName}</td>
                      <td>{ele?.basicDetails?.dealerAddress}</td>
                      <td>{ele?.relationId}</td>
                      <td>{ele?.anchorData?.[0]?.companyName}</td>
                      <td>{ele?.basicDetails?.keyPersonContact}</td>
                      <td>{ele?.basicDetails?.keyPersonEmail}</td>
                      <td>{ele?.anchorData?.[0]?.cnnNumber}</td>
                      <td>{ele?.expiryDate?.split("T")[0]}</td>
                      <td>{ele?.createdAt?.split("T")[0]}</td>
                    </tr>
                  </>
                );
              })
            ) : (
              <p>No data found</p>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Clientviewscore;
