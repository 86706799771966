import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  anchorDropdownAction,
  updateAccessAction,
  userApprovedAction,
  userDetailsAction,
} from "../redux/actions/anchorAction";
import Multiselect from "multiselect-react-dropdown";
import { sucessToast } from "../utils/toast";
import { checkArray } from "../utils/CheckType";
const init = {
  anchorData: [],
  anchorDataError: "",
  stateChange: false,
};
function Anchorpendingaccess() {
  const [iState, updateState] = useState(init);
  const {  anchorDataError, stateChange } = iState;

  const [anchorData, setAnchorData] =useState([]);
  const { state } = useLocation();
  const [preSelectedData, setPreSelectedData] = useState([])
  const [UserDetailsData, updateUserDetailsData] = useState([]);
  const navigate = useNavigate();
  const [anchorDetailsData, updateAnchorDetailsData] = useState([]);
  const dispatch = useDispatch();
  const { anchorDropdownData } = useSelector((state) => state.mainReducer);
 
  const customerOption = {
    options: anchorDropdownData?.result,
  };

  console.log("stateetetetet", state)
  useEffect(() => {
     
    dispatch(userDetailsAction(state?.state === "anchorDetails" ? state?._id : state)).then((res) => {
      updateUserDetailsData(res?.askedUser?.[0]);
      updateAnchorDetailsData(res?.data);
      console.log("ressss", res);
      let companyId = res?.data?.map((ele, i) => {
        return{ anchorId: ele?.anchorData?.[0]?._id};
       
      });
      setAnchorData(companyId)
      updateState({ ...iState, stateChange: true });
    });
    dispatch(anchorDropdownAction());
  }, []);


  useEffect(() => {
    let temp = anchorDetailsData
      ?.map((item) => {
        return item?.anchorData?.flat();
      })
      ?.flat();
setPreSelectedData(temp)
  }, [anchorDetailsData]);
  const onSelectCustomer = (selectedList, selectedItem) => {
    for (let i = 0; i < selectedList?.length; i++) {
      let data = {
        anchorId: selectedItem?._id,
      };

      setAnchorData([...anchorData, data])
    }
  };

  const onRemoveCustomer = (selectedList, removedItem) => {
    let tempList = anchorData;
    let idList = anchorData?.map((item) => {
      return item?.anchorDataId;
    });
    const removeIndex = tempList.findIndex(
      (item) => item.streamId === removedItem._id
    );
    tempList.splice(removeIndex, 1);
    updateState({
      ...iState,
      anchorData: tempList,
    });
  };

  // console.log("anchorData", anchorData.length);
  const approveUserFunc = () => {
    if (anchorData.length === 0) {
      updateState({ ...iState, anchorDataError: "Anchors can't be empty" });
    } else {
      let data = {
        status: "APPROVED",
        anchorData,
        userId: UserDetailsData?._id,
      };
      console.log("dataaa", data);
      dispatch(userApprovedAction(data)).then((res) => {
        console.log("ressss", res);
        sucessToast("User approved successfully");
        navigate("/anchor-pending-approval",{state:state?._id});
        updateState({ ...iState, anchorData: [] });
      });
    }
  };

  const changeStateFun = () => {
    updateState({ ...iState, stateChange: !stateChange });
  };

  const updateApproveUser = () => {
    if (anchorData.length === 0) {
      updateState({ ...iState, anchorDataError: "Anchors can't be empty" });
    } else {
      let data = {
        status: "APPROVED",
        anchorData,
        userId: UserDetailsData?._id,
      };
      console.log("dataaa", data);
      dispatch(updateAccessAction(data)).then((res) => {
        console.log("ressss", res);
        sucessToast("User approved successfully");
        navigate("/anchor-details", {state:state?.anchorId});
        updateState({ ...iState, anchorData: [] });
      });
    }
  };



  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="TitleBox">
              <h4 className="Title">
                {state?.state === "anchorDetails" ? (
                  <Link
                    to="/anchor-details"
                    state={state?.anchorId}
                    className="backArrow"
                  >
                    <i className="fa fa-arrow-left" aria-hidden="true" />
                  </Link>
                ) : (
                  <Link
                    to="/anchor-pending-approval"
                    state={state?._id}
                    className="backArrow"
                  >
                    <i className="fa fa-arrow-left" aria-hidden="true" />
                  </Link>
                )}
                Provide Access
              </h4>
            </div>
            <div className="BusinessPersonal BusinessPersonal2">
              <figcaption>
                <p>
                  <strong>User ID : </strong> {UserDetailsData?.user_number}
                </p>
                <p>
                  <strong>User Name : </strong> {UserDetailsData?.name}
                </p>
                <p>
                  <strong>Contact Number : </strong>{" "}
                  {UserDetailsData?.phoneNumber}
                </p>
                <p>
                  <strong>Email Address : </strong> {UserDetailsData?.email}{" "}
                </p>
                <p>
                  <strong>Date of Request : </strong>{" "}
                  {UserDetailsData?.createdAt?.split("T")[0]}{" "}
                </p>
              </figcaption>
              <figure>
                <img src={UserDetailsData?.empIdPic} alt="" />
                <p className="idCard">Id Card</p>
              </figure>
            </div>
            <div className="CommonForm">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <div className="anchorCompany">
                      <div className="d-flex justify-content-between" style={{"width":"400px"}}>
                        <h3>Select Anchor Company </h3>
                        {state?.state === "anchorDetails" && stateChange ? <> <a
                            class="Green"
                            onClick={() => changeStateFun(!stateChange)}
                          >
                            <i class="fa fa-pencil"></i>
                          </a></> : <></>} 
                        {/* <a href="javascript:void(0);" className="Green">
                          <i
                            className="fa fa-pencil"
                            aria-hidden="true"
                          />
                        </a> */}
                      </div>
                      {state?.state === "anchorDetails" ? (
                        <>
                         
                          <div className="anchorPergraph">
                            {stateChange ? (
                              <>
                                <ul>
                                  {checkArray(anchorDetailsData) &&
                                    anchorDetailsData?.map((ele, i) => {
                                      return (
                                        <>
                                          <li>
                                            {ele?.anchorData?.[0]?.companyName}
                                          </li>
                                        </>
                                      );
                                    })}
                                </ul>
                              </>
                            ) : (
                              <>
                                {" "}
                                <Multiselect
                                  // className="form-control"
                                  options={customerOption.options}
                                  // selectedValues={streamOption.selectedValue}
                                  displayValue="companyName"
                                  onSelect={onSelectCustomer}
                                  onRemove={onRemoveCustomer}
                                  selectedValues={preSelectedData}
                                  // disable={!stream}
                                  //   ref={dropDownRefsubStream
                                  // resetSelectedValues={handleRefresh}
                                />
                                <span>
                                  <i
                                    className="fa fa-chevron-down"
                                    aria-hidden="true"
                                  />
                                </span>
                              </>
                            )}
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {anchorDataError}
                          </span>

                          <div className=""></div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="anchorPergraph">
                            <Multiselect
                              // className="form-control"
                              options={customerOption.options}
                              // selectedValues={streamOption.selectedValue}
                              displayValue="companyName"
                              onSelect={onSelectCustomer}
                              onRemove={onRemoveCustomer}
                              selectedValues={preSelectedData}
                              // disable={!stream}
                              //   ref={dropDownRefsubStream
                              // resetSelectedValues={handleRefresh}
                            />
                            <span>
                              <i
                                className="fa fa-chevron-down"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            {state?.state === "anchorDetails" ? (
              !stateChange ? <>
                <div className="newBtn">
                <a onClick={updateApproveUser} className="Button">
                  Approve
                </a>
              </div>
              </> : <></> 
            
            ) : (
              <div className="newBtn">
                <a onClick={approveUserFunc} className="Button">
                  Approve
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Anchorpendingaccess;
