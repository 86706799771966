import React from "react";
import Lottie from "lottie-web";
import animationData from "../assets/animation/noDataFound.json";
import ReactLottie from "react-lottie";

const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  
  function NoDataFound({ setModalName }) {
    return (
      <div >
          <ReactLottie
  
        options={animationOptions}
        width={"40%"}
        height={"40%"}
        className="bg-red-400"
      />
      </div>
        
       
    );
  }
  
  export default NoDataFound;