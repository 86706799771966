import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { update } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { checkArray, checkEmail, checkGst, checkPan, checkPhone } from '../utils/CheckType';
import { useDispatch, useSelector } from 'react-redux';
import S3FileUpload from 'react-s3/lib/ReactS3';
import { errorToast, sucessToast } from '../utils/toast';
import { cityListAction, stateListAction } from '../redux/actions/authAction';
import { addAnchorAction } from '../redux/actions/anchorAction';
import { bucketConfig } from '../utils/uploadFile';
// import PDF from "../assets/images/PDF.png"
 const init = {
  companyName:"",
  natureOfBuisness: "",
  cnnNumber: "",
  companyAddress: "",
  state: "",
  city: "",
  pinCode: "",
  contactPersonName: "",
  contactPersonNumber: "",
  contactPersonEmail: "",
  designation: "",
  gstNumber: "",
  gstFront: "",
  gstBack: "",
  panNumber: "",
  panCertificate: "" ,
  errors:{}
}
function Anchoradd() {
  const [iState,updateState]= useState(init);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {stateListData, cityListData} = useSelector((state ) => state.mainReducer)
console.log("stateeeee", cityListData)
  const {
    companyName,
    natureOfBuisness,
    cnnNumber,
    companyAddress,
    state,
    city,
    pinCode,
    contactPersonName,
    contactPersonNumber,
    contactPersonEmail,
    designation,
    gstNumber,
    gstFront,
    gstBack,
    panNumber,
    panCertificate,
    errors
  } = iState
  const handleInput = (e) => {
    const{name,value} = e.target;
    updateState({...iState, [name]:value})
  }

  useEffect(() => {
dispatch(stateListAction())
  },[])

  useEffect(() => {
    dispatch(cityListAction(state))
      },[state])

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };


  window.Buffer = window.Buffer || require("buffer").Buffer;

  const onFileHandler = (e) => {
    const{name,value} = e.target
    const file = e.target.files[0];
    console.log("fileee", file.size);
    console.log("maxxxxsizeeeee",1024 * 1024 * 2)

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf"
      ) {
        
        S3FileUpload.uploadFile(file, bucketConfig)
          .then((data) => {
            if(file.size < 1024 * 1024 * 2){
              console.log("datatdfdfdfsd", file.size);
              updateState({ ...iState, [name]: data.location });
            } else{
              console.log("datajkhsdjhgfs")
              errorToast("Please upload the file less than 2MB");
            }
           
          }).catch((err) => console.error(err));
      } else {
       errorToast("Only jpeg, jpg, png and pdf are allowed");
      }
    }
  };

  console.log("=============>", gstFront)
  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
    if(!companyName){
      error.companyNameError = "Company name can't be empty";
      formIsValid = false
    }

    if(!natureOfBuisness){
      error.natureOfBuisnessError = "Nature of bussiness can't be empty";
      formIsValid = false
    }
    // /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if(!cnnNumber){
      error.cnnNumberError = "Cnn number can't be empty";
      formIsValid = false
    }

    if(!companyAddress){
      error.companyAddressError = "Company address can't be empty";
      formIsValid = false
    }

    if(!city){
      error.cityError = "City can't be empty";
      formIsValid = false
    }

    if(!state){
      error.stateError = "State can't be empty";
      formIsValid = false
    }

    if(!pinCode){
      error.pinCodeError = "Pincode can't be empty";
      formIsValid = false
    }
    if(pinCode){
      if(!(pinCode.length ===6)){
        error.pinCodeError = "Please Enter a valid pincode"
        formIsValid=false
      }
     

    }

    if(!contactPersonEmail){
      error.contactPersonEmailError = "Email can't be empty";
      formIsValid = false
    }
    if(contactPersonEmail){
      if(!checkEmail(contactPersonEmail)){
        error.contactPersonEmailError = "Please enter a valid email";
        formIsValid  = false;
      }
    }

    if(!contactPersonName){
      error.contactPersonNameError = "Name can't be empty";
      formIsValid = false
    }

    if(!contactPersonNumber){
      error.contactPersonNumberError = "Phone number can't be empty";
      formIsValid = false
    }
    if(contactPersonNumber){
      if(!checkPhone(contactPersonNumber)){
        error.contactPersonNumberError = "Please enter a valid number"
        formIsValid=false
      }
    }

    if(!designation){
      error.designationError = "Designation can't be empty";
      formIsValid = false
    }

    if(!gstNumber){
      error.gstNumberError = "GST can't be empty";
      formIsValid = false
    }
    if(gstNumber){
      if(gstNumber.length != 15 ){
        error.gstNumberError = "Please enter a valid GST number"
        formIsValid=false
      }
    }
    if(!gstBack){
      error.gstBackError = "GST back can't be empty";
      formIsValid = false
    }
    if(!gstFront){
      error.gstFrontError = "GST front can't be empty";
      formIsValid = false
    }

    if(!panNumber){
      error.panNumberError = "Pan number can't be empty";
      formIsValid = false
    }
    if(panNumber){
      if(panNumber.length !== 10){
        error.panNumberError = "Please enter a valid pan number"
        formIsValid=false
      }
    }
    
    if(!panCertificate){
      error.panCertificateError = "Pan certificate can't be empty";
      formIsValid = false
    }

    updateState({...iState, errors:error})
    return formIsValid
  }

  const addAnchorFunc = (e) => {
    e.preventDefault()
    let formIsValid = handleValidation();
    if(formIsValid){
      let data = {
        companyName,
      natureOfBuisness,
      cnnNumber,
      companyAddress,
      state,
      city,
      pinCode,
      contactPersonName,
      contactPersonNumber,
      contactPersonEmail,
      designation,
      gstNumber,
      gstFront,
      gstBack,
      panNumber,
      panCertificate,
      }
      console.log('dataaaaaaa,',data)
      dispatch(addAnchorAction(data)).then((res) => {
        console.log("resss", res)
        updateState(init)
        sucessToast("Anchor added successfully")
        navigate("/anchor-management")
      
      }).catch((err) => {
        console.log("errrrr",err)
      })
  
    }
  
  }
  
  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="TitleBox">
      <h4 className="Title">
        <Link to="/anchor-management" className="backArrow">
          <i className="fa fa-arrow-left" aria-hidden="true" />
        </Link>
        Add Anchor
      </h4>
    </div>
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">Company Details</h4>
      </div>
      <div className="AnchorArea">
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Enter Company Name</label>
              <input
                type="text"
                placeholder="ABC Company"
                className="form-control"
                name='companyName'
                value={companyName}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.companyNameError}</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Nature of Business</label>
              <select className="form-control" name='natureOfBuisness' value={natureOfBuisness} onChange={handleInput}>
                <option value="">Select option</option>
                <option value="Manufacturing">Manufacturing </option>
                <option value="Trading">Trading </option>
                <option value="Services">Services</option>
              </select>
              <span style={{color:"red", fontSize:"14px"}}>{errors?.natureOfBuisnessError}</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Enter CIN Number</label>
              <input
                type="text"
                placeholder="Enter CIN Number"
                className="form-control"
                name='cnnNumber'
                value={cnnNumber}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.cnnNumberError}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="AnchorArea">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Company Address</label>
              <input
                type="text"
                placeholder="Full Address"
                className="form-control"
                name='companyAddress'
                value={companyAddress}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.companyAddressError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Select State</label>
              <select className="form-control" name='state' value={state} onChange={handleInput}>
              <option>Select State </option>
                 {checkArray(stateListData?.states) && stateListData?.states?.map((ele,i) => {
                  return (
                    <option value={ele?.isoCode}>{ele?.name} </option>
                  )
                 })}
              </select>
              <span style={{color:"red", fontSize:"14px"}}>{errors?.stateError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Select City</label>
              <select className="form-control" name='city' value={city} onChange={handleInput}>
              <option>Select City </option>
                 {checkArray(cityListData?.cities) && cityListData?.cities?.map((ele,i) => {
                  return (
                    <option value={ele?.isoCode}>{ele?.name} </option>
                  )
                 })}
              </select>
              <span style={{color:"red", fontSize:"14px"}}>{errors?.cityError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Pincode</label>
              <input
                type="text"
                placeholder="Enter Pincode"
                className="form-control"
                name='pinCode'
                value={pinCode}
                maxLength={6}
                onChange={handleInputNumber}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.pinCodeError}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">Contact Person Details</h4>
      </div>
      <div className="AnchorArea">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Contact Person Full Name </label>
              <input
                type="text"
                placeholder="Enter Full Name"
                className="form-control"
                name='contactPersonName'
                value={contactPersonName}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.contactPersonNameError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Designation</label>
              <input
                type="text"
                placeholder="Enter Designation"
                className="form-control"
                name='designation'
                value={designation}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.designationError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Contact Number </label>
              <input
                type="text"
                placeholder="Enter Contact Number"
                className="form-control"
                name='contactPersonNumber'
                value={contactPersonNumber}
                maxLength={10}
                minLength={10}
                onChange={handleInputNumber}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.contactPersonNumberError}</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Enter Email Address</label>
              <input
                type="text"
                placeholder="Enter Email"
                className="form-control"
                name='contactPersonEmail'
                value={contactPersonEmail}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.contactPersonEmailError}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="Small-Wrapper">
      <div className="AnchorArea">
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Enter GSTIN Number</label>
              <input
                type="text"
                className="form-control"
                placeholder='GST Number'
                name='gstNumber'
                value={gstNumber}
                maxLength={15}
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.gstNumberError}</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Upload front </label>
              <div className="UploadBox">
                <div className="Upload">
                {/* {gstFront ?
                gstFront.split(".")[gstFront.split(".").length -1] == "pdf" ? <>
                     <img src={PDF}/>
                </>  : <> <img src={gstFront}/></> ?
                 
                   :<> <i className="fa fa-upload"  /> <span>Upload</span></> }
                  <input type="file" name="gstFront" onChange={onFileHandler} /> */}

                  {gstFront ? 
                    gstFront.split(".")[gstFront.split(".").length -1] == "pdf" ? <>
                  
                    <a href={gstFront} target='_blank'>  <img src="images/PDF.png"/></a>
                    </>: <><img src={gstFront}/></>
                   : <>
                  <i className="fa fa-upload"  /> <span>Please upload the size less than 2mb</span>
                  
                  </>}
                  <input type="file" name="gstFront" onChange={onFileHandler} /> 
                </div>
                
              </div>
              <span style={{color:"red", fontSize:"14px"}}>{errors?.gstFrontError}</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Upload Back </label>
              <div className="UploadBox">
                <div className="Upload">
                {gstBack ? 
                    gstBack.split(".")[gstBack.split(".").length -1] == "pdf" ? <>
                  
                    <a href={gstBack} target='_blank'>  <img src="images/PDF.png"/></a>
                    </>: <><img src={gstBack}/></>
                   : <>
                  <i className="fa fa-upload"  /> <span>Please upload the size less than 2mb</span>
                  
                  </>}
                  <input type="file"name="gstBack" onChange={onFileHandler} />
                </div>
              
              </div>
              <span style={{color:"red", fontSize:"14px"}}>{errors?.gstBackError}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Enter PAN Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter  PAN Number"
                name='panNumber'
                value={panNumber}
                maxLength={10}
                
                onChange={handleInput}
              />
              <span style={{color:"red", fontSize:"14px"}}>{errors?.panNumberError}</span>
            </div>
          </div>
         
          <div className="col-sm-4">
            <div className="form-group">
              <label>Upload </label>
              <div className="UploadBox">
                <div className="Upload">
                {panCertificate ? 
                    panCertificate.split(".")[panCertificate.split(".").length -1] == "pdf" ? <>
                  
                    <a href={panCertificate} target='_blank'>  <img src="images/PDF.png"/></a>
                    </>: <><img src={panCertificate}/></>
                   : <>
                  <i className="fa fa-upload"  /><span>Please upload the size less than 2mb</span>
                  
                  </>}
                 
                  <input type="file" name='panCertificate' onChange={onFileHandler} />

                </div>
              </div>
              <span style={{color:"red", fontSize:"14px"}}>{errors?.panCertificateError}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <button class="Button newBtn">Add Anchor</button> */}
    <a onClick={addAnchorFunc} className="Button newBtn">
      Add Anchor
    </a>
  </div>
</div>

   </>
  )
}

export default Anchoradd