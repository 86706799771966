import React from 'react'
import { Link } from 'react-router-dom'

function Loginconfirm() {
  return (
    <>
    <div className="SuccessArea">
  <div className="SuccessBox">
    <span>
      <img src="images/Success.png" />
    </span>
    <h3>Congratulations!</h3>
    <p>Your password has been changed successfully!</p>
    <Link to="/">Continue To Login</Link>
  </div>
</div>

    </>
  )
}

export default Loginconfirm