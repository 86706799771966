import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userApprovedAction, userListAction } from '../redux/actions/anchorAction';
import Loader from './Loader';
import NoDataFound from './NoDataFound';
import { checkArray } from '../utils/CheckType';
import Pagination from 'react-js-pagination';
import { sucessToast } from '../utils/toast';
const init = {

}
function Anchorpendingapproval () {
  const[iState, updateState] = useState(init);
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  const dispatch = useDispatch();
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const navigate = useNavigate();
  const {userListData, loader} = useSelector((state ) => state.mainReducer)
  console.log("userListData,",userListData);
  useEffect(( ) => {
     dispatch(userListAction())
  },[])


  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    let payloadData = {
      page:pageNumber
    };
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    dispatch(userListAction(payloadData))
  }

  const filterSearch = (e) => {
    updateState({...iState, search:e.target.value})
    dispatch(userListAction(e.target.value))
  }


  const updateApproveUser = (id) => {   
      let data = {
        status: "REJECT",
        userId: id
      };
      console.log("dataaa", data);
      dispatch(userApprovedAction(data)).then((res) => {
        console.log("ressss", res);
        sucessToast("User rejected successfully");
        // navigate("/anchor-details", {state:state?.anchorId});
        updateState({ ...iState, anchorData: [] });
      });
   
  };

  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">
          <Link to="/anchor-management" className="backArrow">
            <i className="fa fa-arrow-left" aria-hidden="true" />
          </Link>{" "}
          Users Pending for approval
        </h4>
      </div>
      <div className="Filter">
        <div className="form-group">
          <label>Search</label>
          <input type="text" className="form-control" placeholder="Search" onChange={(e) => filterSearch(e)} />
        </div>
      </div>
      <div className="TableList">
        <table style={{ width: "130%" }}>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>User ID</th>
              <th>Company Name</th>
              <th>User Name</th>
              <th>Contact Number</th>
              <th>Designation</th>
              <th>Email Address</th>
              <th>Registraion Date</th>
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Anchor-Management" &&
                      ele.fullAccess == true
                  ) && (
                     <>
                      <th>Anchor Access</th>
              <th>Action</th>
                     </>
                  )}
                </>
              ) : (
                <> 
                     <th>Anchor Access</th>
              <th>Action</th>
                </>
              )}
             
            </tr>
          </thead>
          <tbody>
          {loader ? <>
            <Loader/>
            </>
            :
            checkArray(userListData?.result?.[0]?.paginationData) ? 
            userListData?.result?.[0]?.paginationData?.map((ele,i) => {           
              return(
                <>
  <tr>
              <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.user_number}</td>
              <td>{ele?.company}</td>
              <td>{ele?.name}</td>
              <td>{ele?.phoneNumber}</td>
              <td>{ele?.designation}</td>
              <td>{ele?.email}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              
       {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Anchor-Management" &&
                      ele.fullAccess == true
                  ) && (
                      <td>
              <Link
                    to="/anchor-pending-view"
                    className="view"
                    state={ele?._id}
                  >
                    View
                  </Link>
              </td>
                  )}
                </>
              ) : (
                <> 
                      <td>
              <Link
                    to="/anchor-pending-view"
                    className="view"
                    state={ele?._id}
                  >
                    View
                  </Link>
              </td>
                </>
              )}



{adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Anchor-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <td>
                    <aside>
                      <Link
                        to="/anchor-pending-access"
                        state={ele?._id}
                        className="Green1"
                      >
                        Approve
                      </Link>
                      <a onClick={() => updateApproveUser(ele?._id)} className="Red1">
                        Reject
                      </a>
                    </aside>
                  </td>
                  )}
                </>
              ) : (
                <> 
                        <td>
                <aside>
                  <Link
                    to="/anchor-pending-access"
                    state={ele?._id}
                    className="Green1"
                  >
                    Approve
                  </Link>
                  <a onClick={() => updateApproveUser(ele?._id)} className="Red1">
                    Reject
                  </a>
                </aside>
              </td>
                </>
              )}
           
        
            </tr>
                </>
              )
            })
            : <p>No Data Found</p>
            }
          
          
          </tbody>
        </table>
      </div>
      <div class="pagination">
              <p>
                {" "}
                Total records : <span>{userListData?.result?.[0]?.totalCount?.[0]?.count}</span>
              </p>
              <ul>
                {userListData?.result?.[0]?.totalCount?.[0]?.count && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={userListData?.result?.[0]?.totalCount?.[0]?.count}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </ul>
            </div>
    </div>
  </div>
</div>

   </>
  )
}

export default Anchorpendingapproval