import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { changeRatingStatusAction, unapprovedListAction } from '../redux/actions/clientAction';
import Loader from './Loader';
import { checkArray } from '../utils/CheckType';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { sucessToast } from '../utils/toast';
const init = {
  search:"",startDate:"",endDate:"", timeFrame:"", 
  approveModal:false,unapproveModal:false, modalId:"",
  modalName:"",
}
function ClientScorePending() {
  const[iState, updateState] = useState(init);
  const{search, startDate, endDate, timeFrame,approveModal,unapproveModal, modalId,modalName} = iState;
  const dispatch = useDispatch();
  const{clientPendingId} = useParams();
  console.log("clientPendingId",clientPendingId);

  const navigate = useNavigate();
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const {unapprovedListData, loader} = useSelector((state ) => state.mainReducer)
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  useEffect(() => {
    dispatch(unapprovedListAction())
  },[])


  const handleInput = (e) => {
    const{name,value} = e.target;
    updateState({...iState, [name]:value})
  }

  const handleRefresh = () => {
    updateState(init)
    dispatch(unapprovedListAction({page:1}))
  }
  const applyFilter = () => {
    dispatch(unapprovedListAction({search,startDate, endDate, timeFrame} ))
  }
  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    dispatch(unapprovedListAction({search, startDate, endDate, timeFrame,page:pageNumber}))
  }


  const showApproveModal = (id, name) => {
    updateState({...iState, approveModal:true, modalId:id, modalName:name})
  }


  const hideApproveModal = () => {
    updateState({...iState, approveModal:false, modalId:"", modalName:""})
  }

  const showRejectModal = (id, name) => {
    updateState({...iState, unapproveModal:true, modalId:id, modalName:name})
  }


  const hideRejectModal = () => {
    updateState({...iState, unapproveModal:false, modalId:"", modalName:""})
  }

  const handleStatus = (approvedStatus) => {
    let data = {
      id: modalId,approvedStatus

    }
    dispatch(changeRatingStatusAction(data)).then((res) => {
      console.log("ressssssss",res)
      if(approvedStatus == "APPROVED"){
        hideApproveModal();
      } else {
        hideRejectModal()
      }
     
     
      sucessToast("Rating approved successfully")
      dispatch(unapprovedListAction())
    })
  }
  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">Scoring Pending for apporval</h4>
      </div>
      <div className="Filter">
        <div className="form-group">
          <label>Search</label>
          <input type="text" className="form-control" placeholder="Search" name='search' value={search} onChange={handleInput}/>
        </div>
        <div className="form-group">
          <label>From</label>
          <input type="date" className="form-control" name='startDate' value={startDate} max={moment().format("YYYY-MM-DD")} onChange={handleInput} />
        </div>
        <div className="form-group">
          <label>To</label>
          <input type="date" className="form-control" name='endDate' value={endDate}  min={moment(startDate).format("YYYY-MM-DD")} onChange={handleInput}/>
        </div>
        <div className="form-group">
          <label>Select From</label>
          <select className="form-control" name='timeFrame' value={timeFrame} onChange={handleInput}>
          <option value="">Select</option>
            <option value="Today">Today</option>
            <option value="Week">Last Week</option>
            <option value="Month">Last Month</option>
          </select>
        </div>
        <div className="form-group">
          <label>&nbsp;</label>
          <button onClick={applyFilter} className="Button">Apply</button>
          <button onClick={handleRefresh} className="Button Cancel">
            <i className="fa fa-refresh" />
          </button>
        </div>
      </div>
      <div className="TableList">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>Dealer ID</th>
              <th>Dealer Name</th>
              <th>Dealer Address</th>
              <th>Parent Anchor</th>
              <th>Scored By</th>
              <th>Scored Date</th>
             
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Client-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <th>View Score</th>
                  )}
                </>
              ) : (
                <> 
                 <th>View Score</th>
                </>
              )}

{adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Client-Management" &&
                      ele.approve_disapprove == true
                  ) && (
                    <th>Action</th>
                  )}
                </>
              ) : (
                <> 
                    <th>Action</th>
                </>
              )}
           
            </tr>
          </thead>
          <tbody>


          {loader ? <>
            <Loader/>
            </>
            :

            checkArray(unapprovedListData?.result?.[0]?.paginationData) ? 
            unapprovedListData?.result?.[0]?.paginationData?.map((ele,i) => {      
              return(
                <>
      <tr>
      <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.dealer_number}</td>
              <td>{ele?.dealerName}</td>
              <td>{ele?.basicDetails?.dealerAddress}</td>
              <td>{ele?.anchorData?.[0]?.companyName}</td>
              <td>{ele?.scoreById}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Client-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <td>
                    <Link to="/client-scoring-details"  state={ele}>
                      <img src="images/eye.png" alt="" />
                    </Link>
                  </td>
                  )}
                </>
              ) : (
                <> 
                 <td>
                <Link to="/client-scoring-details"  state={ele}>
                  <img src="images/eye.png" alt="" />
                </Link>
              </td>
                </>
              )}
             

             {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Client-Management" &&
                      ele.approve_disapprove == true
                  ) && (
                    <td>
                    <aside>
                      <span
                        className="Green"
                         onClick={() => showApproveModal(ele?._id, ele?.dealerName)}
                      >
                        Approve
                      </span> &nbsp;  &nbsp;
                      <span
                        className="Red"
                        onClick={() => showRejectModal(ele?._id)}
                      >
                        Reject
                      </span>
                     
                    </aside>
                  </td>
                  )}
                </>
              ) : (
                <> 
                   <td>
                <aside>
                  <span
                    className="Green"
                     onClick={() => showApproveModal(ele?._id, ele?.dealerName)}
                  >
                    Approve
                  </span> &nbsp;  &nbsp;
                  <span
                    className="Red"
                    onClick={() => showRejectModal(ele?._id, ele?.dealerName)}
                  >
                    Reject
                  </span>
                  {/* <span class="Red">Reject</span> */}
                </aside>
              </td>
                </>
              )}
           
            </tr>
                </>
              )
            })
            : <p>No data found</p>
            }
           
         
           
          </tbody>
        </table>
      </div>
      <div class="pagination">
              <p>
                {" "}
                {/* Total records : <span>{anchorListData?.result?.[0]?.totalCount?.[0]?.count}</span> */}
              </p>
              <ul>
                {unapprovedListData?.result?.[0]?.totalCount?.[0]?.count && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={unapprovedListData?.result?.[0]?.totalCount?.[0]?.count}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </ul>
            </div>
    </div>
  </div>
</div>


<Modal
        show={approveModal}
        onHide={hideApproveModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
          <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideApproveModal}>
    ×
  </a>
  <h3>Are you sure you want to submit approve ratings for {modalName}? </h3>
  <div className="newBtn">
    <button
      className="Button active"
       onClick={() =>handleStatus("APPROVED")}
    >
      Yes
    </button>   &nbsp;  &nbsp;
    <button className="Button" onClick={hideApproveModal}>
      No
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>


      
<Modal
        show={unapproveModal}
        onHide={hideRejectModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
          <div className="Category">
  <a href="javascript:void(0);" className="CloseModal"  onClick={hideRejectModal}>
    ×
  </a>
  <h3>Are you sure you want to submit reject ratings for {modalName} ? </h3>
  <div className="newBtn">
    <button
      className="Button active"
       onClick={() =>handleStatus("DISAPPROVED")}
    >
      yes
    </button>  &nbsp;  &nbsp;
    <button className="Button" onClick={hideRejectModal}>
      No
    </button>
  </div>
</div>


        </Modal.Body>
      </Modal>

   </>
  )
}

export default ClientScorePending