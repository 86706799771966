import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { staffDetailsAction } from "../redux/actions/staffAction";
import { update } from "lodash";
const init = {
  staffDetailsData: [],
  designation:"",
  name:"",
  email:"",
  phoneNumber:"",
  staff_number:"",
  createdAt:"",
};
const StaffManagementdetails = () => {
  const [iState, updateState] = useState(init);
  const{staffListData, loader} = useSelector((state ) => state.mainReducer)
 console.log('staffManagementAction', staffListData)
  const { staffDetailsData, designation,
    name,
    email,
    phoneNumber,
    staff_number,
    employeeId,
    createdAt, } = iState;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [modulePermissions, setModulePermissions] = useState([]);
  useEffect(() => {
    dispatch(staffDetailsAction(state)).then((res) => {
      console.log("ressssssss", res?.result?.[0]?.permission)
      const { designation, staff_number, email, phoneNumber, createdAt, name, employeeId } =
        res?.result?.[0];
      const updatedState = {
        designation,
        name,
        email,
        phoneNumber,
        staff_number,
        createdAt,
        employeeId
      };
      setModulePermissions(res?.result?.[0]?.permission)

      updateState(updatedState);
    });
    updatePermissionData()
  }, []);


 
  const modules = [
    { name: 'Dashboard'},
    { name: 'Anchor-Management' },
    { name: 'Staff-Management' },
    { name: 'Score-Management' },   
    { name: 'Client-View-Score' },
    { name: 'Start-Scoring' },
    { name: 'Score-Pending-Approval' },
    { name: 'Client-Management' },
    { name: 'Score-Management-Details'},
  ];



  const updatePermissionData = () => {
    // Assume apiData is the response from the API containing permissions data
    const apiData = modulePermissions 
    const updatedPermissions = modules.map(module => {
      const apiModuleData = apiData.find(apiModule => apiModule.name === module.name) || {};
      return {
        name: module.name,
        read: !!apiModuleData.read,
        fullAccess: !!apiModuleData.fullAccess,
        approve_disapprove: !!apiModuleData.approve_disapprove,
      };
    });
  
    setModulePermissions(updatedPermissions);
  };
  

  const renderRows = () => {
    return modulePermissions && modulePermissions.map((module, index) => (
      <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <strong>{module.name}</strong>
      </td>
      <td>
        <div className="Read">
        <input
                type="checkbox"
                name={`read${index}`}
                checked={module.read}
                // onChange={() => handlePermissionChange(index, 'read', true)}
              />
          <span>
            <i className="fa fa-eye" />
          </span>
        </div>
      </td>
      <td>
        <div className="Access">
        <input
                type="checkbox"
                name={`fullAccess${index}`}
                checked={module.fullAccess}
                // onChange={() => handlePermissionChange(index, 'fullAccess', true)}
              />
          <span>
            <i className="fa fa-lock" />
          </span>
        </div>
      </td>
      <td>
      {module.name === "Client-Management" ? 
         <div className="Access">
         <input type="checkbox"        
          name={`approve_disapprove${index}`}
          checked={module.approve_disapprove}
          // onChange={(e) => handlePermissionChange(index, 'approve_disapprove', e.target.checked)}
          
          />
         <span>
           <i className="fa fa-lock" />
         </span>
       </div>
       :
       "-"
        }
        {/* <div className="Access">
          <input type="checkbox"        
           name={`approve_disapprove${index}`}
           checked={module.approve_disapprove}
          //  onChange={() => handlePermissionChange(index, 'approveAccess', true)}
           />
          <span>
            <i className="fa fa-lock" />
          </span>
        </div> */}
      </td>
    </tr>
 
    ));
  };

 
  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="TitleBox">
              <h4 className="Title">
                <Link to="/staff-management" className="backArrow">
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link>{" "}
                Staff Details
              </h4>
            </div>
          </div>
          <div className="InformationBox">
            <h4>Basic Details</h4>
            <div className="Information">
              <article>
                <aside>
                  <p>
                    <strong>Staff ID</strong> <span>{staff_number}</span>
                  </p>
                  <p>
                    <strong>Staff Full Name</strong>{" "}
                    <span> {name} </span>
                  </p>
                  <p>
                    <strong>Contact Number</strong> <span>{phoneNumber}</span>
                  </p>
                  <p>
                    <strong>Email Address</strong> <span>{email}</span>
                  </p>

                  <p>
                    <strong>Employee Id</strong> <span>{employeeId}</span>
                  </p>
                </aside>
                <aside>
                  <p>
                    <strong>Designation</strong> <span> {designation}</span>
                  </p>
                  <p>
                    <strong>Date Registered on</strong> <span> {createdAt?.split("T")[0]}</span>
                  </p>
                  <p>
                    <strong>Last Active on</strong> <span>  {createdAt?.split("T")[0]}</span>
                  </p>

                </aside>
              </article>
            </div>
          </div>
          <div className="InformationBox">
            <div className="AdminAccess">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr. N.</th>
                    <th>Module</th>
                    <th width="150px">Read</th>
                    <th width="150px">Manage</th>
                    <th width="150px">Approve/Disapprove</th>
                  </tr>
                </thead>
                
                <tbody>{renderRows()}</tbody>
                
              </table>
            </div>
          </div>
          {/* <div className="InformationBox">
            <h4>Activities</h4>
            <div className="Activities">
              <ol>
                <li>
                  <p>
                    1. Approved DCG Technologies, Dealer ID on{" "}
                    <strong>12/03/2023. 20:30 PM</strong> scored by Mr. Kamal
                    Kumar on 11/03/2023,20:30 PM
                  </p>
                </li>
                <li>
                  <p>
                    2. Provided rating to DCG Technologies, Dealer ID on{" "}
                    <strong>12/03/2023. 20:30 PM</strong> .
                  </p>
                </li>
                <li>
                  <p>
                    3. Provided rating to DCG Technologies, Dealer ID on{" "}
                    <strong>12/03/2023. 20:30 PM</strong> .
                  </p>
                </li>
                <li>
                  <p>
                    4. Provided rating to DCG Technologies, Dealer ID on{" "}
                    <strong>12/03/2023. 20:30 PM</strong> .
                  </p>
                </li>
              </ol>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default StaffManagementdetails;
