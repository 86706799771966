export function isLoggedIn(userType) {
    let session = getObject("sgfinserv") || {};
   
    session = Object.keys(session).length && JSON.parse(session);
  
    let accessToken = (session && session["jwtToken"]) || "";
    return accessToken;
  }
  export function getObject(key) {
    if (window && window.localStorage) {
      return (localStorage.getItem(key));
    }
  }