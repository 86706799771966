import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorToast, sucessToast } from '../utils/toast';
import { forgetPassword } from '../redux/actions/authAction';
import { checkPassword } from '../utils/CheckType';
const initialState = {
  email: "admin@gmail.com",
  newPassword: "",
  confirmPassword: "",
  errors: {},
};
function Changepassword() {
  const [iState, updateState] = useState(initialState);
  const { email, newPassword, confirmPassword, errors } = iState;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [showNewPassword, setshowNewPassword] = useState(true);
  const [showConfirmPassword, setshowConfirmPassword] = useState(true);
  const adminData =  JSON.parse(localStorage.getItem("sgEmail"))
  const adminEmail = adminData.email;

  useEffect(() => {
    updateState({...iState, email:adminData})
  },[])

  console.log(email)
  const passwordVisibility = () => {
    if (showNewPassword) {
      setshowNewPassword(false);
    } else {
      setshowNewPassword(true);
    }
  };

  const confirmPasswordVisibility = () => {
    if (showConfirmPassword) {
      setshowConfirmPassword(false);
    } else {
      setshowConfirmPassword(true);
    }
  };
  console.log(checkPassword(newPassword))

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!newPassword.trim("")) {
      error.newPasswordError = "Password Can't Be Empty";
      formIsValid = false;
    }

     
       if(newPassword && confirmPassword){
        if(!checkPassword(newPassword)){
          error.newPasswordError = "Password length must be of 8 character including the numeric , alphabet and special character"
          formIsValid = false
        }
       }

    if (!confirmPassword.trim("")) {
      error.confirmPasswordError = "Confirm Password Can't Be Empty";
      formIsValid = false;
    }

    if(newPassword &&confirmPassword){
      if(!checkPassword(confirmPassword)){
        error.confirmPasswordError = "Password length must be of 8 character including the numeric , alphabet and special character";
        formIsValid = false
      }
     }
    if (newPassword && confirmPassword &&  newPassword !== confirmPassword) {
      error.confirmPasswordError = "Password and confirm Password are not same";
      // errorToast("Password and confirm Password are not Same")
      formIsValid = false;
    }
    updateState({ ...iState, errors: error });
    return formIsValid;
  };

  const handlePassword = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleForget = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      dispatch(forgetPassword({ email : adminData, password:newPassword }))
        .then((res) => {
          if (res.code === 200) {
            sucessToast(res.message);
            navigate("/login-confirm");
          }
        })
        .catch((err) => {
         errorToast(err.message);
          console.log(err);
        });
    }
  };
  return (
    <div className="LoginArea">
    <div className="LoginBox">
      <div className="LoginLeft">
        <h2>
          SG <span>Finserve</span>
        </h2>
        <h3>
          <span>Change password</span>
        </h3>
        <form>
          <div className="form-group">
            <label>Create New Password</label>
            <input
               type={showNewPassword ? "password" : "text"}
             
              className="form-control"
             
              name="newPassword"
              class="form-control"
              placeholder="New Password"
              onChange={handlePassword}


            />
             {showNewPassword ? 
           <span onClick={passwordVisibility} className="Icon">
             <i className="fa fa-unlock-alt" />
         </span>
         :
         <span onClick={passwordVisibility} className="Icon">
        <i className="fa fa-eye"></i>
       </span>
      
        }
         <span style={{color:"red", fontSize:"14px" }}>{errors?.newPasswordError}</span>
        
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type={showConfirmPassword ? "password" : "text"}
              name="confirmPassword"
              class="form-control"
              placeholder="Confirm Password"
              onChange={handlePassword}
            />
            {showConfirmPassword ? 
           <span onClick={confirmPasswordVisibility} className="Icon">
             <i className="fa fa-unlock-alt" />
         </span>
         :
         <span onClick={confirmPasswordVisibility} className="Icon">
        <i className="fa fa-eye"></i>
       </span>
        }
          <span style={{color:"red", fontSize:"14px" }}>{errors?.confirmPasswordError}</span>
          </div>
          <a className="Button" onClick={handleForget}>
            change password
          </a>
          {/* <button>Send OTP</button> */}
        </form>
      </div>
      <div className="LoginRight">
        <figure>
          <img src="images/logo.png" />
        </figure>
        <h5>
          Let's Started <span>SG Finserve</span>
        </h5>
        {/* <h6>To keep connected wit us please login .</h6> */}
      </div>
    </div>
  </div>
  
  )
}

export default Changepassword