import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNextKeyDef } from "@testing-library/user-event/dist/keyboard/getNextKeyDef";
import { getNodeText } from "@testing-library/react";
import { saveAs } from 'file-saver';
import {
  addNonFinanceRatingAction,
  clientDetailsAction,
  downloadReportAction,
  getNonFinanceAction,
} from "../redux/actions/clientAction";
import { NonFinancialRiskAction } from "../redux/actions/scoreAction";
import NoDataFound from "./NoDataFound";
import Loader from "./Loader";
import { checkArray } from "../utils/CheckType";
import { update, values } from "lodash";
import { sucessToast } from "../utils/toast";
import { Modal } from "react-bootstrap";
import { usePDF } from "react-to-pdf";
const init = {
  submitRatingModal: false,
  downloadModal:false,

};
function Clientscoringnonfinancial() {
  const [data, setData] = useState([]);
  const [iState, updateState] = useState(init);
  const { state } = useLocation();
  console.log("state",state);
  const { id } = useParams();
  const financeRatingData = JSON.parse(localStorage.getItem("financeratingData"))
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const { toPDF, targetRef } = usePDF({ filename: "NonFinancialRating.pdf" });
  const { rangeId, submitRatingModal,downloadModal} = iState;
  const[clientDetailsDataDownload, setClietDetailsDataDownload] = useState([])
  // const{baseYear, dealerName,anchorId,gstinNumber ,panNumber  } = state
    //  // // // console.log("Entering", baseYear, dealerName,anchorId,gstinNumber ,panNumber)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nonFinancialDetailsData, nonFinancialRiskListData, loader } =
    useSelector((state) => state.mainReducer);
  useEffect(() => {
    dispatch(getNonFinanceAction(state?.anchorId));
  }, []);

  // // // // console.log("setData--- ",data)

  useEffect(() => {
    if (nonFinancialDetailsData&&!data.length) {
      nonFinancialDetailsData?.result?.forEach((obj) => {
        const data=obj.anchorlevelnonfinancevalues?.[0];
        setData(prev=>([...prev,{range:data.range,score:data.score,valueId:data._id}]))
        obj.anchorlevelnonfinancevalues.forEach((item) => {
          // // // // console.log(item)
          // setData(prev=>
          //   {
              
          //     let jsonObject = prev.map(obj=>JSON.stringify(obj));
          //     let uniqueSet = new Set(jsonObject);
          //     const clientDataArray2 = Array.from(uniqueSet).map(JSON.parse);
          //     // // // console.log("databaseeee",clientDataArray2)    
          //     return ([...clientDataArray2,{ range:item.range, score:item.score, valueId:item._id}]);
          //   })
         
        });
      });
    }
  }, [nonFinancialDetailsData]);

  
  const showSubmitRatingModal = () => {
    updateState({ ...iState, submitRatingModal: true });
  };

  const hideSubmitRatingModal = () => {
    updateState({ ...iState, submitRatingModal: false });
  };


  
  const showDownloadRatingModal = () => {
    updateState({...iState, downloadModal:true });
  }

  const hideDownloadRatingModal = () => {
    updateState({...iState, downloadModal:false });
  }

 
  const handleInputChange = (e, item) => {
    const { name, value } = e.target;
    // let semesterData = [...data];
    updateState(prev=>({...prev,[name]:value}))

    const data=item?.anchorlevelnonfinancevalues.find(data=>data._id===value)
    const curParameter=nonFinancialDetailsData.result.find(item=>item.anchorlevelnonfinancevalues.find(data=>data._id===value));
    const filteredData=curParameter.anchorlevelnonfinancevalues.map(item=>item._id)
    // // // // console.log("hereee",filteredData)
    setData(prev=>{
      const newData=prev.filter(val=>!filteredData.includes(val.valueId))
      return ([...newData,{range:data.range,score:data.score,valueId:data._id}])})
  };

  const [isLoading,setLoading]=useState(false)

  const addNonFInacialDataFunc = () => {
   
    setLoading(true)
    let dataP = {
      nonFinanceRatingData: data,
      financeRatingData: financeRatingData,
      baseYear:
        state?.baseYear ??
        state?.state?.state?.baseYear ??
        state?.state?.state?.state?.baseYear,
      dealerName: state?.dealerName ?? state?.state?.state?.dealerName ?? state?.state?.state?.state?.dealerName,
      anchorId: state?.anchorId ?? state?.state?.state?.anchorId  ?? state?.state?.state?.state?.anchorId,
      // gstinNumber:
      //   state?.state?.gstinNumber ?? state?.state?.state?.gstinNumber  ?? state?.state?.state?.state?.gstinNumber,
      panNumber: state?.panNumber ?? state?.state?.state?.panNumber  ?? state?.state?.state?.state?.panNumber,
      expiryDate: state?.expiryDate ?? state?.state?.state?.expiryDate  ?? state?.state?.state?.state?.expiryDate,
      relationId: state?.relationId ?? state?.state?.state?.relationId  ?? state?.state?.state?.state?.relationId,
      keyPersonAddress :state?.address ?? state?.state?.state?.address  ?? state?.state?.state?.state?.address,
      keyPersonEmail :state?.emailAddress ?? state?.state?.state?.emailAddress  ?? state?.state?.state?.state?.emailAddress,
      keyPersonContact :state?.mobileNumber ?? state?.state?.state?.mobileNumber  ?? state?.state?.state?.state?.mobileNumber,
      aadharNumber:state?.aadharNumber ?? state?.state?.state?.aadharNumber  ?? state?.state?.state?.state?.aadharNumber,
      dateOfIncorporation:state?.dateOfIncorporation ?? state?.state?.state?.dateOfIncorporation  ?? state?.state?.state?.state?.dateOfIncorporation,

      // reportData: state?.state?.reportData ?? state?.state?.state?.reportData  ?? state?.state?.state?.state?.reportData,
    };
    // // // // console.log("dattaaaPPP", dataP);
     dispatch(addNonFinanceRatingAction(dataP)).then((res) => {
      // // // console.log("resssss",res)
      let id = res?.addsubAdmin?._id
       setData([])
         updateState({...iState, submitRatingModal:false, downloadModal:true})
       dispatch(clientDetailsAction(id,state?.anchorId)).then((res) => {
        // // // console.log("resssss",res)
        setClietDetailsDataDownload(res?.result?.[0])
        setLoading(false)
        // updateState({...iState, downloadModal:true})
       })
       adminData?.userType == "STAFF" ? 
         sucessToast("Your score has been added please wait for the approval from Admin")
        : 
         sucessToast("Rating added successfully")
      //  navigate("/client-management")

     })
  };
 

  const downloadRatingFunc = () => {
    let financeData = clientDetailsDataDownload?.financeratingsData?.map((ele,i) => {
      // // // console.log(ele)
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
        value: ele?.value
      }
    })
    
    let businessNonFinacePdfData = clientDetailsDataDownload?.nonfinanceratingsData?.map((ele,i) => {
              if(ele?.outsideTitle === "Business Risk parameters"){
                //  console.log("elelelelelelle",ele)
                return {
                  parameterName: ele?.parameterName,
                  range: ele?.range,
                  score: ele?.score,
                }
              } else{
                return null
              }
    })



    let accountNonFinacePdfData = clientDetailsDataDownload?.nonfinanceratingsData?.map((ele,i) => {
      // console.log("Elelelelelel",ele)
     if(ele?.outsideTitle === "Account Conduct Risk parameters"){
        console.log("elelelelelelle",ele)
       return {
         parameterName: ele?.parameterName,
         range: ele?.range,
         score: ele?.score,
       }
     } else{
       return null
     }
})



let managementNonFinacePdfData = clientDetailsDataDownload?.nonfinanceratingsData?.map((ele,i) => {
 
 if(ele?.outsideTitle === "Management Risk parameters"){
    // console.log("elelelelelelle",ele)
   return {
     parameterName: ele?.parameterName,
     range: ele?.range,
     score: ele?.score,
   }
 } else{
   return null
 }
})


    let anchorData = clientDetailsDataDownload?.anchorRatingData?.map((ele,i) => {
    
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
      }
    })
     
    let anchorRiskData =  clientDetailsDataDownload?.overallRatingAnchorRisk ? clientDetailsDataDownload?.overallRatingAnchorRisk.toFixed(2) : 0
        let financeRiskData = clientDetailsDataDownload?.overallRatingFinanceRisk ? clientDetailsDataDownload?.overallRatingFinanceRisk.toFixed(2) : 0
        let businessRiskData = clientDetailsDataDownload?.overallRatingBuisnessRisk ? clientDetailsDataDownload?.overallRatingBuisnessRisk.toFixed(2) : 0
        let managementRiskData = clientDetailsDataDownload?.overallRatingManagementRisk ? clientDetailsDataDownload?.overallRatingManagementRisk.toFixed(2) : 0
        let accountRiskData = clientDetailsDataDownload?.overallRatingAccountRisk ? clientDetailsDataDownload?.overallRatingAccountRisk.toFixed(2) : 0
        let overAllRiskData = clientDetailsDataDownload?.overallRating ? clientDetailsDataDownload?.overallRating.toFixed(2) : 0
        let categoryColor = clientDetailsDataDownload?.overallRating < 2 ? "Green" : clientDetailsDataDownload?.overallRating < 3 ? "Yellow" :  clientDetailsDataDownload?.overallRating < 4 ? "Orange":clientDetailsDataDownload?.overallRating < 5 ? "Red":""
     
        
        let data = {
          clientId:clientDetailsDataDownload?._id,
          financeData,
          businessNonFinacePdfData : businessNonFinacePdfData.filter(item => item !== null),
          accountNonFinacePdfData:accountNonFinacePdfData.filter(item => item !== null),
          managementNonFinacePdfData:managementNonFinacePdfData.filter(item => item !== null),

          // nonFinanceData,
          anchorData,
          anchorRiskData,
          financeRiskData,
          businessRiskData,
          managementRiskData,
          accountRiskData,
          overAllRiskData,
          categoryColor,
        }
        dispatch(downloadReportAction(data)).then((res) => {
          // // // console.log("resssssssss",res)
          window.open(res.link, "_blank");
          const blob = new Blob([res.link], { type: 'application/pdf' })
            saveAs(blob, "tickets.pdf")
          // href={
          //   studentHelpdetails?.askedRequest
          //     ?.tutorAttachDocument?.[0]?.link
          // }
          // target="_self"
        })
    
  }



 
  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">
              <Link to="/client-management" className="backArrow">
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </Link>{" "}
              Client Management
            </h4>
          </div>
          <div className="Small-Wrapper">
            <div className="CommonLinks">
              <ul>
                <li>
                  <Link state={state} to="/client-scoring-financial">
                    Financial Rating
                  </Link>
                </li>
                <li className="active">
                  <Link
                    state={state}
                    to="/client-scoring-non-financial" 
                  >
                    Non-Financial Rating
                  </Link>
                </li>
              </ul>
            </div>
            {loader ? (
              <>
                <Loader />
              </>
            ) : checkArray(nonFinancialDetailsData?.result) ? (
              nonFinancialDetailsData?.result
                ?.reduce((result, currentObj) => {
                  const group = result.find(
                    (group) => group.name === currentObj.outsideTitle
                  );
                  // If the group exists, add the current object to its "data" array
                  if (group) {
                    group.data.push(currentObj);
                  } else {
                    // If the group doesn't exist, create a new group with the current object
                    result.push({
                      name: currentObj.outsideTitle,
                      data: [currentObj],
                    });
                  }
                  return result;
                }, [])
                .map((ele, i) => {
                  // // // console.log("elelelelelelel",ele)
                  return (
                    <>
                      <div className="TopTittle">
                        <h4>{ele?.name}</h4>
                      </div>

                      <div className="TableList TableFinance">
                        <table  ref={targetRef} style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>Parameter</th>
                              <th>Range</th>
                              <th>Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkArray(ele?.data) &&
                              ele?.data?.map((item, i) => {
                                // // // console.log("selected item", item)
                                return (
                                  <>
                                    <tr>
                                      <td>{item?.title}</td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            name={item._id+"range"}
                                            // defaultValue={item?.range}
                                            value={iState[item._id+"range"]}
                                            onChange={(e) =>
                                              handleInputChange(e, item)
                                            }
                                          >
                                            {/* <option value="">Select</option> */}
                                            {checkArray(
                                              item?.anchorlevelnonfinancevalues
                                            ) &&
                                              item?.anchorlevelnonfinancevalues?.map(
                                                (ele, i) => {
                                                  return (
                                                    <option
                                                      
                                                      value={ele?._id}
                                                    >
                                                      {ele?.range}
                                                    </option>
                                                  );
                                                }
                                              )}
                                          </select>
                                          {/* <input type="text"  value={item?.nonFinanceRiskData?.[0]?.range} className="form-control" /> */}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name={item._id+"score"}
                                        
                                           value={item?.anchorlevelnonfinancevalues.find(data=>data._id===iState[item._id+'range'])?.score??item?.anchorlevelnonfinancevalues[0]?.score}
                                            onChange={(e) =>
                                             { 

                                              handleInputChange(e, item)
                                             }
                                            }
                                            className="form-control"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })
            ) : (
              <p>No Data Found</p>
            )}
          </div>
          <div className="newBtn">
            <a onClick={showSubmitRatingModal} className="Button">
              Submit non financial rating
            </a>
          </div>
        </div>
      </div>

      <Modal
        show={submitRatingModal}
        onHide={hideSubmitRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={hideSubmitRatingModal}
            >
              ×
            </a>
            <h3>Are you sure you want to submit the ratings? </h3>
            <div className="newBtn d-flex">
              <button
                className="Button active"
                onClick={addNonFInacialDataFunc}
                disabled={isLoading}
              >
                Yes,Submit
              </button>{" "}
              &nbsp; &nbsp;
              {/* <button
      className="Button active"
      onClick={() => toPDF()}
    >
      Download Rating
    </button>   &nbsp; &nbsp; */}
              <button className="Button" onClick={hideSubmitRatingModal}>
                No,Review
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={downloadModal}
        onHide={hideDownloadRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideDownloadRatingModal}>
    ×
  </a>
  <figure>
    <img src="images/check.png" alt="" />
  </figure>
  <p>Rating is successful </p>
  <div className="newBtn newBtn1">
    <a onClick={downloadRatingFunc} className="Button">
      View Score
    </a>
    <a onClick={downloadRatingFunc} className="Button" >
      Download Report
    </a>
    <Link to="/client-management" className="Button active">
      Go to home
    </Link>
  </div>
</div>

        </Modal.Body>
      </Modal>

    </>
  );
}

export default Clientscoringnonfinancial;
