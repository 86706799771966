import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clientDetailsAction } from "../redux/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
import { checkArray } from "../utils/CheckType";
import { usePDF } from "react-to-pdf";
import {
  NonFinancialRiskAction,
  ankorRiskListAction,
} from "../redux/actions/scoreAction";
const init = {
  bussinessRiskParameter: "",
  managementRiskParameter: "",
  bussinessConductRiskParameter: "",
  anchorRiskDataParameter: "",
  financeratingsDataParameter: "",
};
function Clientviewscoredetails() {
  const [iState, updateState] = useState(init);
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const {
    bussinessRiskParameter,
    managementRiskParameter,
    bussinessConductRiskParameter,
    anchorRiskDataParameter,
    financeratingsDataParameter,
  } = iState;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [clientDetailsData, setClientDetailsData] = useState([]);
  const [totalScore, updateTotoalScore] = useState("");
  const { toPDF, targetRef } = usePDF({ filename: "Score.pdf" });
  // const {tableRef} = useRef()
  console.log("clientDetailsData", clientDetailsData);
  useEffect(() => {
    dispatch(clientDetailsAction(state?._id, state?.anchorId)).then((res) => {
      console.log("ressssssssss,r", res?.result?.[0]);
      setClientDetailsData(res?.result?.[0]);
      // console.log("client", res)
      // const scoresArray = res?.result?.[0].nonFinanceRiskData?.map(obj => obj.score);
      // console.log("scoresArray", scoresArray)
      // // Usiamo reduce per calcolare la somma dei punteggi
      // let totalScore
      // //  =
      // //  (scoresArray.reduce((acc, score) => acc + score, 0)/scoresArray.length).toFixed();
      // console.log("totalScore",totalScore)
      // updateTotoalScore(totalScore)

      let totalScore0 = 0;
      let totalScore1 = 0;
      let totalScore2 = 0;
      let finalRating = 0;
      let ancRatings = 0;
      let fnTotal = 0;
      res?.result?.[0]?.nonfinanceratingsData?.forEach((obj) => {
        if (obj.outsideTitle === "Business Risk parameters") {
          totalScore0 += obj?.score;
        }
        if (obj.outsideTitle === "Management Risk parameters") {
          totalScore1 += obj?.score;
        }
        if (obj.outsideTitle === "Business Conduct Risk parameters") {
          totalScore2 += obj?.score;
        }
      });
      res?.result[0]?.anchorRiskData?.forEach((obj) => {
        ancRatings += obj?.score;
      });
      res?.result[0]?.financeratingsData?.forEach((obj) => {
        fnTotal += obj?.score;
      });
      // console.log("financeratingsData", fnTotal,res?.result[0]?.financeratingsData.length)
      let num1 =
        totalScore0 / res?.result[0]?.nonfinanceratingsData?.length || 0;
      let num2 =
        totalScore1 / res?.result[0]?.nonfinanceratingsData?.length || 0;
      let num3 =
        totalScore2 / res?.result[0]?.nonfinanceratingsData?.length || 0;

      let num4 = fnTotal / res?.result[0]?.financeratingsData?.length || 0;
      let num5 = ancRatings / res?.result[0]?.anchorRiskData?.length || 0;

      console.log("num5num5num5", num4);

      updateState({
        bussinessRiskParameter: num1,
        managementRiskParameter: num2,
        bussinessConductRiskParameter: num3,
        anchorRiskDataParameter: num5,
        financeratingsDataParameter: num4,
      });
    });
  }, [dispatch]);

  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="TitleBox">
              <h4 className="Title">
                <Link to="/client-view-score" className="backArrow">
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link>{" "}
                Scoring Details
              </h4>
              {/* <h4 className="Title">Pending For Approval</h4> */}
            </div>
          </div>
          <div className="InformationBox">
            <h4>Basic Details</h4>
            <div className="Information">
              <article>
                <aside>
                  <p>
                    <strong>Dealer ID</strong>{" "}
                    <span>{state?.dealer_number}</span>
                  </p>
                  <p>
                    <strong>Anchor Company Name</strong>{" "}
                    <span> {state?.anchorData?.[0]?.companyName}</span>
                  </p>
                  <p>
                    <strong>Dealer Name </strong>{" "}
                    <span>{state?.dealerName}</span>
                  </p>
                  <p>
                    <strong>Dealer Address</strong>{" "}
                    <span>{state?.basicDetails?.dealerAddress}</span>
                  </p>
                  <p>
                    <strong>Key Person Name</strong>{" "}
                    <span>{state?.anchorData?.[0]?.contactPersonName}</span>
                  </p>
                  <p>
                    <strong>Key Person Contact Number</strong>{" "}
                    <span>{state?.anchorData?.[0]?.contactPersonNumber}</span>
                  </p>
                  <p>
                    <strong>Expiry Date</strong>{" "}
                    <span>{state?.expiryDate?.split("T")[0]}</span>
                  </p>
                </aside>
                <aside>
                  <p>
                    <strong>Key Person Email Address</strong>{" "}
                    <span>{state?.anchorData?.[0]?.contactPersonEmail}</span>
                  </p>
                  <p>
                    <strong>Nature of Business</strong>{" "}
                    <span> {state?.anchorData?.[0]?.natureOfBuisness}</span>
                  </p>
                  <p>
                    <strong>Date Registered on</strong>{" "}
                    <span> {state?.createdAt?.split("T")[0]}</span>
                  </p>
                  <p>
                    <strong>PAN number</strong>{" "}
                    <span> {state?.anchorData?.[0]?.panNumber}</span>
                  </p>
                  <p>
                    <strong>GSTIN Number</strong>{" "}
                    <span> {state?.anchorData?.[0]?.gstNumber}</span>
                  </p>
                  <p>
                    <strong>CIN Number</strong>{" "}
                    <span> {state?.anchorData?.[0]?.cnnNumber}</span>
                  </p>

                  <p>
                    <strong>Dealer Relation</strong>{" "}
                    <span> {state?.relationId}</span>
                  </p>
                </aside>
              </article>
            </div>
          </div>
          <div className="InformationBox">
            <h4>Scoring Summary</h4>
            <div className="Information">
              <article>
                <aside>
                  <p>
                    <strong>Scored By</strong>{" "}
                    <span>{clientDetailsData?.scoreByName}</span>
                  </p>
                  {/* {adminData?.userType === "ADMIN" ? <></> :<> */}
                  <p>
                    <strong>Score Approved By</strong>{" "}
                    <span>{clientDetailsData?.approvedBy}</span>
                  </p>
                  <p>
                    <strong>Approval Date</strong>{" "}
                    <span>{clientDetailsData?.approvedAt?.split("T")[0]}</span>
                  </p>
                  {/* </> } */}

                  <p>
                    <strong>Base Year</strong> <span>{state?.baseYear}</span>
                  </p>
                </aside>
              </article>
            </div>
          </div>
          <div className="Small-Wrapper">
            <div className="TopScoringList">
              <div className="TableList">
                <table ref={targetRef} style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Risk Category</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>1</td>
                    <td>Anchor / Industry Risk</td>
                    <td>
                      {clientDetailsData?.overallRatingAnchorRisk
                        ? clientDetailsData?.overallRatingAnchorRisk.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Financial Risk</td>
                    <td>
                      {clientDetailsData?.overallRatingFinanceRisk
                        ? clientDetailsData?.overallRatingFinanceRisk.toFixed(2)
                        : 0}
                    </td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td> Bussiness Risk</td>
                    <td>
                      {clientDetailsData?.overallRatingBuisnessRisk
                        ? clientDetailsData?.overallRatingBuisnessRisk.toFixed(
                            2
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Management Risk</td>
                    <td>
                      {clientDetailsData?.overallRatingManagementRisk
                        ? clientDetailsData?.overallRatingManagementRisk.toFixed(
                            2
                          )
                        : 0}
                    </td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td> Account Conduct</td>
                    <td>
                      {clientDetailsData?.overallRatingAccountRisk
                        ? clientDetailsData?.overallRatingAccountRisk.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Overall Risk Score</td>
                    <td>
                      {clientDetailsData?.overallRating
                        ? clientDetailsData?.overallRating.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Customer Category</td>
                    <td>
                      {clientDetailsData?.overallRating < 1.5
                        ? "High Risk"
                        : clientDetailsData?.overallRating >= 1.5 &&
                          clientDetailsData?.overallRating < 3.5
                        ? "Medium Risk"
                        : clientDetailsData?.overallRating >= 3.5 &&
                          clientDetailsData?.overallRating <= 5.0
                        ? "Low Risk"
                        : ""}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="newBtn">
              <Link
                to="/client-financial-rating"
                state={state}
                className="Button"
              >
                View Complete Rating
              </Link>{" "}
              &nbsp; &nbsp;
              <a
                href={clientDetailsData?.reportLink}
                target="_blank"
                className="Button active"
              >
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clientviewscoredetails;
