import React from 'react'
import Header from './Header'
import Sidenav from './Sidenav'

function Clientscorependingdetails() {
  return (
     <>
     <Header/>
     <Sidenav/>
     <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">
          <a href="client-management.html" className="backArrow">
            <i className="fa fa-arrow-left" aria-hidden="true" />
          </a>{" "}
          Scoring Details
        </h4>
      </div>
    </div>
    <div className="InformationBox">
      <h4>Basic Details</h4>
      <div className="Information">
        <article>
          <aside>
            <p>
              <strong>Dealer ID</strong> <span>#2364965097</span>
            </p>
            <p>
              <strong>Anchor Company Name</strong>{" "}
              <span> DCG Private Technologies</span>
            </p>
            <p>
              <strong>Dealer Name </strong>{" "}
              <span>DCG Private Technologies</span>
            </p>
            <p>
              <strong>Dealer Address</strong>{" "}
              <span>14/223 Bhishma Pitamah Marg, New Delhi, Delhi 110067 </span>
            </p>
            <p>
              <strong>Contact Number</strong> <span>+91824097093</span>
            </p>
            <p>
              <strong>Email Address</strong> <span>Abc@gmail.com</span>
            </p>
          </aside>
          <aside>
            <p>
              <strong>Nature of Business</strong> <span> Manufacturing</span>
            </p>
            <p>
              <strong>Date Registered on</strong> <span> 12/03/1992</span>
            </p>
            <p>
              <strong>PAN number</strong> <span> 2364965097</span>
            </p>
            <p>
              <strong>GSTIN Number</strong> <span> 2364965097</span>
            </p>
            <p>
              <strong>CIN Number</strong> <span> 2364965097</span>
            </p>
          </aside>
        </article>
      </div>
    </div>
    <div className="InformationBox">
      <h4>Scoring Summary</h4>
      <div className="Information">
        <article>
          <aside>
            <p>
              <strong>Scored By</strong> <span>#2364965097, Staff Name</span>
            </p>
            <p>
              <strong>Score Approved By</strong>{" "}
              <span>#3459579, Staff Name</span>
            </p>
            <p>
              <strong>Approval Date</strong> <span>12/04/2023</span>
            </p>
            <p>
              <strong>Base Year</strong> <span>2023</span>
            </p>
          </aside>
        </article>
      </div>
    </div>
    <div className="Small-Wrapper">
      <div className="TopScoringList">
        <div className="TableList">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Risk Category</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Anchor/Industry Risk</td>
                <td>4</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Financial Risk</td>
                <td>4.5</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Business Risk</td>
                <td>5</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Management Risk</td>
                <td>3</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Account Conduct</td>
                <td>4</td>
              </tr>
              <tr>
                <td colSpan={2}>Overall Risk Score</td>
                <td>4.8</td>
              </tr>
              <tr>
                <td colSpan={2}>Customer Category</td>
                <td>Green/Yellow/Red</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="newBtn">
        <a href="client-financial-rating.html" className="Button">
          View Complete Rating
        </a>
        <a href="client-score-panding-detail.html" className="Button active">
          Download PDF
        </a>
      </div>
    </div>
  </div>
</div>


     </>
  )
}
export default Clientscorependingdetails