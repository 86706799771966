import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clientDetailsAction,
  downloadReportAction,
  getFinanceListAction,
  getScoreAction,
  updateFinancialManagementAction,
} from "../redux/actions/clientAction";
import Header from "./Header";
import Sidenav from "./Sidenav";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { checkArray } from "../utils/CheckType";
import { sucessToast } from "../utils/toast";
import { usePDF } from "react-to-pdf";
import { saveAs } from "file-saver";
import { range } from "lodash";
import { DeepClone } from "../utils/uploadFile";
const init = {
  rangeId: "",
  disabledState: true,
  submitRatingModal: false,
};
function Clientfinancialrating() {
  const [iState, updateState] = useState(init);
  const { rangeId, disabledState, submitRatingModal } = iState;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { anchorId, clientId } = useParams();
  const [clientDetailsData, setClientDetailsData] = useState([]);
  const { financeRiskListData, loader } = useSelector(
    (state) => state.mainReducer
  );
  const { toPDF, targetRef } = usePDF({ filename: "FinancialRating.pdf" });
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log("clientDetailsData",clientDetailsData);
  console.log("data", data);
 
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const res = await dispatch(clientDetailsAction(state?._id, state?.anchorId));
  
       
  
        const updatedClientDetails = res?.result;

        setClientDetailsData(updatedClientDetails);
      } catch (error) {
        console.error("Error fetching client details:", error);
        // Optionally, you can handle errors here, e.g., show an error toast
      }
    };
  
    fetchClientDetails();
  }, [dispatch, state?._id, state?.anchorId]);
  
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 3;
  let pathMatch = path[pathLength];


  const handleInput = (e, type, i) => {
    const { name, value } = e.target;
    console.log({ name, value });
     if(type === "auto"){
       const cloneClientsData = JSON.parse(JSON.stringify(clientDetailsData));
       const dummyScore = cloneClientsData[0]?.financeratingsData?.[i]?.financeratingsDataValue?.find((item) => item._id === value);
       if (dummyScore) {
         cloneClientsData[0].financeratingsData[i].score = dummyScore.score;
         cloneClientsData[0].financeratingsData[i].range = dummyScore.range;
         
        }
        setClientDetailsData(cloneClientsData);
      }

      else {
       const cloneClientsData = JSON.parse(JSON.stringify(clientDetailsData));
         cloneClientsData[0].financeratingsData[i].score = value;
        setClientDetailsData(cloneClientsData);
      }
  };
  



  



  const showSubmitRatingModal = () => {
    updateState({ ...iState, submitRatingModal: true });
  };

  const hideSubmitRatingModal = () => {
    updateState({ ...iState, submitRatingModal: false });
  };

  const addFinacialDataFunc = async () => {
    try {
      const dataa = {
        anchorId: state?.anchorId,
        clientId: state?._id,
        financeRatingData: clientDetailsData?.[0]?.financeratingsData,
      };
  
      // Update financial management data
      const updateRes = await dispatch(updateFinancialManagementAction(dataa));
  
      // Fetch updated client details
      const clientDetailsRes = await dispatch(clientDetailsAction(state?._id, state?.anchorId));
  
      console.log("resss", clientDetailsRes);
  
      const updatedClientDetails = clientDetailsRes?.result;
      setClientDetailsData(updatedClientDetails);
  
      // Show success toast and navigate to the next page
      sucessToast("Finance rating updated successfully");
      // navigate("/client-nonfinancial-rating", { state });
  
      // Hide the submit rating modal and disable state
      hideSubmitRatingModal();
      updateState((prev) => ({ ...prev, disabledState: true }));
    } catch (error) {
      console.error("Error updating financial data:", error);
      // Optionally, you can handle errors here, e.g., show an error toast
    }
  
    console.log("state", state);
  };
  
  

  const downloadRatingFunc = () => {
    let financeData = clientDetailsData?.[0]?.financeratingsData?.map((ele, i) => {
      // // // console.log(ele)
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
        value: ele?.value,
      };
    });

    let businessNonFinacePdfData =
      clientDetailsData?.[0]?.nonfinanceratingsData?.map((ele, i) => {
        if (ele?.outsideTitle === "Business Risk parameters") {
          //  console.log("elelelelelelle",ele)
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });

    let accountNonFinacePdfData = clientDetailsData?.[0]?.nonfinanceratingsData?.map(
      (ele, i) => {
        // console.log("Elelelelelel",ele)
        if (ele?.outsideTitle === "Account Conduct Risk parameters") {
          console.log("elelelelelelle", ele);
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      }
    );

    let managementNonFinacePdfData =
      clientDetailsData?.[0]?.nonfinanceratingsData?.map((ele, i) => {
        if (ele?.outsideTitle === "Management Risk parameters") {
          // console.log("elelelelelelle",ele)
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });

    let anchorData = clientDetailsData?.[0]?.anchorRatingData?.map((ele, i) => {
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
      };
    });

    let anchorRiskData = clientDetailsData?.[0]?.overallRatingAnchorRisk
      ? clientDetailsData?.[0]?.overallRatingAnchorRisk.toFixed(2)
      : 0;
    let financeRiskData = clientDetailsData?.[0]?.overallRatingFinanceRisk
      ? clientDetailsData?.[0]?.overallRatingFinanceRisk.toFixed(2)
      : 0;
    let businessRiskData = clientDetailsData?.[0]?.overallRatingBuisnessRisk
      ? clientDetailsData?.[0]?.overallRatingBuisnessRisk.toFixed(2)
      : 0;
    let managementRiskData = clientDetailsData?.[0]?.overallRatingManagementRisk
      ? clientDetailsData?.[0]?.overallRatingManagementRisk.toFixed(2)
      : 0;
    let accountRiskData = clientDetailsData?.[0]?.overallRatingAccountRisk
      ? clientDetailsData?.[0]?.overallRatingAccountRisk.toFixed(2)
      : 0;
    let overAllRiskData = clientDetailsData?.[0]?.overallRating
      ? clientDetailsData?.[0]?.overallRating.toFixed(2)
      : 0;
    let categoryColor =
      clientDetailsData?.[0]?.overallRating < 2
        ? "Green"
        : clientDetailsData?.[0]?.overallRating < 3
        ? "Yellow"
        : clientDetailsData?.[0]?.overallRating < 4
        ? "Orange"
        : clientDetailsData?.[0]?.overallRating < 5
        ? "Red"
        : "";

    let data = {
      clientId: clientDetailsData?.[0]?._id,
      financeData,
      businessNonFinacePdfData: businessNonFinacePdfData?.filter(
        (item) => item !== null
      ),
      accountNonFinacePdfData: accountNonFinacePdfData?.filter(
        (item) => item !== null
      ),
      managementNonFinacePdfData: managementNonFinacePdfData?.filter(
        (item) => item !== null
      ),

      // nonFinanceData,
      anchorData,
      anchorRiskData,
      financeRiskData,
      businessRiskData,
      managementRiskData,
      accountRiskData,
      overAllRiskData,
      categoryColor,
    };
    dispatch(downloadReportAction(data)).then((res) => {
      // // // console.log("resssssssss",res)
      window.open(res.link, "_blank");
      // const blob = new Blob([data], { type: 'application/pdf' })
      //   saveAs(blob, "tickets.pdf")
      // href={
      //   studentHelpdetails?.askedRequest
      //     ?.tutorAttachDocument?.[0]?.link
      // }
      // target="_self"
    });
  };

  const viewRatingFunc = () => {
    let financeData = clientDetailsData?.financeratingsData?.map((ele, i) => {
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
      };
    });
    let nonFinanceData = clientDetailsData?.nonfinanceratingsData?.map(
      (ele, i) => {
        return {
          parameterName: ele?.parameterName,
          range: ele?.range,
          score: ele?.score,
        };
      }
    );

    let anchorData = clientDetailsData?.anchorRatingData?.map((ele, i) => {
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
      };
    });

    let anchorRiskData = clientDetailsData?.overallRatingAnchorRisk
      ? clientDetailsData?.overallRatingAnchorRisk.toFixed(2)
      : 0;
    let financeRiskData = clientDetailsData?.overallRatingFinanceRisk
      ? clientDetailsData?.overallRatingFinanceRisk.toFixed(2)
      : 0;
    let businessRiskData = clientDetailsData?.overallRatingBuisnessRisk
      ? clientDetailsData?.overallRatingBuisnessRisk.toFixed(2)
      : 0;
    let managementRiskData = clientDetailsData?.overallRatingManagementRisk
      ? clientDetailsData?.overallRatingManagementRisk.toFixed(2)
      : 0;
    let accountRiskData = clientDetailsData?.overallRatingAccountRisk
      ? clientDetailsData?.overallRatingAccountRisk.toFixed(2)
      : 0;
    let overAllRiskData = clientDetailsData?.overallRating
      ? clientDetailsData?.overallRating.toFixed(2)
      : 0;
    let categoryColor =
      clientDetailsData?.overallRating < 2
        ? "Green"
        : clientDetailsData?.overallRating < 3
        ? "Yellow"
        : clientDetailsData?.overallRating < 4
        ? "Orange"
        : clientDetailsData?.overallRating < 5
        ? "Red"
        : "";

    let data = {
      clientId: clientDetailsData?._id,
      financeData,
      nonFinanceData,
      anchorData,
      anchorRiskData,
      financeRiskData,
      businessRiskData,
      managementRiskData,
      accountRiskData,
      overAllRiskData,
      categoryColor,
    };
    dispatch(downloadReportAction(data)).then((res) => {
      console.log("resssssssss", res);
      window.open(res.link, "_target");
      //   const blob = new Blob([data], { type: 'application/pdf' })
      //   saveAs(blob, "tickets.pdf")
    });
  };

  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">
              {/* {state?.UrlLink === "pending" ?<Link to="/client-scoring-details" state={state} className="backArrow">
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </Link> : */}
              <a onClick={() => navigate(-1)} className="backArrow">
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </a>
              View Score
            </h4>
          </div>
          <div className="Small-Wrapper">
            <div className="CommonLinks">
              <ul>
                <li
                  className={
                    pathMatch == "client-financial-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-financial-rating" state={state}>
                    Financial Rating
                  </Link>
                </li>
                <li
                  className={
                    pathMatch == "client-nonfinancial-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-nonfinancial-rating" state={state}>
                    Non-Financial Rating
                  </Link>
                </li>
                <li
                  className={
                    pathMatch == "client-anchor-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-anchor-rating" state={state}>
                    Anchor Rating
                  </Link>
                </li>
              </ul>
              {/* {DIS} */}
              {/* {state?.UrlLink === "pending" ?  <></> : <>
              <div className='d-flex align-items-center'>
              {disabledState ?  <a onClick={() => updateState({...iState,disabledState:!disabledState })} className="Green">
          <i className="fa fa-pencil" />
        </a>  : <></>} 
        </div></>}  */}

              <a
                onClick={() =>
                  updateState({ ...iState, disabledState: !disabledState })
                }
                className="Green"
              >
                <i className="fa fa-pencil" />
              </a>
              <a onClick={downloadRatingFunc} className="Button active ml-3">
                Download PDF
              </a>
            </div>
            <div className="TableList TableFinance">
              <table ref={targetRef} style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>Range</th>
                    <th>Score</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <Loader />
                    </>
                  ) : 
                  
                 // Inside your component's JSX

  checkArray(clientDetailsData?.[0]?.financeratingsData) ? (
    clientDetailsData?.[0]?.financeratingsData?.map((ele, i) => {
    //  const dummyScore = ele?.financeratingsDataValue?.find((elem) => elem.range==ele.range)
    
      return (
        <tr key={ele._id}>
          <td>{ele.parameterName}</td>
          <td>
            <div className="form-group">
              <select
                className="form-control"
                name="range"
                // value={ele.range}
                onChange={(e) => handleInput(e,"auto", i)}
                disabled={disabledState ? true : false}
              >
                
                {checkArray(ele.financeratingsDataValue) &&
                  ele.financeratingsDataValue?.map((child,index) => (
                    <option key={child._id} value={child._id} 
                    selected={ele.range===child.range}>
                      {child.range}
                    </option>
                  ))}
              </select>
            </div>
          </td>
          <td>
            <div className="form-group">
              <input
                type="number"
                placeholder="Score"
                className="form-control"
                disabled={disabledState ? true : false}
                name="score"
                onChange={(e) => handleInput(e,"manul", i)}
                // defaultValue={ele.score}
                 value={ele?.score}
              />
            </div>
          </td>
          <td>{Number(ele.value).toFixed(2)}</td>
        </tr>
      );
    })
  ) : (
    <p>No data found</p>
  )
}

                  
                  
                  
                  
                    
                  
                  
                  
                </tbody>
              </table>
            </div>
          </div>
          {disabledState === false ? (
            <div className="newBtn">
              <button className="Button" onClick={showSubmitRatingModal}>
                Submit financial rating
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Modal
        show={submitRatingModal}
        onHide={hideSubmitRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={hideSubmitRatingModal}
            >
              ×
            </a>
            <h3>Are you sure you want to submit the ratings? </h3>
            <div className="newBtn d-flex">
              <button className="Button active" onClick={addFinacialDataFunc}>
                Yes,Submit
              </button>{" "}
              &nbsp; &nbsp;
              <button className="Button active" onClick={() => toPDF()}>
                Download Rating
              </button>{" "}
              &nbsp; &nbsp;
              <button className="Button" onClick={hideSubmitRatingModal}>
                No,Review
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Clientfinancialrating;
