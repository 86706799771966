import React, { useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorToast, sucessToast } from '../utils/toast';
import { changePassword } from '../redux/actions/authAction';
import { checkPassword } from '../utils/CheckType';
const initialState = {
  oldPassword:"",
  newPassword:"",
  confirmPassword:"",
  errors:{},
  userId:""
}
function Setting() {

  const [iState, updateState] = useState(initialState);
  const{oldPassword,newPassword,confirmPassword, errors, userId} = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  const id = adminData._id


  const [showNewPassword, setshowNewPassword] = useState(true);
  const [showOldPassword, setshowOldPassword] = useState(true);
  const [showConfirmPassword, setshowConfirmPassword] = useState(true);


  const passwordVisibility = () => {
    if (showOldPassword) {
      setshowOldPassword(false);
    } else {
      setshowOldPassword(true);
    }
  };

  const confirmPasswordVisibility = () => {
    if (showConfirmPassword) {
      setshowConfirmPassword(false);
    } else {
      setshowConfirmPassword(true);
    }
  };


  const newPasswordVisibility = () => {
    if (showNewPassword) {
      setshowNewPassword(false);
    } else {
      setshowNewPassword(true);
    }
  }
  const handlePassword = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log()
    updateState({ ...iState, [name]: value });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!oldPassword.trim("")) {
        error.oldPasswordError = "Old password can't be empty";
        formIsValid = false;
      }

    if (!newPassword.trim("")) {
      error.newPasswordError = "New password can't be empty";
      formIsValid = false;
    }


    if(newPassword && confirmPassword){
      if(!checkPassword(newPassword)){
        error.newPasswordError = "Password length must be of 8 character including the numeric , alphabet and special character"
        formIsValid = false
      }
     }

    if (!confirmPassword.trim("")) {
      error.confirmPasswordError = "Confirm password can't be empty";
      formIsValid = false;
    }
    if(newPassword && confirmPassword && oldPassword){
        if (newPassword !== confirmPassword) {
          error.confirmPasswordError = "New password and confirm password must be same";
            // errorToast("New password and confirm password must be same")
            formIsValid = false;
          }
    }
    

    if(newPassword &&confirmPassword){
      if(!checkPassword(confirmPassword)){
        error.confirmPasswordError = "Password length must be of 8 character including the numeric , alphabet and special character";
        formIsValid = false
      }
     }
    updateState({ ...iState, errors: error });
    return formIsValid;
  };

  const handlechangePassword = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
    
      dispatch(changePassword({password: oldPassword, newPassword,  }))
        .then((res) => {
          if (res.code === 200) {
            console.log(res);
             sucessToast("Password updated successfully")
           updateState({...iState, oldPassword:"", newPassword:"", confirmPassword:""})

          }
        })
        .catch((err) => {
          if(err.response.status === 401)
          errorToast("Please enter your old valid password")
          console.log(err);
        });
    }
  };
  return (
  <>
  <Header/>
  <Sidenav/>
  <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="PasswordBox">
      <aside>
        <h4>Password Settings</h4>
      </aside>
       {/* {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "setting" &&
                      ele.fullAccess == true
                  ) && (
                    <>
                       <article>
        <div className="CommonForm">
          <div className="form-group">
            <label>Current Password</label>
            <input
                                                         type={showOldPassword ? "password" : "text"}
                                                        name="oldPassword"
                                                        value={oldPassword}
                                                        onChange={handlePassword}
                                                        className="form-control"
                                                        placeholder="Enter your old Password"
                                                    />
                                                    <span style={{color:"red", fontSize:"14px"}}>{errors.oldPasswordError}</span>
            <span onClick={passwordVisibility} className="Eye">
              <i className="fa fa-eye"></i>
            </span>
          </div>
          <div className="form-group">
            <label>Create New Password</label>
            <input
             type={showNewPassword ? "password" : "text"}
              name="newPassword"
              value={newPassword}
              onChange={handlePassword}
              className="form-control"
              placeholder="Create New Password"
          />
           <span style={{color:"red", fontSize:"14px"}}>{errors.newPasswordError}</span>
            <span onClick={newPasswordVisibility} className="Eye">
              <i className="fa fa-eye" />
            </span>
          </div>
          <div className="form-group">
            <label>Confirm New password</label>
            <input
                type={showConfirmPassword ? "password" : "text"}
               name="confirmPassword"
               value={confirmPassword}
               onChange={handlePassword}
               className="form-control"
               placeholder="Confirm your password"
           />
            <span style={{color:"red", fontSize:"14px"}}>{errors.confirmPasswordError}</span>
            <span onClick={confirmPasswordVisibility} className="Eye">
              <i className="fa fa-eye" />
            </span>
          </div>
          <button onClick={handlechangePassword} className="Button">Change Password</button>
        </div>
      </article>
                    </>
               
                  )}
                </>
              ) : (
                <>  */}
                  <article>
        <div className="CommonForm">
          <div className="form-group">
            <label>Current Password</label>
            <input
                                                         type={showOldPassword ? "password" : "text"}
                                                        name="oldPassword"
                                                        value={oldPassword}
                                                        onChange={handlePassword}
                                                        className="form-control"
                                                        placeholder="Enter your old Password"
                                                    />
                                                    <span style={{color:"red", fontSize:"14px"}}>{errors.oldPasswordError}</span>
            <span onClick={passwordVisibility} className="Eye">
              <i className="fa fa-eye"></i>
            </span>
          </div>
          <div className="form-group">
            <label>Create New Password</label>
            <input
             type={showNewPassword ? "password" : "text"}
              name="newPassword"
              value={newPassword}
              onChange={handlePassword}
              className="form-control"
              placeholder="Create New Password"
          />
           <span style={{color:"red", fontSize:"14px"}}>{errors.newPasswordError}</span>
            <span onClick={newPasswordVisibility} className="Eye">
              <i className="fa fa-eye" />
            </span>
          </div>
          <div className="form-group">
            <label>Confirm New password</label>
            <input
                type={showConfirmPassword ? "password" : "text"}
               name="confirmPassword"
               value={confirmPassword}
               onChange={handlePassword}
               className="form-control"
               placeholder="Confirm your password"
           />
            <span style={{color:"red", fontSize:"14px"}}>{errors.confirmPasswordError}</span>
            <span onClick={confirmPasswordVisibility} className="Eye">
              <i className="fa fa-eye" />
            </span>
          </div>
          <button onClick={handlechangePassword} className="Button">Change Password</button>
        </div>
      </article>
                {/* </>
              )} */}
     
    </div>
  </div>
</div>

  </>
  )
}

export default Setting