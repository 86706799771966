import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clientDetailsAction, downloadReportAction } from "../redux/actions/clientAction";
import { usePDF } from "react-to-pdf";

function ClientScoringDetails() {
  const [clientDetailsData, setClientDetailsData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { clientPendingId } = useParams();
  console.log("statetete", state);
  const { toPDF, targetRef } = usePDF({ filename: "Score.pdf" });
  useEffect(() => {
    dispatch(clientDetailsAction(state?._id, state?.anchorId)).then((res) => {
      console.log("resssssss", res?.result[0]);
      setClientDetailsData(res?.result[0]);
    });
  }, []);

  const goToFinacialDetails = (locationData) => {
    let sendData = {
      locationData,
      UrlLink: "pending",
    };
    navigate("/client-financial-rating");
  };

  const downloadRatingFunc = () => {
    let financeData = clientDetailsData?.financeratingsData?.map((ele, i) => {
      // // // console.log(ele)
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
        value: ele?.value,
      };
    });

    let businessNonFinacePdfData =
      clientDetailsData?.nonfinanceratingsData?.map((ele, i) => {
        if (ele?.outsideTitle === "Business Risk parameters") {
          //  console.log("elelelelelelle",ele)
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });

    let accountNonFinacePdfData = clientDetailsData?.nonfinanceratingsData?.map(
      (ele, i) => {
        // console.log("Elelelelelel",ele)
        if (ele?.outsideTitle === "Account Conduct Risk parameters") {
          console.log("elelelelelelle", ele);
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      }
    );

    let managementNonFinacePdfData =
      clientDetailsData?.nonfinanceratingsData?.map((ele, i) => {
        if (ele?.outsideTitle === "Management Risk parameters") {
          // console.log("elelelelelelle",ele)
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });

    let anchorData = clientDetailsData?.anchorRatingData?.map((ele, i) => {
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
      };
    });

    let anchorRiskData = clientDetailsData?.overallRatingAnchorRisk
      ? clientDetailsData?.overallRatingAnchorRisk.toFixed(2)
      : 0;
    let financeRiskData = clientDetailsData?.overallRatingFinanceRisk
      ? clientDetailsData?.overallRatingFinanceRisk.toFixed(2)
      : 0;
    let businessRiskData = clientDetailsData?.overallRatingBuisnessRisk
      ? clientDetailsData?.overallRatingBuisnessRisk.toFixed(2)
      : 0;
    let managementRiskData = clientDetailsData?.overallRatingManagementRisk
      ? clientDetailsData?.overallRatingManagementRisk.toFixed(2)
      : 0;
    let accountRiskData = clientDetailsData?.overallRatingAccountRisk
      ? clientDetailsData?.overallRatingAccountRisk.toFixed(2)
      : 0;
    let overAllRiskData = clientDetailsData?.overallRating
      ? clientDetailsData?.overallRating.toFixed(2)
      : 0;
    let categoryColor =
      clientDetailsData?.overallRating < 2
        ? "Green"
        : clientDetailsData?.overallRating < 3
        ? "Yellow"
        : clientDetailsData?.overallRating < 4
        ? "Orange"
        : clientDetailsData?.overallRating < 5
        ? "Red"
        : "";

    let data = {
      clientId: clientDetailsData?._id,
      financeData,
      businessNonFinacePdfData: businessNonFinacePdfData.filter(
        (item) => item !== null
      ),
      accountNonFinacePdfData: accountNonFinacePdfData.filter(
        (item) => item !== null
      ),
      managementNonFinacePdfData: managementNonFinacePdfData.filter(
        (item) => item !== null
      ),

      // nonFinanceData,
      anchorData,
      anchorRiskData,
      financeRiskData,
      businessRiskData,
      managementRiskData,
      accountRiskData,
      overAllRiskData,
      categoryColor,
    };
    dispatch(downloadReportAction(data)).then((res) => {
      // // // console.log("resssssssss",res)
      window.open(res.link, "_blank");
      // const blob = new Blob([data], { type: 'application/pdf' })
      //   saveAs(blob, "tickets.pdf")
      // href={
      //   studentHelpdetails?.askedRequest
      //     ?.tutorAttachDocument?.[0]?.link
      // }
      // target="_self"
    });
  };
  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="TitleBox">
              <h4 className="Title">Scoring Details</h4>
            </div>
          </div>
          <div className="InformationBox">
            <h4>Basic Details</h4>
            <div className="Information">
              <article>
                <aside>
                  <p>
                    <strong>Dealer ID</strong>{" "}
                    <span>{clientDetailsData?.dealer_number}</span>
                  </p>
                  <p>
                    <strong>Anchor Company Name</strong>{" "}
                    <span>
                      {" "}
                      {clientDetailsData?.basicDetails?.anchorCompanyName}
                    </span>
                  </p>
                  <p>
                    <strong>Dealer Name </strong>{" "}
                    <span>{clientDetailsData?.dealerName}</span>
                  </p>
                  <p>
                    <strong>Dealer Address</strong>{" "}
                    <span>
                      {clientDetailsData?.basicDetails?.dealerAddress}
                    </span>
                  </p>
                  <p>
                    <strong>Key Person Name</strong>{" "}
                    <span>
                      {clientDetailsData?.basicDetails?.keyPersonName}
                    </span>
                  </p>
                  <p>
                    <strong>Key Person Contact Number</strong>{" "}
                    <span>
                      {clientDetailsData?.basicDetails?.keyPersonContact}
                    </span>
                  </p>
                </aside>
                <aside>
                  <p>
                    <strong>Key Person Email Address</strong>{" "}
                    <span>
                      {clientDetailsData?.basicDetails?.keyPersonEmail}
                    </span>
                  </p>
                  <p>
                    <strong>Nature of Business</strong>{" "}
                    <span>
                      {" "}
                      {clientDetailsData?.basicDetails?.natureOfBusiness}
                    </span>
                  </p>
                  <p>
                    <strong>Date Registered on</strong>{" "}
                    <span> {clientDetailsData?.createdAt?.split("T")[0]}</span>
                  </p>
                  <p>
                    <strong>PAN number</strong>{" "}
                    <span> {clientDetailsData?.basicDetails?.panNumber}</span>
                  </p>
                  <p>
                    <strong>GSTIN Number</strong>{" "}
                    <span> {clientDetailsData?.basicDetails?.gstinNumber}</span>
                  </p>
                  <p>
                    <strong>CIN Number</strong>{" "}
                    <span> {clientDetailsData?.basicDetails?.cnnNumber}</span>
                  </p>
                </aside>
              </article>
            </div>
          </div>
          <div className="InformationBox">
            <h4>Basic Details</h4>
            <div className="TopScoringList">
              <div className="TableList">
                <table ref={targetRef} style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Risk Category</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Anchor / Industry Risk</td>
                      <td>
                        {clientDetailsData?.overallRatingAnchorRisk
                          ? clientDetailsData?.overallRatingAnchorRisk.toFixed(
                              2
                            )
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Financial Risk</td>
                      <td>
                        {clientDetailsData?.overallRatingFinanceRisk
                          ? clientDetailsData?.overallRatingFinanceRisk.toFixed(
                              2
                            )
                          : 0}
                      </td>
                    </tr>

                    <tr>
                      <td>3</td>
                      <td> Bussiness Risk</td>
                      <td>
                        {clientDetailsData?.overallRatingBuisnessRisk
                          ? clientDetailsData?.overallRatingBuisnessRisk.toFixed(
                              2
                            )
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Management Risk</td>
                      <td>
                        {clientDetailsData?.overallRatingManagementRisk
                          ? clientDetailsData?.overallRatingManagementRisk.toFixed(
                              2
                            )
                          : 0}
                      </td>
                    </tr>

                    <tr>
                      <td>5</td>
                      <td> Account Conduct</td>
                      <td>
                        {clientDetailsData?.overallRatingAccountRisk
                          ? clientDetailsData?.overallRatingAccountRisk.toFixed(
                              2
                            )
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">Overall Risk Score</td>
                      <td>
                        {clientDetailsData?.overallRating
                          ? clientDetailsData?.overallRating.toFixed(2)
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="newBtn">
              <Link
                to={`/client-financial-rating/${state?.anchorId}/${state?._id}`}
                state={state}
                className="Button"
              >
                View Complete Rating
              </Link>{" "}
              &nbsp; &nbsp;
              <a onClick={() => toPDF()} className="Button active">
                Download PDF
              </a>
            </div> */}
            <div className="newBtn">
              <Link
                to="/client-financial-rating"
                state={state}
                className="Button"
              >
                View Complete Rating
              </Link>{" "}
              &nbsp; &nbsp;
              {/* to={`/client-financial-rating/${state?.anchorId}/${state?._id}`}
                state={state}
                className="Button"
              > */}
              {/* </Link>{" "} */}
              {
                clientDetailsData?.is_pdf===false?
                <a
                href={clientDetailsData?.reportLink}
                target="_blank"
                className="Button"
              >
                Download Complete Rating{" "}
              </a>
              :
              <a
               onClick={()=>downloadRatingFunc()}
              className="Button"
            >
              Download Complete Ratings{" "}
            </a>
              }
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientScoringDetails;
