import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { userDetailsAction } from '../redux/actions/anchorAction';

function Anchorpendingview() {
  const{state} = useLocation();
  const [UserDetailsData, updateUserDetailsData] = useState([])
  const[anchorDetailsData, updateAnchorDetailsData] = useState([])
   const dispatch = useDispatch();


   useEffect(( ) => {
     dispatch(userDetailsAction(state)).then((res) => {
      console.log("rsssss, re",res)
      updateUserDetailsData(res?.askedUser?.[0])
      updateAnchorDetailsData(res?.data)
     })
   },[])

  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">
          <Link to="/anchor-pending-approval" className="backArrow">
            <i className="fa fa-arrow-left" aria-hidden="true" />
          </Link>
          Provide Access
        </h4>
      </div>
      <div className="BusinessPersonal BusinessPersonal2">
        <figcaption>
          <p>
            <strong>User ID : </strong> {UserDetailsData?.user_number}
          </p>
          <p>
            <strong>User Name : </strong> {UserDetailsData?.name}
          </p>
          <p>
            <strong>Contact Number : </strong> {UserDetailsData?.phoneNumber}
          </p>
          <p>
            <strong>Email Address : </strong>  {UserDetailsData?.email}{" "}
          </p>
          <p>
            <strong>Date of Request : </strong>  {UserDetailsData?.createdAt?.split("T")[0]}{" "}
          </p>
        </figcaption>
        <figure>
          <img src={UserDetailsData?.empIdPic} alt="" />
          <p className="idCard">Id Card</p>
        </figure>
      </div>
      {/* <div className="anchor-view-access">
        <div className="anchor-view-heading">
          <h3>Selected Anchor Company </h3>
          <a className="Green" href="#">
            <i className="fa fa-pencil" />
          </a>
        </div>
        <ul>
          <li>Hindon</li>
          <li>DCG Group</li>
          <li>Bajaj Group</li>
          <li>Samsung Pvt Ltd</li>
        </ul>
      </div> */}
      {/* <div class="CommonForm">
              <div class="row"> 
                  <div class="col-sm-4">
                      <div class="form-group">
                          <select class="form-control">
                              <label>Select Anchor Company </label>
                              <option>DCG private limited</option>
                              <option>ABC Company Name 1</option>
                              <option>ABC Company Name 2</option>
                              <option>ABC Company Name 3</option>
                              <option>ABC Company Name 4</option>
                          </select>
                      </div>
                  </div>
              </div>
          </div> */}
      {/* <div class="Information">
              <aside>
                  <div class="form-group1">
                      <label class="CheckBox">All
                          <input type="checkbox">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="form-group1">
                      <label class="CheckBox">ABC Company Name 1
                          <input type="checkbox">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="form-group1">
                      <label class="CheckBox">ABC Company Name 2
                          <input type="checkbox">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="form-group1">
                      <label class="CheckBox">ABC Company Name 3
                          <input type="checkbox">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="form-group1">
                      <label class="CheckBox">ABC Company Name 4
                          <input type="checkbox">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="form-group1">
                      <label class="CheckBox">ABC Company Name 
                          <input type="checkbox">
                          <span class="checkmark"></span>
                      </label>
                  </div>
              </aside> 
          </div> */}
    </div>
  </div>
</div>

   </>
  )
}

export default  Anchorpendingview