import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeStaffStatusAction, staffListAction } from '../redux/actions/staffAction'
import NoDataFound from './NoDataFound'
import Pagination from 'react-js-pagination'
import Loader from './Loader'
import { checkArray } from '../utils/CheckType'
import { sucessToast } from '../utils/toast'
import { Modal } from 'react-bootstrap'
const init = {
  search:"",
  blockModal:false,unblockModal:false, modalId:"", status:"",
  deleteModal:false, modalName:""
}
function Staffmanagment() {
  const[iState, updateState] = useState(init);
  const{search ,blockModal, unblockModal, modalId,status, deleteModal,modalName} = iState;
  const anchorRef = useRef();
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
const{staffListData, loader} = useSelector((state ) => state.mainReducer)
 console.log('staffManagementAction', staffListData)
 const dispatch = useDispatch();
 const navigate = useNavigate();
 
 const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
 useEffect(() => {
   dispatch(staffListAction())
 },[])


 const handlePageChange = (pageNumber) => {
  let ser = pageNumber * 10;
  let payloadData = {
    page:pageNumber
  };
  updateSerialNo(ser);
  updateActivePage(pageNumber);
  dispatch(staffListAction("",pageNumber))
}

const changeAnchorStatusFunc = ( ) => {
  console.log(status)
  dispatch(changeStaffStatusAction({id:modalId, status})).then((res) => {
    console.log("ressss",res)
    sucessToast("Status changed successfully")
    dispatch(staffListAction(search))
    if(status == "ACTIVE"){
      console.log(status)
      hideUnblockModal()
    } else{
      hideBlockModal()
      // hideUnblockModal()
    }
   
   
  })
}

const removeAnchorFunc = () => {
  dispatch(changeStaffStatusAction({id:modalId, status:"DELETED"})).then((res) => {
    console.log("ressss",res)
    sucessToast("Staff removed successfully")
    dispatch(staffListAction(search))
    hideDeleteModal()
  })

}

const handleSearch = (e) => {
  dispatch(staffListAction(e.target.value))
  updateState({...iState, search:e.target.value})
}

const showBlockModal = (_id, status, name) => {
  updateState({...iState, blockModal:true, modalId:_id, status:status, modalName:name})
}


const hideBlockModal = () => {
  updateState({...iState, blockModal:false, modalName:""})
}


const showUnblockModal = (_id, status, name) => {
  updateState({...iState, unblockModal:true, modalId:_id, status:status, modalName:name})
}


const hideUnblockModal = () => {
  updateState({...iState, unblockModal:false, modalName:""})
}


const showDeleteModal = (_id, name) => {
  updateState({...iState, deleteModal:true, modalId:_id, modalName:name})
}


const hideDeleteModal = () => {
  updateState({...iState, deleteModal:false, modalId:"",})
}
  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
    {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "staff-management" &&
                      ele.fullAccess == true
                  ) && (
                    <>
                     <div className="TitleBox">
                    <h4 className="Title">Sub-admin</h4>
                    <Link className="TitleLink" to="/staff-management-add">
                      Add New Staff
                    </Link>
                  </div>
                
                    </>
                   
                  )}
                </>
              ) : (
                <> 
                     <div className="TitleBox">
        <h4 className="Title">Sub-admin</h4>
        <Link className="TitleLink" to="/staff-management-add">
          Add New Staff
        </Link>
      </div>
     
                </>
              )}
      
      <div className="Filter">
                    <div className="form-group">
                      <label>Search</label>
                      <input type="text" className="form-control" placeholder="Search" onChange={handleSearch} />
                    </div>
                  </div>
                  
      <div className="TableList">
        <table style={{ width: "100%" }} ref={anchorRef}>
          <thead>
          <tr>
              <th>S. No</th>
              <th>Staff ID</th>
              <th>Staff Full Name</th>
              <th>Designation</th>
              <th>Email Address</th>
              <th>Registered On</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loader ? <>
            <Loader/>
            </>
            :

            checkArray(staffListData?.result?.[0]?.paginationData) ? 
            staffListData?.result?.[0]?.paginationData?.map((ele,i) => {           
              return(
                <>
                            <tr>
                            <td>{i + 1 + serialNo - 10}</td>
              <td>{ele?.staff_number}</td>
              <td>{ele?.name}</td>
              <td>{ele?.designation}</td>
              <td>{ele?.email}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              <td>
                {ele?.status === "ACTIVE"?  <span className="Green">Active</span> :  <span className="Red">Inactive</span>}
               
              </td>
              <td>

              {adminData?.userType == "STAFF" ? (
                <>
                  {adminData?.permission?.some(
                    (ele) =>
                      ele.name == "Staff-Management" &&
                      ele.fullAccess == true
                  ) && (
                    <>
                      <div className="Actions">
                {ele?.status === "ACTIVE" ? (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={true}
                                onChange={() =>
                                  showBlockModal(ele?._id, "INACTIVE", ele?.name)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          ) : (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={false}
                                onChange={() =>
                                  showUnblockModal(ele?._id, "ACTIVE",ele?.name)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          )}
                 
                  <Link className="Blue" to="/staff-details" state={ele?._id}>
                    <i className="fa fa-eye" />
                  </Link>
                  <Link className="Green" to="/staff-management-edit" state={ele?._id}>
                    <i className="fa fa-pencil" />
                  </Link>
                  <a
                    className="Red"
                    onClick={() => showDeleteModal(ele?._id,ele?.name)}
                  >
                    <i className="fa fa-trash" />
                  </a>
                </div>
                    </>
                   
                  )}
                </>
              ) : (
                <> 
                       <div className="Actions">
                {ele?.status === "ACTIVE" ? (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={true}
                                onChange={() =>
                                  showBlockModal(ele?._id, "INACTIVE", ele?.name)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          ) : (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={false}
                                onChange={() =>
                                  showUnblockModal(ele?._id, "ACTIVE", ele?.name)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          )}
                 
                  <Link className="Blue" to="/staff-details" state={ele?._id}>
                    <i className="fa fa-eye" />
                  </Link>
                  <Link className="Green" to="/staff-management-edit" state={ele?._id}>
                    <i className="fa fa-pencil" />
                  </Link>
                  <a
                    className="Red"
                    onClick={() => showDeleteModal(ele?._id,ele?.name)}
                  >
                    <i className="fa fa-trash" />
                  </a>
                </div>
                </>
              )}
      
             
              </td>
            </tr>

                </>
              )
            })
            : <p>No Data Found</p>
            }
           
           
           
          </tbody>
        </table>
      </div>
      <div class="pagination">
              <p>
                {" "}
                {/* Total records : <span>{staffListData?.result?.[0]?.totalCount?.[0]?.count}</span> */}
              </p>
              <ul>
                {staffListData?.result?.[0]?.totalCount?.[0]?.count && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={staffListData?.result?.[0]?.totalCount?.[0]?.count}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </ul>
            </div>
    
   
    </div>
  </div>
</div>





<Modal
        show={blockModal}
        onHide={hideBlockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideBlockModal}>
    ×
  </a>
  <h3>Are you sure you want to block {modalName} SubAdmin ? ? </h3>
  <div className="newBtn">
    <button
      className="Button active"
       onClick={() =>changeAnchorStatusFunc("ACTIVE")}
    >
      Yes
    </button>  &nbsp;  &nbsp; 
    <button className="Button" onClick={hideBlockModal}>
      No
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>


            
<Modal
        show={unblockModal}
        onHide={hideUnblockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideUnblockModal}>
    ×
  </a>
  <h3>Are you sure you want to unblock {modalName} SubAdmin ? </h3>
  <div className="newBtn">
    <button
      className="Button"
       onClick={() =>changeAnchorStatusFunc("INACTIVE")}
    >
      Yes
    </button> &nbsp; &nbsp;  &nbsp; &nbsp;
    <button className="Button" onClick={hideUnblockModal}>
      No
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>



      <Modal
        show={deleteModal}
        onHide={hideDeleteModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
     

<div className="Category">
  <button onClick={hideDeleteModal} className="CloseModal">×</button>
  <h3>Delete</h3>
  <figure>
    <img src="./static/media/deleteAlert.488dad788fffd1c04acd.png"   width={50}/>
  </figure>
  <p>Are you sure you want to delete this {modalName} SubAdmin  ?</p>
  <h4>
  <button className="Button" onClick={removeAnchorFunc}>Delete</button>   &nbsp;  &nbsp; 
    <button onClick={hideDeleteModal} className="Button">Cancel</button> 
   
  </h4>
</div>


        </Modal.Body>
      </Modal>
   </>
  )
}

export default Staffmanagment