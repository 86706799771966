import { errorToast } from "./toast"

export const checkTYpeImage=(type)=>{
    let arr=['png','jpg','jpeg']

    if(arr.includes(type)){
        return true
    }
    else{
        return false
    }
}
export const checkTYpePDF=(type)=>{
    if(type=="pdf"){
        return true
    }
    else{
        // errorToast("Please Select correct format")
        return
    }
}

export const checkTYpeVideo=(type)=>{
    if(type=="mp4"){
        return true
    }
    else{
        // errorToast("Please Select correct format")
        return
    }
}


export const checkArray=(type)=>{
    if(type !==undefined && type !== null &&type.length>0){
        return true
    }
    else{
        return false
    }
}

export function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }
//   <td>{index + 1 + serialNo - 10}</td>


  export function checkEmail(str) {
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(str);
  }

  export function checkPhone(str) {
    var re = /^[1-9][0-9]{9,14}$/;
    return re.test(str);
  }

  
  export function checkGst(str) {
    var re = /^[1-9][0-9]{14}$/;
    return re.test(str);
  }

  export function checkPan(str) {
    var re = /^[1-9][0-9]{9}$/;
    return re.test(str);
  }