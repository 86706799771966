import axios from "axios";
import { scoreManagementAction } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config";




export const overAllWeightageListInitiate = () => ({
    type: scoreManagementAction.OVERALL_WEIGHTAGE_LIST_INITIATE,
  });
  
  export const overAllWeightageListSuccess = (data) => ({
    type: scoreManagementAction.OVERALL_WEIGHTAGE_LIST_SUCCESS,
    payload: data,
  });
  
  export const overAllWeightageListFailure = (data) => ({
    type: scoreManagementAction.OVERALL_WEIGHTAGE_LIST_FAILURE,
    payload: data,
  });
  
  export function overAllWeightageListAction(id) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(overAllWeightageListInitiate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/anchorData?id=${id}`, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(overAllWeightageListSuccess(data));
             
            } else {
              dispatch(overAllWeightageListFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(overAllWeightageListFailure(err));
            reject(err);
          });
      });
    };
  }




  

export const ankorRiskListInitiate = () => ({
    type: scoreManagementAction.ANCHOR_RISK_LIST_INITIATE,
  });
  
  export const ankorRiskListSuccess = (data) => ({
    type: scoreManagementAction.ANCHOR_RISK_LIST_SUCCESS,
    payload: data,
  });
  
  export const ankorRiskListFailure = (data) => ({
    type: scoreManagementAction.ANCHOR_RISK_LIST_FAILURE,
    payload: data,
  });
  
  export function ankorRiskListAction(id) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(ankorRiskListInitiate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/anchorRiskData?anchorId=${id}`, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(ankorRiskListSuccess(data));
             
            } else {
              dispatch(ankorRiskListFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(ankorRiskListFailure(err));
            reject(err);
          });
      });
    };
  }


export const NonFinancialRiskInitiate = () => ({
    type: scoreManagementAction.NON_FINANCIAL_RISK_LIST_INITIATE,
  });
  
  export const NonFinancialRiskSuccess = (data) => ({
    type: scoreManagementAction.NON_FINANCIAL_RISK_LIST_SUCCESS,
    payload: data,
  });
  
  export const NonFinancialRiskFailure = (data) => ({
    type: scoreManagementAction.NON_FINANCIAL_RISK_LIST_FAILURE,
    payload: data,
  });
  
  export function NonFinancialRiskAction(id) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(NonFinancialRiskInitiate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/nonfinanceRiskDataAll?anchorId=${id}`, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(NonFinancialRiskSuccess(data));
             
            } else {
              dispatch(NonFinancialRiskFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("errrrrrrrr",err);
            if(err.response.data.code === 404){
              resolve(err);
              dispatch(NonFinancialRiskSuccess(err));
            }else{
              dispatch(NonFinancialRiskFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  


  export const addMoreNonFinancialInitiate = () => ({
    type: scoreManagementAction.ADD_MORE_NON_FINANCIAL_INITIATE,
  });
  
  export const addMoreNonFinancialSuccess = (data) => ({
    type: scoreManagementAction.ADD_MORE_NON_FINANCIAL_SUCCESS,
    payload: data,
  });
  
  export const addMoreNonFinancialFailure = (data) => ({
    type: scoreManagementAction.ADD_MORE_NON_FINANCIAL_FAILURE,
    payload: data,
  });
  
  export function addMoreNonFinancialAction(payload) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(addMoreNonFinancialInitiate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}admin/addMoreInNonFinanceRisk`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(addMoreNonFinancialSuccess(data));
             
            } else {
              dispatch(addMoreNonFinancialFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("errrrrrrrr",err);
            if(err.response.data.code === 404){
              resolve(err);
              dispatch(addMoreNonFinancialSuccess(err));
            }else{
              dispatch(addMoreNonFinancialFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  



  
  export const addMoreAnchorRiskInitiate = () => ({
    type: scoreManagementAction.ADD_MORE_ANCHOR_INITIATE,
  });
  
  export const addMoreAnchorRiskSuccess = (data) => ({
    type: scoreManagementAction.ADD_MORE_ANCHOR_SUCCESS,
    payload: data,
  });
  
  export const addMoreAnchorRiskFailure = (data) => ({
    type: scoreManagementAction.ADD_MORE_ANCHOR_FAILURE,
    payload: data,
  });
  
  export function addMoreAnchorRiskAction(id) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(addMoreAnchorRiskInitiate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}admin/addMoreInAnchorRisk`,id, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(addMoreAnchorRiskSuccess(data));
             
            } else {
              dispatch(addMoreAnchorRiskFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("errrrrrrrr",err);
            if(err.response.data.code === 404){
              resolve(err);
              dispatch(addMoreAnchorRiskSuccess(err));
            }else{
              dispatch(addMoreAnchorRiskFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  

  
export const removeOverAllWeightageInitiate = () => ({
  type: scoreManagementAction.REMOVE_OVERALL_INITIATE,
});

export const removeOverAllWeightageSuccess = (data) => ({
  type: scoreManagementAction.REMOVE_OVERALL_SUCCESS,
  payload: data,
});

export const removeOverAllWeightageFailure = (data) => ({
  type: scoreManagementAction.REMOVE_OVERALL_FAILURE,
  payload: data,
});

export function removeOverAllWeightageAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(removeOverAllWeightageInitiate());
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Url}admin/removeOverAllWeightage?id=${payload}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(removeOverAllWeightageSuccess(data));
           
          } else {
            dispatch(removeOverAllWeightageFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeOverAllWeightageFailure(err));
          reject(err);
        });
    });
  };
}




export const removeAnchorInitiate = () => ({
  type: scoreManagementAction.REMOVE_ANCHOR_RISK_INITIATE,
});

export const removeAnchorSuccess = (data) => ({
  type: scoreManagementAction.REMOVE_ANCHOR_RISK_SUCCESS,
  payload: data,
});

export const removeAnchorFailure = (data) => ({
  type: scoreManagementAction.REMOVE_ANCHOR_RISK_FAILURE,
  payload: data,
});

export function removeAnchorRiskAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(removeAnchorInitiate());
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Url}admin/deleteInAnchorRisk?id=${payload}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(removeAnchorSuccess(data));
           
          } else {
            dispatch(removeAnchorFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeAnchorFailure(err));
          reject(err);
        });
    });
  };
}




export const removeNonFinancialInitiate = () => ({
  type: scoreManagementAction.REMOVE_NON_FINANCIAL_INITIATE,
});

export const removeNonFinancialSuccess = (data) => ({
  type: scoreManagementAction.REMOVE_NON_FINANCIAL_SUCCESS,
  payload: data,
});

export const removeNonFinancialFailure = (data) => ({
  type: scoreManagementAction.REMOVE_NON_FINANCIAL_FAILURE,
  payload: data,
});

export function removeNonFinancialAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(removeNonFinancialInitiate());
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Url}admin/deleteInNonFinanceRisk?id=${payload}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(removeNonFinancialSuccess(data));
           
          } else {
            dispatch(removeNonFinancialFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeNonFinancialFailure(err));
          reject(err);
        });
    });
  };
}






export const updateOverallWeightInitiate = () => ({
  type: scoreManagementAction.UPDATE_OVERALL_INITIATE,
});

export const updateOverallWeightSuccess = (data) => ({
  type: scoreManagementAction.UPDATE_OVERALL_SUCCESS,
  payload: data,
});

export const updateOverallWeightFailure = (data) => ({
  type: scoreManagementAction.UPDATE_OVERALL_FAILURE,
  payload: data,
});

export function updateOverallManagementAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateOverallWeightInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editWeightage`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateOverallWeightSuccess(data));
           
          } else {
            dispatch(updateOverallWeightFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateOverallWeightFailure(err));
          reject(err);
        });
    });
  };
}




export const updateAnchorRiskInitiate = () => ({
  type: scoreManagementAction.UPDATE_ANCHOR_RISK_INITIATE,
});

export const updateAnchorRiskSuccess = (data) => ({
  type: scoreManagementAction.UPDATE_ANCHOR_RISK_SUCCESS,
  payload: data,
});

export const updateAnchorRiskFailure = (data) => ({
  type: scoreManagementAction.UPDATE_ANCHOR_RISK_FAILURE,
  payload: data,
});

export function updateAnchorRiskAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateAnchorRiskInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editInAnchorRisk`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateAnchorRiskSuccess(data));
           
          } else {
            dispatch(updateAnchorRiskFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateAnchorRiskFailure(err));
          reject(err);
        });
    });
  };
}




export const updateNonFinancialStaffInitiate = () => ({
  type: scoreManagementAction.UPDATE_NON_FINANCIAL_INITIATE,
});

export const updateNonFinancialStaffSuccess = (data) => ({
  type: scoreManagementAction.UPDATE_NON_FINANCIAL_SUCCESS,
  payload: data,
});

export const updateNonFinancialStaffFailure = (data) => ({
  type: scoreManagementAction.UPDATE_NON_FINANCIAL_FAILURE,
  payload: data,
});

export function updateNonFinancialAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateNonFinancialStaffInitiate());
    return new Promise((resolve, reject) => {
      // editInNonFinanceRisk
      axios
        .put(`${Url}admin/editValues`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateNonFinancialStaffSuccess(data));
           
          } else {
            dispatch(updateNonFinancialStaffFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateNonFinancialStaffFailure(err));
          reject(err);
        });
    });
  };
}




export const addMoreFinancialInitiate = () => ({
  type: scoreManagementAction.ADD_MORE_FINANCIAL_INITIATE,
});

export const addMoreFinancialSuccess = (data) => ({
  type: scoreManagementAction.ADD_MORE_FINANCIAL_SUCCESS,
  payload: data,
});

export const addMoreFinancialFailure = (data) => ({
  type: scoreManagementAction.ADD_MORE_FINANCIAL_FAILURE,
  payload: data,
});

export function addMoreFinancialAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(addMoreFinancialInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/addMoreFinanceRisk`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(addMoreFinancialSuccess(data));
           
          } else {
            dispatch(addMoreFinancialFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(addMoreFinancialSuccess(err));
          }else{
            dispatch(addMoreFinancialFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




// export const updateNonFinancialStaffInitiate = () => ({
//   type: scoreManagementAction.UPDATE_NON_FINANCIAL_INITIATE,
// });

// export const updateNonFinancialStaffSuccess = (data) => ({
//   type: scoreManagementAction.UPDATE_NON_FINANCIAL_SUCCESS,
//   payload: data,
// });

// export const updateNonFinancialStaffFailure = (data) => ({
//   type: scoreManagementAction.UPDATE_NON_FINANCIAL_FAILURE,
//   payload: data,
// });

export function updateFinancialAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    // editFinanceRisk
    // dispatch(updateNonFinancialStaffInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editValues`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
         //   dispatch(updateNonFinancialStaffSuccess(data));
           
          } else {
            // dispatch(updateNonFinancialStaffFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          // dispatch(updateNonFinancialStaffFailure(err));
          reject(err);
        });
    });
  };
}




export function removeFinancialAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    // dispatch(removeNonFinancialInitiate());
    return new Promise((resolve, reject) => {
      axios
        .delete(`${Url}admin/deleteFinanceRisk?id=${payload}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            // dispatch(removeNonFinancialSuccess(data));
           
          } else {
            // dispatch(removeNonFinancialFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          // dispatch(removeNonFinancialFailure(err));
          reject(err);
        });
    });
  };
}




export function updateAnchorValueAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/updateAnchorValue`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {       
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
