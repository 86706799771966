import axios from "axios";
import { anchorAction } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config";


export const addAnchorInitiate = () => ({
    type: anchorAction.ADD_ANCHOR_INITIATE,
  });
  
  export const addAnchorSuccess = (data) => ({
    type: anchorAction.ADD_ANCHOR_SUCCESS,
    payload: data,
  });
  
  export const addAnchorFailure = (data) => ({
    type: anchorAction.ADD_ANCHOR_FAILURE,
    payload: data,
  });
  
  export function addAnchorAction(payload) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(addAnchorInitiate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}admin/addAnchor`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(addAnchorSuccess(data));
             
            } else {
              dispatch(addAnchorFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(addAnchorFailure(err));
            reject(err);
          });
      });
    };
  }
  

  
export const anchorListInitiate = () => ({
    type: anchorAction.ANCHOR_LIST_INITIATE,
  });
  
  export const anchorListSuccess = (data) => ({
    type: anchorAction.ANCHOR_LIST_SUCCESS,
    payload: data,
  });
  
  export const anchorListFailure = (data) => ({
    type: anchorAction.ANCHOR_LIST_FAILURE,
    payload: data,
  });
  
  export function anchorListAction(search,startDate,endDate, timeFrame, pageNumber, limit) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(anchorListInitiate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/anchorList?search=${search? search:""}&startDate=${startDate ? startDate :""}&endDate=${endDate ? endDate :""}&timeframe=${timeFrame ? timeFrame:""}&page=${pageNumber ? pageNumber :""}&limit=${limit?limit:""}`, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(anchorListSuccess(data));
             
            } else {
              dispatch(anchorListFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("errrrrrrrr",err);
            if(err.response.data.code === 404){
              resolve(err);
              dispatch(anchorListSuccess(err));
            }else{
              dispatch(anchorListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  


  
export const removeAnchorInitiate = () => ({
  type: anchorAction.REMOVE_ANCHOR_INITIATE,
});

export const removeAnchorSuccess = (data) => ({
  type: anchorAction.REMOVE_ANCHOR_SUCCESS,
  payload: data,
});

export const removeAnchorFailure = (data) => ({
  type: anchorAction.REMOVE_ANCHOR_FAILURE,
  payload: data,
});

export function removeAnchorAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(removeAnchorInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/removeAnchor`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(removeAnchorSuccess(data));
           
          } else {
            dispatch(removeAnchorFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeAnchorFailure(err));
          reject(err);
        });
    });
  };
}




export const updateAnchorInitiate = () => ({
  type: anchorAction.UPDATE_ANCHOR_INITIATE,
});

export const updateAnchorSuccess = (data) => ({
  type: anchorAction.UPDATE_ANCHOR_SUCCESS,
  payload: data,
});

export const updateAnchorFailure = (data) => ({
  type: anchorAction.UPDATE_ANCHOR_FAILURE,
  payload: data,
});

export function updateAnchorAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateAnchorInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editAnchor`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateAnchorSuccess(data));
           
          } else {
            dispatch(updateAnchorFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateAnchorFailure(err));
          reject(err);
        });
    });
  };
}



export const anchorDetailsInitiate = () => ({
  type: anchorAction.ANCHOR_DETAILS_INITIATE,
});

export const anchorDetailsSuccess = (data) => ({
  type: anchorAction.ANCHOR_DETAILS_SUCCESS,
  payload: data,
});

export const anchorDetailsFailure = (data) => ({
  type: anchorAction.ANCHOR_DETAILS_FAILURE,
  payload: data,
});

export function anchorDetailsAction(id) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(anchorDetailsInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/anchorDetails/${id}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(anchorDetailsSuccess(data));
           
          } else {
            dispatch(anchorDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(anchorDetailsFailure(err));
          reject(err);
        });
    });
  };
}




export const changeAnchorStatusInitiate = () => ({
  type: anchorAction.ANCHOR_STATUS_CHANGE_INITIATE,
});

export const changeAnchorStatusSuccess = (data) => ({
  type: anchorAction.ANCHOR_STATUS_CHANGE_SUCCESS,
  payload: data,
});

export const changeAnchorStatusFailure = (data) => ({
  type: anchorAction.ANCHOR_STATUS_CHANGE_FAILURE,
  payload: data,
});

export function changeAnchorStatusAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(changeAnchorStatusInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/statusAnchor`,payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(changeAnchorStatusSuccess(data));
           
          } else {
            dispatch(changeAnchorStatusFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(changeAnchorStatusFailure(err));
          reject(err);
        });
    });
  };
}





export const userListInitiate = () => ({
  type: anchorAction.USER_LIST_INITIATE,
});

export const userListSuccess = (data) => ({
  type: anchorAction.USER_LIST_SUCCESS,
  payload: data,
});

export const userListFailure = (data) => ({
  type: anchorAction.USER_LIST_FAILURE,
  payload: data,
});

export function userListAction(search) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(userListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/userList?search=${search? search:""}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {         
          const data = response.data;
          if (response.status && response.status === 200) {
            resolve(data);
            dispatch(userListSuccess(data));
           
          } else {
            dispatch(userListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(userListSuccess(err));
          }else{
            dispatch(userListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const userApprovedInitiate = () => ({
  type: anchorAction.USER_APPROVED_INITIATE,
});

export const userApprovedSuccess = (data) => ({
  type: anchorAction.USER_APPROVED_SUCCESS,
  payload: data,
});

export const userApprovedFailure = (data) => ({
  type: anchorAction.USER_APPROVED_FAILURE,
  payload: data,
});

export function userApprovedAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(userApprovedInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/approvedUser`,payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(userApprovedSuccess(data));
           
          } else {
            dispatch(userApprovedFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(userApprovedSuccess(err));
          }else{
            dispatch(userApprovedFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const updateAccessInitiate = () => ({
  type: anchorAction.USER_APPROVED_INITIATE,
});

export const updateAccessSuccess = (data) => ({
  type: anchorAction.USER_APPROVED_SUCCESS,
  payload: data,
});

export const updateAccessFailure = (data) => ({
  type: anchorAction.USER_APPROVED_FAILURE,
  payload: data,
});

export function updateAccessAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateAccessInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editAccessAnhor`,payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateAccessSuccess(data));
           
          } else {
            dispatch(updateAccessFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(userApprovedSuccess(err));
          }else{
            dispatch(userApprovedFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const anchorDropdownInitiate = () => ({
  type: anchorAction.ANCHOR_DROPDOWN_INITIATE,
});

export const anchorDropdownSuccess = (data) => ({
  type: anchorAction.ANCHOR_DROPDOWN_SUCCESS,
  payload: data,
});

export const anchorDropdownFailure = (data) => ({
  type: anchorAction.ANCHOR_DROPDOWN_FAILURE,
  payload: data,
});

export function anchorDropdownAction(search) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(anchorDropdownInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/anchorDropdown`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(anchorDropdownSuccess(data));
           
          } else {
            dispatch(anchorDropdownFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(anchorDropdownSuccess(err));
          }else{
            dispatch(anchorDropdownFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const userDetailsInitiate = () => ({
  type: anchorAction.USER_DETAILS_INITIATE,
});

export const userDetailsSuccess = (data) => ({
  type: anchorAction.USER_DETAILS_SUCCESS,
  payload: data,
});

export const userDetailsFailure = (data) => ({
  type: anchorAction.USER_DETAILS_FAILURE,
  payload: data,
});

export function userDetailsAction(id) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(userDetailsInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/userDetails/${id}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(userDetailsSuccess(data));
           
          } else {
            dispatch(userDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(userDetailsSuccess(err));
          }else{
            dispatch(userDetailsFailure(err));
            reject(err);
          }
         
        });
    });
  };
}



 
export const changeUserStatusInitiate = () => ({
  type: anchorAction.CHANGE_USER_STATUS_INITIATE,
});

export const changeUserStatusSuccess = (data) => ({
  type: anchorAction.CHANGE_USER_STATUS_SUCCESS,
  payload: data,
});

export const changeUserStatusFailure = (data) => ({
  type: anchorAction.CHANGE_USER_STATUS_FAILURE,
  payload: data,
});

export function changeUserStatusAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(changeUserStatusInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/userStatusUpdate`,payload,{
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(changeUserStatusSuccess(data));
           
          } else {
            dispatch(changeUserStatusFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(changeUserStatusSuccess(err));
          }else{
            dispatch(changeUserStatusFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const updateUserListInitiate = () => ({
  type: anchorAction.UPDATE_USER_LIST_INITIATE,
});

export const updateUserListSuccess = (data) => ({
  type: anchorAction.UPDATE_USER_LIST_SUCCESS,
  payload: data,
});

export const updateUserListFailure = (data) => ({
  type: anchorAction.UPDATE_USER_LIST_FAILURE,
  payload: data,
});

export function updateUserListAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateUserListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editAccessAnhor`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateUserListSuccess(data));
           
          } else {
            dispatch(updateUserListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateUserListFailure(err));
          reject(err);
        });
    });
  };
}






export const userAnchorListInitiate = () => ({
  type: anchorAction.ANCHOR_USER_LIST_INITIATE,
});

export const userAnchorListSuccess = (data) => ({
  type: anchorAction.ANCHOR_USER_LIST_SUCCESS,
  payload: data,
});

export const userAnchorListFailure = (data) => ({
  type: anchorAction.ANCHOR_USER_LIST_FAILURE,
  payload: data,
});

export function userAnchorListAction(search, pageNumber, limit) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(userAnchorListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/totalAnchorUserList?search=${search? search:""}&page=${pageNumber ? pageNumber :""}&limit=${limit ? limit :""}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(userAnchorListSuccess(data));
           
          } else {
            dispatch(userAnchorListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(userAnchorListFailure(err));
          reject(err);
        });
    });
  };
}