import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  changeRatingStatusAction,
  clientDetailsAction,
  downloadReportAction,
  getNonFinanceAction,
  updateNonFinancialManagementAction,
} from "../redux/actions/clientAction";
import Loader from "./Loader";
import { checkArray } from "../utils/CheckType";
import { sucessToast } from "../utils/toast";
import { Modal } from "react-bootstrap";
import { saveAs } from "file-saver";
import { usePDF } from "react-to-pdf";
// import ReactPDF from '@react-pdf/renderer';
import Clientfinancialrating from "./client-financial-rating";
const init = {
  disabledState: true,
  submitRatingModal: false,
};
function CientNonfinancialRating() {
  // let loader = false;
  const [data, setData] = useState([]);
  const [clientDetailsData, setClientDetailsData] = useState([]);
  const [iState, updateState] = useState(init);
  const { disabledState, submitRatingModal } = iState;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    dispatch(getNonFinanceAction(state?.anchorId));
  }, []);
  const { toPDF, targetRef } = usePDF({ filename: "NonFinancialRating.pdf" });
  const { anchorId, clientId } = useParams();
  console.log("state", state);
  console.log("clientDetailsData", clientDetailsData);
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const res = await dispatch(clientDetailsAction(state?._id, state?.anchorId));
  
       
  
        const updatedClientDetails = res?.result;

        setClientDetailsData(updatedClientDetails);
      } catch (error) {
        console.error("Error fetching client details:", error);
        // Optionally, you can handle errors here, e.g., show an error toast
      }
    };
  
    fetchClientDetails();
  }, [dispatch, state?._id, state?.anchorId]);
  
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 3;
  let pathMatch = path[pathLength];
  const { nonFinancialDetailsData, nonFinancialRiskListData, loader } =
    useSelector((state) => state.mainReducer);
    
 
    
    const showSubmitRatingModal = () => {
      updateState({ ...iState, submitRatingModal: true });
    };
    
    const hideSubmitRatingModal = () => {
      updateState({ ...iState, submitRatingModal: false });
    };
    
    const addNonFinancialDataFunc = () => {
      let data1 = {
        anchorId: state?.anchorId,
        clientId: state?._id,
        nonFinanceRatingData: clientDetailsData?.[0]?.nonfinanceratingsData,
      };
      dispatch(updateNonFinancialManagementAction(data1)).then((res) => {
      if(res.code===200){
        sucessToast("Non finance rating updated successfully");
        updateState({ ...iState, disabledState: !disabledState,submitRatingModal: false  });
   dispatch(clientDetailsAction(state?._id, state?.anchorId)).then((resonse)=>{
    const updatedClientDetails = resonse?.result;
    setClientDetailsData(updatedClientDetails);
   })}

    });
  };
  
  const downloadRatingFunc = () => {
    let financeData = clientDetailsData?.[0]?.financeratingsData?.map((ele, i) => {
      // // // console.log(ele)
      return {
        parameterName: ele?.parameterName,
        range: ele?.range,
        score: ele?.score,
        value: ele?.value,
      };
    });
    
    let businessNonFinacePdfData =
    clientDetailsData?.[0]?.nonfinanceratingsData?.map((ele, i) => {
      if (ele?.outsideTitle === "Business Risk parameters") {
        //  console.log("elelelelelelle",ele)
        return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });
      
      let accountNonFinacePdfData = clientDetailsData?.[0]?.nonfinanceratingsData?.map(
      (ele, i) => {
        // console.log("Elelelelelel",ele)
        if (ele?.outsideTitle === "Account Conduct Risk parameters") {
          console.log("elelelelelelle", ele);
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      }
    );
    
    let managementNonFinacePdfData =
    clientDetailsData?.[0]?.nonfinanceratingsData?.map((ele, i) => {
        if (ele?.outsideTitle === "Management Risk parameters") {
          // console.log("elelelelelelle",ele)
          return {
            parameterName: ele?.parameterName,
            range: ele?.range,
            score: ele?.score,
          };
        } else {
          return null;
        }
      });
      
      let anchorData = clientDetailsData?.[0]?.anchorRatingData?.map((ele, i) => {
        return {
          parameterName: ele?.parameterName,
          range: ele?.range,
        score: ele?.score,
      };
    });

    let anchorRiskData = clientDetailsData?.[0]?.overallRatingAnchorRisk
    ? clientDetailsData?.[0]?.overallRatingAnchorRisk.toFixed(2)
    : 0;
    let financeRiskData = clientDetailsData?.[0]?.overallRatingFinanceRisk
    ? clientDetailsData?.[0]?.overallRatingFinanceRisk.toFixed(2)
    : 0;
    let businessRiskData = clientDetailsData?.[0]?.overallRatingBuisnessRisk
      ? clientDetailsData?.[0]?.overallRatingBuisnessRisk.toFixed(2)
      : 0;
      let managementRiskData = clientDetailsData?.[0]?.overallRatingManagementRisk
      ? clientDetailsData?.[0]?.overallRatingManagementRisk.toFixed(2)
      : 0;
      let accountRiskData = clientDetailsData?.[0]?.overallRatingAccountRisk
      ? clientDetailsData?.[0]?.overallRatingAccountRisk.toFixed(2)
      : 0;
      let overAllRiskData = clientDetailsData?.[0]?.overallRating
      ? clientDetailsData?.[0]?.overallRating.toFixed(2)
      : 0;
    let categoryColor =
      clientDetailsData?.[0]?.overallRating < 2
        ? "Green"
        : clientDetailsData?.[0]?.overallRating < 3
        ? "Yellow"
        : clientDetailsData?.[0]?.overallRating < 4
        ? "Orange"
        : clientDetailsData?.[0]?.overallRating < 5
        ? "Red"
        : "";

        let data = {
      clientId: clientDetailsData?.[0]?._id,
      financeData,
      businessNonFinacePdfData: businessNonFinacePdfData?.filter(
        (item) => item !== null
      ),
      accountNonFinacePdfData: accountNonFinacePdfData?.filter(
        (item) => item !== null
      ),
      managementNonFinacePdfData: managementNonFinacePdfData?.filter(
        (item) => item !== null
      ),
      
      // nonFinanceData,
      anchorData,
      anchorRiskData,
      financeRiskData,
      businessRiskData,
      managementRiskData,
      accountRiskData,
      overAllRiskData,
      categoryColor,
    };
    dispatch(downloadReportAction(data)).then((res) => {
      // // // console.log("resssssssss",res)
      window.open(res.link, "_blank");
      // const blob = new Blob([data], { type: 'application/pdf' })
      //   saveAs(blob, "tickets.pdf")
      // href={
      //   studentHelpdetails?.askedRequest
      //     ?.tutorAttachDocument?.[0]?.link
      // }
      // target="_self"
    });
  };
  
  const handleInputChangeComplex = (e, type, i) => {
    const { name, value } = e.target;
    console.log({ name, value,i });
     if (type === "auto") {
      
       const cloneClientsData = JSON.parse(JSON.stringify(clientDetailsData));
       const dummyScore = cloneClientsData[0]?.nonfinanceratingsData?.[
         i
       ]?.nonFinanceratingsDataValue?.find((item) => item._id == value);
       if (dummyScore) {
         cloneClientsData[0].nonfinanceratingsData[i].score = dummyScore.score;
         cloneClientsData[0].nonfinanceratingsData[i].range = dummyScore.range;
       }
       
       setClientDetailsData(cloneClientsData);
     }
      else {
       const cloneClientsData = JSON.parse(JSON.stringify(clientDetailsData));
       cloneClientsData[0].nonfinanceratingsData[i].score = value;
       setClientDetailsData(cloneClientsData);
     }
  };
  

  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">
              {/* {state?.UrlLink === "pending" ?<Link to={`/client-scoring-details/${clientId}`}  state={state} className="backArrow">
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </Link> :  */}
              <a onClick={() => navigate(-1)} className="backArrow">
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </a>
              {/* } */}
              View Score
            </h4>
            {/* <div className="">
        <a   onClick={() => handleStatus()} className="Button">
          Approve
        </a>
        <a onClick={() => handleStatus()} className="Button">
          Disapprove
        </a>
      </div> */}
          </div>
          <div className="Small-Wrapper">
            <div className="CommonLinks">
              <ul>
                <li
                  className={
                    pathMatch == "client-financial-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-financial-rating" state={state}>
                    Financial Rating
                  </Link>
                </li>
                <li
                  className={
                    pathMatch == "client-nonfinancial-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-nonfinancial-rating" state={state}>
                    Non-Financial Rating
                  </Link>
                </li>
                <li
                  className={
                    pathMatch == "client-anchor-rating" ? "active" : ""
                  }
                >
                  <Link to="/client-anchor-rating" state={state}>
                    Anchor Rating
                  </Link>
                </li>
              </ul>
             
                  <div className="d-flex align-items-center">
                   
                      <a
                        onClick={() =>
                          updateState({
                            ...iState,
                            disabledState: !disabledState,
                          })
                        }
                        className="Green"
                      >
                        <i className="fa fa-pencil" />
                      </a>
                   
                  </div>
              
              {/* <div className='d-flex align-items-center'>
          {disabledState ?  <a onClick={() => updateState({...iState,disabledState:!disabledState })} className="Green">
          <i className="fa fa-pencil" />
        </a> : <></>} */}

              <a onClick={downloadRatingFunc} className="Button active ml-3">
                Download PDF
              </a>
            </div>
            {disabledState ? (
  loader ? (
    <Loader />
  ) : checkArray(clientDetailsData?.[0]?.nonfinanceratingsData) ? (
    clientDetailsData?.[0]?.nonfinanceratingsData
      .reduce((result, currentObj) => {
        const group = result.find(
          (group) => group.name === currentObj.outsideTitle
        );
        if (group) {
          group.data.push(currentObj);
        } else {
          result.push({
            name: currentObj.outsideTitle,
            data: [currentObj],
          });
        }
        return result;
      }, [])
      .map((ele, i) => {
        return (
          <div key={i}>
            <div className="TopTittle">
              <h4>{ele?.name}</h4>
            </div>
            <div className="TableList TableFinance">
              <table ref={targetRef} style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>Range</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(ele?.data) &&
                    ele?.data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.parameterName}</td>
                          <td>
                            <div className="form-group">
                              <input
                                type="text"
                                name="range"
                                defaultValue={item?.range}
                                disabled={true}
                                className="form-control"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="text"
                                name="score"
                                defaultValue={item?.score}
                                disabled={true}
                                className="form-control"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })
  ) : (
    <p>No Data Found</p>
  )
) : loader ? (
  <Loader />
) : (
  (() => {
    const groupedData = checkArray(clientDetailsData?.[0]?.nonfinanceratingsData)
      ? clientDetailsData?.[0]?.nonfinanceratingsData
          ?.map((item, index) => ({ ...item, originalIndex: index })) // Add original index
          .reduce((result, currentObj) => {
            const group = result?.find(
              (group) => group.name === currentObj.outsideTitle
            );
            if (group) {
              group.data.push(currentObj);
            } else {
              result.push({
                name: currentObj.outsideTitle,
                data: [currentObj],
              });
            }
            return result;
          }, [])
      : [];

    return groupedData?.length > 0 ? (
      groupedData?.map((ele, i) => {
        return (
          <div key={i}>
            <div className="TopTittle">
              <h4>{ele?.name}</h4>
            </div>
            <div className="TableList TableFinance">
              <table ref={targetRef} style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>Range</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(ele?.data) &&
                    ele?.data?.map((item) => {
                      return (
                        <tr key={item.originalIndex}>
                          <td>{item?.parameterName}</td>
                          <td>
                            <div className="form-group">
                              <select
                                className="form-control"
                                name="range"
                                onChange={(e) =>
                                  handleInputChangeComplex(
                                    e,
                                    "auto",
                                    item.originalIndex
                                  )
                                }
                              >
                                {checkArray(item?.nonFinanceratingsDataValue) &&
                                  item?.nonFinanceratingsDataValue?.map(
                                    (ele, i) => {
                                      return (
                                        <option
                                          value={ele?._id}
                                          key={i}
                                          selected={
                                            item.range === ele.range
                                          }
                                        >
                                          {ele?.range}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="number"
                                name="score"
                                value={item.score}
                                onChange={(e) =>
                                  handleInputChangeComplex(
                                    e,
                                    "manual",
                                    item.originalIndex
                                  )
                                }
                                className="form-control"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })
    ) : (
      <p>No Data Found</p>
    );
  })()
)}

          </div>
          {disabledState === false ? (
            <div className="newBtn">
              <button className="Button" onClick={showSubmitRatingModal}>
                Submit financial rating
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Modal
        show={submitRatingModal}
        onHide={hideSubmitRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={hideSubmitRatingModal}
            >
              ×
            </a>
            <h3>Are you sure you want to submit the ratings? </h3>
            <div className="newBtn">
              <button
                className="Button active"
                onClick={addNonFinancialDataFunc}
              >
                Yes,Submit
              </button>{" "}
              &nbsp; &nbsp;
              <button className="Button" onClick={hideSubmitRatingModal}>
                No,Review
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CientNonfinancialRating;
