import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFinanceListAction,
  getScoreAction,
} from "../redux/actions/clientAction";
import { checkArray } from "../utils/CheckType";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { usePDF } from "react-to-pdf";
import NoDataFound from "./NoDataFound";
const init = {
  rangeId: "",
  submitRatingModal:false,
  disabledState:true,
  downloadModal:false,
  modalName:""
};
function Clientscoringfinancial() {
  const [iState, updateState] = useState(init);
  const { rangeId ,submitRatingModal,disabledState, modalName,downloadModal} = iState;
  const financeRatingData = JSON.parse(localStorage.getItem("financeratingData"))
  console.log("financerating", financeRatingData)
  const navigate = useNavigate()
  const{id} = useParams()
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const { toPDF, targetRef } = usePDF({ filename: "FinancialRating.pdf" });
  // const [scoreData, setscoreData] = useState([]);
  const { financeRiskListData, loader } = useSelector(
    (state) => state.mainReducer
  );
 

  const dispatch = useDispatch();
  const {  state} = useLocation();
  console.log("state: " , state);
  
  useEffect(() => {
    // dispatch(getFinanceListAction());
  }, []);

  const handleInput = (e,ele, index) => {
    const { name, value } = e.target;

    const res=financeRiskListData?.result.find((data)=>data._id===ele._id).financeRiskData.find(data => data._id===value)
     console.log("ressssssss", res)
    let newData = [...data];
    // let newDownloadData = [...downloadData];
    
    //  newDownloadData[index] = { range: res?.range, score: res?.score, parameter: res?._id };
    newData[index] = { range: res?.range, score: res?.score, valueId: res?._id };
    //
    // setData(prev=>([...prev,{ range:res?.range, score:res?.score, valueId:res?._id}]));
setData(newData)
    updateState({ ...iState, [name]: value });
  };

  // useEffect(() => {
  //   if (rangeId) {
  //     dispatch(getScoreAction(rangeId)).then((res) => {
  //       console.log(scoreData, "SSSSSSSSSSSSSSSSS");
  //       setscoreData((prev) => [...prev, res?.addData?.score]);
  //     });
  //   }
  // }, [rangeId]);







  useEffect(() => {
    if(financeRiskListData){
      financeRiskListData?.result?.forEach(obj => {
        // obj.financeRiskData.forEach(item => {  
          
             
        setData(prev=>
          {
            
            let jsonObject = prev.map(obj=>JSON.stringify(obj));
            let uniqueSet = new Set(jsonObject);
            const clientDataArray2 = Array.from(uniqueSet).map(JSON.parse);
            console.log("databaseeee",clientDataArray2)    
            return ([...clientDataArray2,{ range:obj?.financeRiskData?.[0]?.range, score:obj?.financeRiskData?.[0]?.score, valueId:obj?.financeRiskData?.[0]?._id}]);
          })

        // setData(prev=>([...prev,{ range:obj?.financeRiskData?.[0]?.range, score:obj?.financeRiskData?.[0]?.score, valueId:obj?.financeRiskData?.[0]?._id}]));
          
        // });
    });

    }
  },[financeRiskListData ])

  const addFinacialDataFunc = () => {
    let dataP = {financeRatindData:data,state } 
    setData([])        
    navigate("/client-scoring-non-financial", { state: { ...state, dataP } });

        
  }


  const showSubmitRatingModal = () => {
    updateState({...iState, submitRatingModal:true });
  }

  const hideSubmitRatingModal = () => {
    updateState({...iState, submitRatingModal:false });
  }





  const goToNonFinancePage = () => {
    let dataP = {financeRatindData:data,state }   
    setData([]) 
    navigate("/client-scoring-non-financial", { state: { ...state, dataP } });

  
  }
 
  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">
              <Link to="/client-start-scoring" className="backArrow" state={state}>
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </Link>{" "}
              Client Management
            </h4>
          </div>
          <div className="Small-Wrapper">
            <div className="CommonLinks">
              <ul>
                <li className="active">
                  <Link state={state} to="/client-scoring-financial">Financial Rating</Link>
                </li>
                <li>
                  <a onClick={goToNonFinancePage} state = {state}>
                    Non-Financial Rating
                  </a>
                </li>
              </ul>
              {/* <a className="Green">
                <i className="fa fa-pencil" />
              </a> */}
            </div>
            <div className="TableList TableFinance">
              <table ref={targetRef} style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>Range</th>
                    <th>Score</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? <><Loader/></> : checkArray(financeRatingData) ? 
                    financeRatingData?.map((ele,i) => {
                      return(
                        <tr>
                        <td>{ele?.parameterName}</td>
                        <td>{ele?.range}</td>
                        <td>{ele?.score}</td>
                        <td>{Number(state?.reportData?.filter(item => item?.parameter  == ele?.parameterName )[0]?.value).toFixed(2)}</td>
                      </tr>
                      )
                    })
                  : 
                  <>
                  <NoDataFound/>
                  </>}
                 
                </tbody>
                {/* <tbody>
                  {loader ? (
                    <>
                      <Loader />
                    </>
                  ) :
                   checkArray(financeRiskListData?.result) ? (
                    financeRiskListData?.result?.map((ele, i) => {
                    
                      console.log("elelelelleelee", state?.reportData?.filter(item => item?.parameter  == ele?.title )[0]?.value)
                      return (
                        <>
                          <tr>
                            <td>{ele?.title}</td>
                            <td>
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  name={ele._id + "rangeId"}
                                  value={iState[ele._id + "rangeId"]}
                                  onChange={(e)=>handleInput(e,ele, i)}
                                 
                                >
                                  {checkArray(ele?.financeRiskData) &&
                                    ele?.financeRiskData?.map((child, i) => {

                                      return (
                                        <option value={child?._id}>
                                          {child?.range}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="text"
                                  placeholder={5}
                                  className="form-control"
                                  value={ele.financeRiskData.reduce(
                                    (acc, cur) => {
                                      if (
                                        cur._id === iState[ele._id + "rangeId"]
                                      ) {
                                        return (acc += cur.score);
                                      }
                                      return acc;
                                    },
                                    0
                                  )}
                                  disabled 
                                />
                              </div>
                            </td>
                            
                            <td>{state?.reportData?.filter(item => item?.parameter  == ele?.title )[0]?.value ?? state?.state?.reportData?.filter(item => item?.parameter  == ele?.title )[0]?.value ?? state?.state?.state?.reportData?.filter(item => item?.parameter  == ele?.title )[0]?.value}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <p>No data found</p>
                  )}
                </tbody> */}
              </table>
            </div>
          </div>
          <div className="newBtn">
          
            <button
              className="Button"
              onClick={showSubmitRatingModal}
            >
              Submit financial rating
            </button>
          </div>
        </div>
      </div>



            
<Modal
        show={submitRatingModal}
        onHide={hideSubmitRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideSubmitRatingModal}>
    ×
  </a>
  {/* modalName */}
  <h3>Are you sure you want to submit the ratings? </h3>
  <div className="newBtn d-flex">
    
    <button
      className="Button active"
      onClick={addFinacialDataFunc}
    >
      Yes,Submit
    </button>  &nbsp; &nbsp;
    <button
      className="Button active"
      onClick={() => toPDF()}
    >
      Download Rating
    </button>  &nbsp; &nbsp;
    <button className="Button" onClick={hideSubmitRatingModal}>
      No,Review
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>



            
      <Modal
        show={submitRatingModal}
        onHide={hideSubmitRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideSubmitRatingModal}>
    ×
  </a>
  {/* modalName */}
  <h3>Are you sure you want to submit the ratings? </h3>
  <div className="newBtn d-flex">
    
    <button
      className="Button active"
      onClick={addFinacialDataFunc}
    >
      Yes,Submit
    </button>  &nbsp; &nbsp;
    <button
      className="Button active"
      onClick={() => toPDF()}
    >
      Download Rating
    </button>  &nbsp; &nbsp;
    <button className="Button" onClick={hideSubmitRatingModal}>
      No,Review
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>


              
      <Modal
        show={submitRatingModal}
        onHide={hideSubmitRatingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideSubmitRatingModal}>
    ×
  </a>
  {/* modalName */}
  <h3>Are you sure you want to submit the ratings? </h3>
  <div className="newBtn d-flex">
    
    <button
      className="Button active"
      onClick={addFinacialDataFunc}
    >
      Yes,Submit
    </button>  &nbsp; &nbsp;
    {/* <button
      className="Button active"
      onClick={() => toPDF()}
    >
      Download Rating
    </button>  &nbsp; &nbsp; */}
    <button className="Button" onClick={hideSubmitRatingModal}>
      No,Review
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>


    </>
  );
}

export default Clientscoringfinancial;
