import React, { useState } from 'react'
import "../App.css";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorToast, sucessToast } from '../utils/toast';
import { adminLoginAction } from '../redux/actions/authAction';
import { checkEmail, checkPassword } from '../utils/CheckType';
const init = {
  email:"",
  password:"", 
  loginError:{}
}
const Login = () => {
   const[iState, updateState] = useState(init);
   const {email, password, loginError} = iState
   const [showNewPassword, setshowNewPassword] = useState(true);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const passwordVisibility = () => {
    if (showNewPassword) {
      setshowNewPassword(false);
    } else {
      setshowNewPassword(true);
    }
  };
   const handleValidation = () => {
       let formIsValid = true;
       let error = {};

       if (!email || !email.trim()) {
        error.emailError = " * Email id can't be empty";
        formIsValid = false;
      } else {
        if (!/^.+?@.+?\..+$/.test(email)) {
          error.emailError = " * Email format is not valid";
          formIsValid = false;
        }
      }

       if(!password){
        error.passwordError = "Password can't be empty";
        formIsValid = false
       }

      //  if(password){
      //   if(!checkPassword(password)){
      //     error.passwordError = "your password is not strong."
      //     // formIsValid = false
      //   }
      //  }

       updateState({...iState, loginError:error})
       return formIsValid
   }


   const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let loginForm = handleValidation();
    if (loginForm) {
      const data = {
        email:email.trim(),
        password,
        // deviceToken
      };
      dispatch(adminLoginAction(data))
        .then((res) => {
          console.log("ressss",res)
          if (res.code === 200) {
            console.log("rsssssss",res?.activeUser?.userType)
            sucessToast("Login successfully")
            res?.activeUser?.userType == "STAFF" ? 
            navigate("/subadmin-dashboard")
           
             : navigate("/dashboard")
          } 
            
        })
        .catch((err) => {
          console.log("errr",err.response.data.message)
          if(err?.response.status == 401) {
            console.log("=========>", err)
            errorToast(err.response.data.message)
          }       
        });
    }
  };
  return (
    <div>
        <div className="LoginArea">
  <div className="LoginBox">
    <div className="LoginLeft">
      <h2>
        SG <span>Finserve</span>
      </h2>
      <h3>
        <span>Admin Login</span>
      </h3>
      <form>
        <div className="form-group">
          <label>Enter your email address</label>
          <input
            type="text"
            placeholder="Enter Email ID"
            className="form-control"
            name='email'
            value={email}
            onChange={handleInput}
          />
         
          <span className="Icon">
            <i className="fa fa-envelope" />
          </span>
        </div>
        <span style={{color:"red", fontSize:"14px"}}>{loginError?.emailError}</span>
        <div className="form-group">
          <label>Enter your password</label>
          <input
             type={showNewPassword ? "password" : "text"}
            placeholder="Enter Password"
            className="form-control"
            name='password'
            value={password}
            onChange={handleInput}
          />
          {showNewPassword ? 
           <span onClick={passwordVisibility} className="Icon">
           <i className="fa fa-eye"></i>
         </span>
         :
         <span onClick={passwordVisibility} className="Icon">
         <i className="fa fa-unlock-alt" />
       </span>
        }
          
       
         
         
        </div>
        <span style={{color:"red", fontSize:"14px"}}>{loginError?.passwordError}</span>
        <div className="Checkboxs">
          <label className="CheckBox">
            {/* Remember Me
            <input type="checkbox" />
            <span className="checkmark" /> */}
          </label>
          <Link to="/login-forgot">Forgot password?</Link>
        </div>
        <a onClick={handleLogin} className="Button" >
          Log In <i className="fa fa-sign-in" />
        </a>
        {/* <button>Log In <i class="fa fa-sign-in"></i></button> */}
      </form>
    </div>
    <div className="LoginRight">
      <figure>
        <img src="images/logo.png" />
      </figure>
      <h5>
        Let's Started <span>SG Finserve</span>
      </h5>
      {/* <h6>To keep connected wit us please login .</h6> */}
    </div>
  </div>
</div>

    </div>
  )
}

export default Login