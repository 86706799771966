
export function multiPartData(data, type = "") {
  console.log('=datadata==>',data)
  let multiPart = new FormData();
  for (let key in data) {
   
    if (key === "file" || key === "images") {
      if (Array.isArray(data[key])) {
        data[key].forEach((file, index) => {
          multiPart.append(`${key}_${index}`, file);
        });
      } else {
        multiPart.append(key, data[key]); // Handle a single file case
      }
    } 
    else if ( key == "purchaseDetails") {
      // console.log("------>", data, data[key], key)
      data[key].forEach((obj, index) => {
        console.log("------>", obj, )
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`purchaseDetails[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
    else if (key == "entryAccount") {
      // console.log("------>", data, data[key], key)
      data[key].forEach((obj, index) => {
        console.log("------>", obj, )
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`entryAccount[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }

    else if (key == "warrantyNotes") {
      data[key].forEach((obj, index) => {
        console.log("------>", obj, )
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`warrantyNotes[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
  
  
    else if (key == "assignStaffs") {
      // console.log("------>", data, data[key], key)
      data[key].forEach((obj, index) => {
        console.log("------>", obj, )
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`assignStaffs[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
   else if (key == "assignTrade" || key === "toDoNotes") {
    const assignTradeKeys = Object.keys(data[key]);
      assignTradeKeys.forEach(subKey => {
        multiPart.append(`${key}[${subKey}]`, data[key][subKey]);
      });

     
    }
    else {
      multiPart.append(key, data[key]);
    }
  } 

  return multiPart;
}

export function calculateDateDifference(input) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime()+ 24 * 60 * 60 * 1000 * Number(input));
  return futureDate.toISOString().slice(0, -1);
}

export function convertToISOFormat(readableDate) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime(readableDate) )
  //  + 24 * 60 * 60 * 1000 * Number(input));
  return futureDate.toISOString().slice(0, -1);
}


export function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${year}-${month}-${date}`;
}

// export const bucketConfig = {
//   bucketName: "auuction",
//   dirName: "photo",
//   region: "us-east-1",
//   accessKeyId: "AKIAU4GHOSL2BYOEG7UV",
//   secretAccessKey: "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",
// };

export const bucketConfig = {
  bucketName: "mobileapplications",
  dirName: "photo",
  region: "ap-south-1",
  accessKeyId: "AKIAQKGGXOAWWA6VE233",
  secretAccessKey: "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",
};

