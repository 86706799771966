// export const Url = "http://18.226.165.55:5555/api/v1/";

module.exports = {
  // Url : "https://soft.sgfinserve.com:4500/api/v1/"
  // Url : "https://sgfinserve.com:4500/api/v1/"
  // Url : "http://3.13.196.242:5555/api/v1/"
  // Url:"http://192.168.3.115:3000/api/v1/"
  Url : "https://sgfinserve.in:4500/api/v1/"
  // Url : "http://15.206.180.155:4500/api/v1/"
  // Url : "http://15.206.180.155:4500/api/v1/"
  // Url:"https://sgfinserve.in:4500/api/v1/"
    
    // 192.168.3.115
  }
  
