import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import { useDispatch, useSelector } from "react-redux";
import {
  NonFinancialRiskAction,
  addMoreAnchorRiskAction,
  addMoreFinancialAction,
  addMoreNonFinancialAction,
  ankorRiskListAction,
  overAllWeightageListAction,
  removeAnchorAction,
  removeAnchorRiskAction,
  removeFinancialAction,
  removeNonFinancialAction,
  updateAnchorRiskAction,
  updateAnchorValueAction,
  updateFinancialAction,
  updateNonFinancialAction,
  updateOverallManagementAction,
} from "../redux/actions/scoreAction";
import { Link, useLocation } from "react-router-dom";
import Loader from "./Loader";
import { checkArray } from "../utils/CheckType";
import NoDataFound from "./NoDataFound";
import { Collapse, Modal } from "react-bootstrap";
import { anchorListAction } from "../redux/actions/anchorAction";
import { sucessToast } from "../utils/toast";
import { getFinanceListAction, getNonFinanceAction } from "../redux/actions/clientAction";
const init = {
  addMoreModal: false,
  nonFinancialError: {},
  parameterId: "",
  anchorId: "",
  range: "",
  score: "",
  weightage: "",
  weightageNewCustomer: "",
  modalState: "",
  updateModal: false,
  modalId: "",
  updateOverAllModal: false,
  title: "",
  anchorValue: "",
  financialValue: "",
  nonFinancialValue: "",
  deleteModal:false, modalName:"",listingId:"",
  addMoreFinancialModal:false,
  existingAnchorTotalSum :"",
  newAnchorTotalSum :"",
  mainStateForExistingWieghtage:false,
  mainStateForNewWieghtage:false,
  weightageStatic:"",
  weightageNewCustomerStatic:"",
  addWeightageModal:false,
  addNewWeightageModal:false,
  weightageError:{},
  newWeightageError:{},

};
function ScoreManagementDetails() {
  const [iState, updateState] = useState(init);
  const [existingAnchorTotalSum, setExistingAnchorTotalSum] = useState("")
  const [newAnchorTotalSum, setNewAnchorTotalSum] = useState("")
  const [existingNonFinanceTotalSum, setExistingNonFinanceTotalSum] = useState("")
  const [newNonFinanceTotalSum, setNewNonFinanceTotalSum] = useState("")
  const [existingFinanceTotalSum, setExistingFinanceTotalSum] = useState("")
  const [newFinanceTotalSum, setNewFinanceTotalSum] = useState("")
  const {
    addMoreModal,
    nonFinancialError,
    parameterId,
    anchorId,
    range,
    score,
    weightage,
    weightageNewCustomer,
    modalState,
    updateModal,
    modalId,
    title,
    anchorValue,
    financialValue,
    nonFinancialValue,
    updateOverAllModal,
    deleteModal, modalName,listingId
    ,addMoreFinancialModal,
    mainStateForWieghtage,
    mainStateForNewWieghtage,
    mainStateForExistingWieghtage,
    weightageStatic,
    weightageNewCustomerStatic,
    addWeightageModal,
    addNewWeightageModal,
    weightageError,
    newWeightageError,
    //  existingAnchorTotalSum,
    // newAnchorTotalSum,
  } = iState;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    overAllWeightageData,
    anchorRiskListData,
    financeRiskListData,
    nonFinancialRiskListData,
    loader,
  } = useSelector((state) => state.mainReducer);
  
  const adminData = JSON.parse(localStorage.getItem("sgfinserv"));
  useEffect(() => {
    dispatch(overAllWeightageListAction(state?._id));
    dispatch(getFinanceListAction(state?._id));
    dispatch(ankorRiskListAction(state?._id))
    dispatch(NonFinancialRiskAction(state?._id))
    
  }, []);

 
  const showAddMoreNonFinancialModal = (anchorId, id, state,i) => {
    if(state === "anchor"){    
      if(anchorRiskListData?.result?.length > 0){
            console.log("datatattagasfsdfsdg", anchorRiskListData?.result?.[i]?.anchorRiskData)
        // anchorRiskListData?.result?.map((ele,i) => {
          let sumExisting = 0;
        let sumNew = 0;
        anchorRiskListData?.result[i]?.anchorRiskData?.map((item,i) => {
            console.log("itemlisttt", item)
            sumExisting = sumExisting + item?.weightage;
            sumNew = sumNew + item?.weightageNewCustomer;
            
          })
          console.log(sumExisting, sumNew)
         setExistingAnchorTotalSum(sumExisting)
          setNewAnchorTotalSum(sumNew)
        //  })
         
        
      }
    }
     else{
      let sumExistingNonFinance = 0;
      let sumNewNonFinance = 0;
      if(nonFinancialRiskListData?.result?.length > 0){
        // let nonFinancialRiskMaxData = nonFinancialRiskListData?.result?.map((ele,i) => {
        //   ele?.nonFinanceRiskData?.map((item,i) => {
        //     console.log(item)
        //     sumExistingNonFinance = sumExistingNonFinance + item?.weightage;
        //     sumNewNonFinance = sumNewNonFinance + item?.weightageNewCustomer;        
        //   })      
        //  })
        nonFinancialRiskListData?.result[i]?.nonFinanceRiskData?.map((item,i) => {
          sumExistingNonFinance = sumExistingNonFinance + item?.weightage;
          sumNewNonFinance = sumNewNonFinance + item?.weightageNewCustomer;        
        })   
         setExistingNonFinanceTotalSum(sumExistingNonFinance)
         setNewNonFinanceTotalSum(sumNewNonFinance)
         updateState({...iState,mainStateForWieghtage: true})
        //  updateState({...iState, existingNonFinanceTotalSum:sumExistingNonFinance , newNonFinanceTotalSum:sumNewFinance})
      }
    }
    updateState({
      ...iState,
      addMoreModal: true,
      anchorId,
      parameterId: id,
      modalState: state,
    });
  };

  const hideAddMoreNonFinancialModal = () => {
    updateState({
      ...iState,
      addMoreModal: false,
      range: "",
      score: "",
      weightage: "",
      weightageNewCustomer: "",
      nonFinancialError: {},
      mainStateForExistingWieghtage:false, mainStateForNewWieghtage:false
    });
  };

  const showUpdateModal = (ele, state,i) => {
     if(state === "anchor"){    
      if(anchorRiskListData?.result?.length > 0){
        let sumExisting = 0;
        let sumNew = 0;
       
        let anchorRiskMaxData = anchorRiskListData?.result?.map((ele,i) => {
          ele?.anchorRiskData?.map((item,i) => {         
            sumExisting = sumExisting + ele?.anchorRiskData[0]?.weightage;
            sumNew = sumNew + item?.weightageNewCustomer;
            
          })
         })
        // anchorRiskListData?.result[i]?.anchorRiskData?.map((item,i) => {
        //   console.log("itemlisttt", item)
        //   sumExisting = sumExisting + item?.weightage;
        //   sumNew = sumNew + item?.weightageNewCustomer;
          
        // })
         console.log(sumExisting, sumNew)
         setExistingAnchorTotalSum(sumExisting)
   setNewAnchorTotalSum(sumNew)
        
      }
    } else   if(state === "finance"){    
      if(financeRiskListData?.result?.length > 0){
        let sumFinanceExisting = 0;
        let sumFinanceNew = 0;
       
        // let anchorRiskMaxData = financeRiskListData?.result?.map((ele,i) => {
        //   ele?.financeRiskData?.map((item,i) => {
           
        //     sumFinanceExisting = sumFinanceExisting + item?.weightage;
        //     sumFinanceNew = sumFinanceNew + item?.weightageNewCustomer;
            
        //   })
        //  })
         financeRiskListData?.result[i]?.financeRiskData?.map((item,i) => {
          console.log("itemlisttt", item)
          sumFinanceExisting = sumFinanceExisting + item?.weightage;
          sumFinanceNew = sumFinanceNew + item?.weightageNewCustomer;
          
        })
           console.log(sumFinanceExisting)
           console.log(sumFinanceNew)
         setExistingFinanceTotalSum(sumFinanceExisting)
   setNewFinanceTotalSum(sumFinanceNew)
   updateState({...iState,modalState: state})
       
      }
    }
     else{
      let sumExistingNonFinance = 0;
      let sumNewNonFinance = 0;
      if(nonFinancialRiskListData?.result?.length > 0){
        // let nonFinancialRiskMaxData = nonFinancialRiskListData?.result?.map((ele,i) => {
        //   ele?.nonFinanceRiskData?.map((item,i) => {
        //     console.log(item)
        //     sumExistingNonFinance = sumExistingNonFinance + item?.weightage;
        //     sumNewNonFinance = sumNewNonFinance + item?.weightageNewCustomer;        
        //   })      
        //  })
        nonFinancialRiskListData?.result[i]?.nonFinanceRiskData?.map((item,i) => {
          console.log("itemlisttt", item)
          sumExistingNonFinance = sumExistingNonFinance + item?.weightage;
          sumNewNonFinance = sumNewNonFinance + item?.weightageNewCustomer;
          
        })
         setExistingNonFinanceTotalSum(sumExistingNonFinance)
         setNewNonFinanceTotalSum(sumNewNonFinance)
         updateState({...iState,mainStateForWieghtage: true})
        //  updateState({...iState, existingNonFinanceTotalSum:sumExistingNonFinance , newNonFinanceTotalSum:sumNewFinance})
      }
    }
    
    updateState({
      ...iState,
      range: ele?.range,
      score: ele?.score,
      weightage: ele?.weightage,
      weightageNewCustomer: ele?.weightageNewCustomer,
      modalId: ele?._id,
      parameterId: ele?.parameterId,
      modalState: state,
      updateModal: true,
      weightageStatic:ele?.weightage,weightageNewCustomerStatic:ele?.weightageNewCustomer
    });
  };

  const hideUpdateModal = () => {
    updateState({
      ...iState,
      updateModal: false,
      range: "",
      score: "",
      weightage: "",
      weightageNewCustomer: "",
      modalId: "",
      nonFinancialError: {},
      mainStateForExistingWieghtage:false, mainStateForNewWieghtage:false
    });
  };

  const showOverAllUpdateModal = (ele, state) => {
    
    updateState({
      ...iState,
      title: ele?.title,
      anchorValue: ele?.anchorValue,
      financialValue: ele?.financialValue,
      nonFinancialValue: ele?.nonFinancialValue,
      modalId: ele?._id,
      parameterId: ele?.parameterId,
      modalState: state,
      updateOverAllModal: true,
    });
  };

  const hideOverAllModal = () => {
    updateState({
      ...iState,
      updateOverAllModal: false,
      range: "",
      score: "",
      weightage: "",
      weightageNewCustomer: "",
      modalId: "",
      nonFinancialError: {},
    });
  };


  
  const showAddMoreFinancialModal = (data, state,i) => {
      if(state === "finance"){    
      if(financeRiskListData?.result?.length > 0){
        let sumFinanceExisting = 0;
        let sumFinanceNew = 0;
       
        // let anchorRiskMaxData = financeRiskListData?.result?.map((ele,i) => {
        //   ele?.financeRiskData?.map((item,i) => {
           
        //     sumFinanceExisting = sumFinanceExisting + item?.weightage;
        //     sumFinanceNew = sumFinanceNew + item?.weightageNewCustomer;
            
        //   })
        //  })

        financeRiskListData?.result[i]?.financeRiskData?.map((item,i) => {
          console.log("itemlisttt", item)
          sumFinanceExisting = sumFinanceExisting + item?.weightage;
          sumFinanceNew = sumFinanceNew + item?.weightageNewCustomer;
          
        })
           console.log(sumFinanceExisting)
           console.log(sumFinanceNew)
         setExistingFinanceTotalSum(sumFinanceExisting)
   setNewFinanceTotalSum(sumFinanceNew)
       
      }
    }
    updateState({
      ...iState,
      addMoreFinancialModal: true,
      modalState:state,
      anchorId:data?.anchorId,
      parameterId:data?._id

      // range: "",
      // score: "",
      // weightage: "",
      // weightageNewCustomer: "",
      // modalId: "",
      // nonFinancialError: {},
    });
  };


  const hideAddMoreFinancialModal = () => {
    updateState({
      ...iState,
      addMoreFinancialModal: false,
      range: "",
      score: "",
      weightage: "",
      weightageNewCustomer: "",
      modalId: "",
      nonFinancialError: {},
      mainStateForExistingWieghtage:false, mainStateForNewWieghtage:false
    });
  };

  const getNonFinancialData = (id) => {
    dispatch(NonFinancialRiskAction(id));
  };
// console.log(nonfinance)
  const getanchorRiskListData = (id) => {
    dispatch(ankorRiskListAction(id));
  };



  const handleInputNumber = (e, data) => {
    console.log(weightage, weightageNewCustomer)
    const { name, value } = e.target;
    const result = value
    // .replace(/\D/g, "");
       let error = {}
       
       if(data === "edit"){
        console.log(weightage, weightageNewCustomer)
        if(modalState == "anchor"){
          if (name === "weightage") {
            const oldWeightage = existingAnchorTotalSum + Number(result) - Number(weightageStatic) ;
            // debugger
          
            if (
              oldWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.anchorValue
            ) {
              console.log(overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue - oldWeightage)
              error.weightageError = "Please enter a value less than the assigned value. "
              updateState({ ...iState,[name]: result , weightageError: error ,mainStateForExistingWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForExistingWieghtage:false  });
            }
          }  else if (name === "weightageNewCustomer") {
            // debugger
            const newWeightage = newAnchorTotalSum + Number(result) - Number(weightageNewCustomerStatic);
            if (
              newWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue
            ) {
              error.weightageNewCustomerError = "Please enter a value less than the assigned value"
              updateState({ ...iState, [name]: result, nonFinancialError: error,mainStateForNewWieghtage:true  });
              return
            }else{
              
              updateState({...iState, [name]: result,mainStateForNewWieghtage:false  });
            }
          }
          else{
      
            updateState({ ...iState, [name]: result });
          }
         } else if (modalState == "finance"){
          if (name === "weightage") {
            const oldWeightage = existingFinanceTotalSum + Number(result) - Number(weightageStatic);
            // debugger
            // console.log("oldWeightage: ", oldWeightage);
            // console.log("overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue: ", overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue);
            if (
              oldWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue
            ) {
              // console.log(overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue - oldWeightage)
              error.weightageError = "Please enter a value less than the assigned value. "
              updateState({ ...iState,[name]: result , nonFinancialError: error ,mainStateForExistingWieghtage:true  });
              return;
            }else{           
              updateState({...iState, [name]: result,mainStateForExistingWieghtage:false });
            }
          }else if (name === "weightageNewCustomer") {
            const newWeightage = newFinanceTotalSum + Number(result)  - Number(weightageNewCustomerStatic);;
            console.log("newWeightage: ", newWeightage);
            console.log("overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue: ", overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.financialValue);
            if (
              newWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.financialValue
            ) {
              error.weightageNewCustomerError = "Please enter a value less than the assigned value"
              updateState({ ...iState, [name]: result, nonFinancialError: error,mainStateForNewWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForNewWieghtage:false  });
            }
          }
          else{
      
            updateState({ ...iState, [name]: result });
          }
         } else {
          console.log("oldNonFinanceWeightage")
          if (name === "weightage") {
            console.log(existingNonFinanceTotalSum,weightageStatic, result)
            const oldNonFinanceWeightage = existingNonFinanceTotalSum + Number(result) - Number(weightageStatic);
            console.log("oldNonFinanceWeightage", oldNonFinanceWeightage)
            if (
              oldNonFinanceWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.nonFinancialValue
            ) {
              console.log("overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue ",  overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.nonFinancialValue)
              error.weightageError = "Please enter a value less than the assigned value. "
              updateState({ ...iState,[name]: result , nonFinancialError: error ,mainStateForExistingWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForExistingWieghtage:false });
            }
          }if (name === "weightageNewCustomer") {
            const newNonFinanceWeightage = newNonFinanceTotalSum + Number(result) - Number(weightageNewCustomerStatic);
            if (
              newNonFinanceWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.nonFinancialValue
            ) {
              error.weightageNewCustomerError = "Please enter a value less than the assigned value"
              updateState({ ...iState, [name]: result, nonFinancialError: error,mainStateForNewWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForNewWieghtage:false  });
            }
          }
          else{
      
            updateState({ ...iState, [name]: result });
          }
         }
        //  addd work
       } else{
        if(modalState == "anchor"){
          if (name === "weightage") {
            const oldWeightage = existingAnchorTotalSum + Number(result);
            if (
              oldWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.anchorValue
            ) {
              console.log(overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue - oldWeightage)
              error.weightageError = "Please enter a value less than the assigned value. "
              updateState({ ...iState,[name]: result , nonFinancialError: error ,mainStateForExistingWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForExistingWieghtage:false  });
            }
          }  else if (name === "weightageNewCustomer") {
            const newWeightage = newAnchorTotalSum + Number(result);
            if (
              newWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue
            ) {
              error.weightageNewCustomerError = "Please enter a value less than the assigned value"
              updateState({ ...iState, [name]: result, nonFinancialError: error,mainStateForNewWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForNewWieghtage:false  });
            }
          }
          else{
      
            updateState({ ...iState, [name]: result });
          }
         } else if (modalState == "finance"){
          if (name === "weightage") {
            const oldWeightage = existingFinanceTotalSum + Number(result);
            console.log("oldWeightage: ", oldWeightage);
            console.log("overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue: ", overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue);
            if (
              oldWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue
            ) {
              console.log(overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue - oldWeightage)
              error.weightageError = "Please enter a value less than the assigned value. "
              updateState({ ...iState,[name]: result , nonFinancialError: error ,mainStateForExistingWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForExistingWieghtage:false });
            }
          }if (name === "weightageNewCustomer") {
            const newWeightage = newFinanceTotalSum + Number(result);
            console.log("newWeightage: ", newWeightage);
            console.log("overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.financialValue: ", overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.financialValue);
            if (
              newWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.financialValue
            ) {
              error.weightageNewCustomerError = "Please enter a value less than the assigned value"
              updateState({ ...iState, [name]: result, nonFinancialError: error,mainStateForNewWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForNewWieghtage:false  });
            }
          }
          else{
      
            updateState({ ...iState, [name]: result });
          }
         } else {
          if (name === "weightage") {
            const oldNonFinanceWeightage = existingNonFinanceTotalSum + Number(result);
            if (
              oldNonFinanceWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[1]?.nonFinancialValue
            ) {
              // console.log(overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue - oldWeightage)
              error.weightageError = "Please enter a value less than the assigned value. "
              updateState({ ...iState,[name]: result , nonFinancialError: error ,mainStateForExistingWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForExistingWieghtage:false });
            }
          }if (name === "weightageNewCustomer") {
            const newNonFinanceWeightage = newNonFinanceTotalSum + Number(result);
            if (
              newNonFinanceWeightage >
              overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.nonFinancialValue
            ) {
              error.weightageNewCustomerError = "Please enter a value less than the assigned value"
              updateState({ ...iState, [name]: result, nonFinancialError: error,mainStateForNewWieghtage:true  });
              return;
            }else{
              updateState({...iState, [name]: result,mainStateForNewWieghtage:false  });
            }
          }
          else{
      
            updateState({ ...iState, [name]: result });
          }
         }
       }
         
     
     
      // console.log(
      //   "maxValueFOrNew",
      //   overAllWeightageData?.result?.[0]?.weigtageData?.[0]?.anchorValue
      // );
    // }
  };

  const handleNonFinanceValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!range) {
      error.rangeError = "Range can't be empty";
      formIsValid = false;
    }

   

    if ( score.toString()  == "") {
      error.scoreError = "Score can't be empty";
      formIsValid = false;
    }

    if ( +score  > 5) {
      error.scoreError = "Please enter a score less than or equal to 5";
      formIsValid = false;
    }

    // if (weightage.toString()  == "" ) {
    //   error.weightageError = "Weightage can't be empty";
    //   formIsValid = false;
    // }

    // if (weightage) {
    //   if (weightage > 100 || weightage <  0) {
    //     error.weightageError = "Please enter a valid weightage";
    //     formIsValid = false;
    //   }
    // }

    // if (weightageNewCustomer.toString()  == "") {
    //   error.weightageNewCustomerError = "Weightage new customer can't be empty";
    //   formIsValid = false;
    // }
    //  if(weightage && weightageNewCustomer && score){
    //   if(((+weightageNewCustomer) +  (+weightage) + (+score)) !== 100){
    //     error.finalError = "Please enter a total value of 100";
    //     formIsValid = false;
    //   }
    //  }
    
    if (weightageNewCustomer) {
      if (weightageNewCustomer > 100 || weightageNewCustomer < 0) {
        error.weightageNewCustomerError =
          "Please enter a valid weightage customer";
        formIsValid = false;
      }
    }
  //   if( weightageNewCustomer && mainStateForNewWieghtage &&  weightageNewCustomer > 0){
  //      error.weightageNewCustomerError = "Please enter a value less than the assigned value";
  //      formIsValid = false;

  //   } if(weightage && mainStateForExistingWieghtage && weightage > 0){
  //     error.weightageError = "Please enter a value less than the assigned value";
  //     formIsValid = false;
  //  }

    
    updateState({ ...iState, nonFinancialError: error });
    return formIsValid;
  };

  const addNonFinancialFunc = () => {
    let formIsValid = handleNonFinanceValidation();
   
    if (formIsValid) {
      let data = {
        weightageNewCustomer,
        weightage,
        range,
        anchorId,
        parameterId,
        score,
      };
      
     
      if (modalState === "nonFinancial") {
        dispatch(addMoreNonFinancialAction(data)).then((res) => {
          sucessToast("Non finance rating added successfully");
          dispatch(NonFinancialRiskAction(state?._id));
          hideAddMoreNonFinancialModal();
          // updateState({...iState, mainStateForExistingWieghtage:false, mainStateForNewWieghtage:false})
        });
      } else if (modalState === "finance"){
        dispatch(addMoreFinancialAction(data)).then((res) => {
          dispatch(getFinanceListAction(state?._id));
          hideAddMoreFinancialModal();
          sucessToast("Finance rating updated successfully");
          // updateState({...iState, mainStateForExistingWieghtage:false, mainStateForNewWieghtage:false})
        });
      }
       else {
       
        dispatch(addMoreAnchorRiskAction(data)).then((res) => {       
          dispatch(ankorRiskListAction(state?._id));
          hideAddMoreNonFinancialModal();
          sucessToast("Anchor rating added successfully");
          // updateState({...iState, mainStateForExistingWieghtage:false, mainStateForNewWieghtage:false})
        });
      }
    }
  };

  const updateListFunc = () => {
    let formIsValid = handleNonFinanceValidation();
    if (formIsValid) {
      let data = {
        weightageNewCustomer,
        weightage,
        range,
        id: modalId,
        score,
        type:modalState  == "finance" ? "Finance":""
      };
      if(modalState === "finance"){
        data["type"] = "Finance"
      }

      if (modalState === "nonFinancial") {
        dispatch(updateNonFinancialAction(data)).then((res) => {
          console.log("ressss", res);
          sucessToast("Non finance rating updated successfully");
          dispatch(NonFinancialRiskAction(state?._id));
          hideUpdateModal();
         
        });
      } else if (modalState === "anchor") {
        dispatch(updateAnchorRiskAction(data)).then((res) => {
          console.log("ressss", res);
          dispatch(ankorRiskListAction(state?._id));
          hideUpdateModal();
          sucessToast("Anchor rating updated successfully");
         
        });
      }  else if (modalState === "finance"){
        dispatch(updateFinancialAction(data)).then((res) => {
          console.log("ressss", res);
          dispatch(getFinanceListAction(state?._id));
          hideUpdateModal();
          sucessToast("Finance rating updated successfully");
          // updateState({...iState, mainStateForExistingWieghtage:false, mainStateForNewWieghtage:false})
        });
      } else {

      }
    }
  };

  const handleInput = (e) => {
   
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const removeNonFinanceFunc = (id, pid) => {
    dispatch(removeNonFinancialAction(modalId)).then((res) => {
      console.log("resss", res);
      sucessToast("Non finance removed successfully");
      dispatch(NonFinancialRiskAction(state?._id));
      hideDeleteModal()
    });
  };
  const removeFinanceFunc = (id, pid) => {
    dispatch(removeFinancialAction(modalId)).then((res) => {
      console.log("resss", res);
      sucessToast("Finance rating removed successfully");
      dispatch(getFinanceListAction(state?._id));
      hideDeleteModal()
    });
  };


  const removeAnchorFunc = (id, pid) => {
    dispatch(removeAnchorRiskAction(modalId)).then((res) => {
      console.log("resss", res);
      sucessToast("Anchor rating removed successfully");
      dispatch(ankorRiskListAction(state?._id));
      hideDeleteModal()
    });
  };

  const handleOverALlValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!title) {
      error.titleError = "Title can't be empty";
      formIsValid = false;
    }

    if (anchorValue.toString() == "") {
      error.anchorValueError = "Anchor value can't be empty";
      formIsValid = false;
    }

    if (financialValue.toString() == "") {
      error.financialValueError = "Financial value can't be empty";
      formIsValid = false;
    }
   

    if (financialValue) {
      if (financialValue > 100 || financialValue < 0) {
        error.financialValueError = "Please enter a valid financial value";
        formIsValid = false;
      }
    }
     if(nonFinancialValue && financialValue && anchorValue){
      if(((+financialValue) +  (+anchorValue) + (+nonFinancialValue)) !== 100){
        error.finalError = "Please enter a total value of 100";
        formIsValid = false;
      }
     }
   

    if (nonFinancialValue.toString() === "") {
      error.nonFinancialValueError = "Non financial value can't be empty";
      formIsValid = false;
    }
    if (nonFinancialValue) {
      if (nonFinancialValue > 100 || nonFinancialValue < 0) {
        error.nonFinancialValueError =
          "Please enter a valid non financial value";
        formIsValid = false;
      }
    }

    updateState({ ...iState, nonFinancialError: error });
    return formIsValid;
  };
// console.log(anchorValue,financialValue )
//   console.log("(+anchorValue) +  (+financialValue) + (+nonFinancialValue)",(+weightageNewCustomer) +  (+weightage) + (+score))
  const updateOverAllFunc = () => {
    let formIsValid = handleOverALlValidation();
    if (formIsValid) {
      let data = {
        title,
        anchorValue,
        financialValue,
        nonFinancialValue,
        id:modalId
      };
      dispatch(updateOverallManagementAction( data)).then((res) => {
        console.log("ressss", res);
        dispatch(overAllWeightageListAction(state?._id));
        hideOverAllModal();
        sucessToast("Overall weightage updated successfully");
      });
    }
  };


  
const showDeleteModal = (_id, sid,name, state) => {
 
  updateState({...iState, deleteModal:true, modalId:_id, modalName:name, listingId:sid, modalState:state})
}


const hideDeleteModal = () => {
  updateState({...iState, deleteModal:false, modalId:"", modalName:"",listingId:""})
}

const [selectedAnchorId, setSelectedAnchorId] = useState(null);

const handleAnchorSelect = async (id, checked , pid) => {
 
    // setSelectedAnchorId(id);
    dispatch(updateAnchorValueAction({id:id,is_anchor_rating:checked, parameterId: pid})).then((res) =>{
      dispatch(ankorRiskListAction(state?._id))
    })
   
};

const showAddWeightageModal = ( ele, state,i ) =>{
  if(state === "anchor"){    
    if(anchorRiskListData?.result?.length > 0){
      let sumExisting = 0;
      let sumNew = 0;
     
      let anchorRiskMaxData = anchorRiskListData?.result?.map((ele,i) => {
        // ele?.anchorRiskData?.map((item,i) => {         
          sumExisting = sumExisting + ele?.anchorRiskData[0]?.weightage;
          sumNew = sumNew +  ele?.anchorRiskData[0]?.weightageNewCustomer;
          
        // })
       })
      // anchorRiskListData?.result[i]?.anchorRiskData?.map((item,i) => {
      //   console.log("itemlisttt", item)
      //   sumExisting = sumExisting + item?.weightage;
      //   sumNew = sumNew + item?.weightageNewCustomer;
        
      // })
       console.log(sumExisting)
       console.log(sumNew)
       setExistingAnchorTotalSum(sumExisting)
 setNewAnchorTotalSum(sumNew)
 updateState({...iState,modalState: state, addWeightageModal:true, modalId:ele?._id,  weightageStatic:ele?.anchorRiskData[0]?.weightage,weightageNewCustomerStatic:ele?.anchorRiskData[0]?.weightageNewCustomer, weightage:ele?.anchorRiskData[0]?.weightage, weightageNewCustomer:ele?.anchorRiskData[0]?.weightageNewCustomer})
    }
  } else if(state === "finance"){    
    if(financeRiskListData?.result?.length > 0){
      let sumFinanceExisting = 0;
      let sumFinanceNew = 0;
     
      // let anchorRiskMaxData = financeRiskListData?.result?.map((ele,i) => {
      //   ele?.financeRiskData?.map((item,i) => {
         
      //     sumFinanceExisting = sumFinanceExisting + item?.weightage;
      //     sumFinanceNew = sumFinanceNew + item?.weightageNewCustomer;
          
      //   })
      //  })
       financeRiskListData?.result?.map((item,i) => {
      
        sumFinanceExisting = sumFinanceExisting + item?.financeRiskData[0]?.weightage
        sumFinanceNew = sumFinanceNew + item?.financeRiskData[0]?.weightageNewCustomer
        
      })
         console.log(sumFinanceExisting)
         console.log(sumFinanceNew)
       setExistingFinanceTotalSum(sumFinanceExisting)
 setNewFinanceTotalSum(sumFinanceNew)
 updateState({...iState,modalState: state, addWeightageModal:true, modalId:ele?._id,  weightageStatic:ele?.financeRiskData[0]?.weightage,weightageNewCustomerStatic:ele?.financeRiskData[0]?.weightageNewCustomer, weightage:ele?.financeRiskData[0]?.weightage, weightageNewCustomer:ele?.financeRiskData[0]?.weightageNewCustomer})
     
    }
  }
   else{
    let sumExistingNonFinance = 0;
    let sumNewNonFinance = 0;
    if(nonFinancialRiskListData?.result?.length > 0){
      // let nonFinancialRiskMaxData = nonFinancialRiskListData?.result?.map((ele,i) => {
      //   ele?.nonFinanceRiskData?.map((item,i) => {
      //     console.log(item)
      //     sumExistingNonFinance = sumExistingNonFinance + item?.weightage;
      //     sumNewNonFinance = sumNewNonFinance + item?.weightageNewCustomer;        
      //   })      
      //  })
      nonFinancialRiskListData?.result?.map((item,i) => {
        sumExistingNonFinance = sumExistingNonFinance + item?.nonFinanceRiskData[0]?.weightage;
        sumNewNonFinance = sumNewNonFinance + item?.nonFinanceRiskData[0]?.weightageNewCustomer;
        
      })
      console.log(sumExistingNonFinance,sumNewNonFinance)
       setExistingNonFinanceTotalSum(sumExistingNonFinance)
       setNewNonFinanceTotalSum(sumNewNonFinance)
       updateState({...iState, addWeightageModal:true, modalId:ele?._id, modalState:state, weightageStatic:ele?.nonFinanceRiskData[0]?.weightage,weightageNewCustomerStatic:ele?.nonFinanceRiskData[0]?.weightageNewCustomer, weightage:ele?.nonFinanceRiskData[0]?.weightage, weightageNewCustomer:ele?.nonFinanceRiskData[0]?.weightageNewCustomer})
      //  updateState({...iState, existingNonFinanceTotalSum:sumExistingNonFinance , newNonFinanceTotalSum:sumNewFinance})
    }
  }


  
  // updateState({...iState, addWeightageModal:true, modalId:ele?._id, modalState:state, weightageStatic:ele?.anchorRiskData[0]?.weightage,weightageNewCustomerStatic:ele?.anchorRiskData[0]?.weightageNewCustomer, weightage:ele?.weightage, weightageNewCustomer:ele?.weightageNewCustomer})
}


const hideAddWeightageModal = ( ) =>{
  updateState({...iState, addWeightageModal:false,modalState:"", modalId:"", weightage:"", weightageNewCustomer:"", weightageError:{},})
}



const handleWeightageValidation = () => {
  let formIsValid = true;
  let errors = {};

  // Convert weightage values to numbers for comparison
  const currentWeightage = Number(weightage);
  const newWeightage = Number(weightageNewCustomer);

  // Check if weightage and newWeightage are provided
  if (!weightage) {
    errors.weightageError = "Existing Weightage can't be empty";
    formIsValid = false;
  }

  if (!weightageNewCustomer) {
    errors.newWeightageError = "New weightage can't be empty";
    formIsValid = false;
  }

  // Validate weightageNewCustomer
  if (newWeightage < 0 || newWeightage > 100) {
    errors.newWeightageError = "Please enter a number between 0 and 100";
    formIsValid = false;
  } else if (newWeightage > 0 && mainStateForNewWieghtage) {
    if (newWeightage >= currentWeightage) {
      errors.newWeightageError = "Please enter a value less than the assigned value";
      formIsValid = false;
    }
  }

  // Validate weightage
  if (currentWeightage < 0 || currentWeightage > 100) {
    errors.weightageError = "Please enter a number between 0 and 100";
    formIsValid = false;
  } else if (currentWeightage > 0 && mainStateForExistingWieghtage) {
    if (currentWeightage >= newWeightage) {
      errors.weightageError = "Please enter a value less than the assigned value";
      formIsValid = false;
    }
  }

  // Update the state with error messages
  updateState({ ...iState, weightageError: errors });

  return formIsValid;
}


 


 console.log({weightage, weightageNewCustomer,mainStateForNewWieghtage})
const updateWeigtageFunc = () =>{
  
  let formIsValid = handleWeightageValidation();
  if(formIsValid){   
    dispatch(updateFinancialAction({weightage, weightageNewCustomer, parameterId:modalId, anchorId:state?._id, type:modalState === "finance" ? "Finance": modalState === "anchor" ?"Anchor" :"NonFinance" })).then((res ) =>{
      sucessToast("Weightage updated successfully")
      dispatch(getFinanceListAction(state?._id))
      dispatch(NonFinancialRiskAction(state?._id))
      // dispatch(getNonFinanceAction(state?._id))
      dispatch(ankorRiskListAction(state?._id))
     hideAddWeightageModal()
    })
  }
}

  return (
    <>
      <Header />
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">
              <Link to="/score-management" className="backArrow">
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </Link>{" "}
              Score &amp; Weightage Management
            </h4>
          </div>
          <div className="BusinessPersonal">
            <figure>
              <img src="images/Avatar-1.png" />
            </figure>
            <figcaption>
              <h3>{state?.companyName}</h3>
              <p>
                <strong>Anchor ID: </strong>
                {state?.anchor_number}
              </p>
            </figcaption>
          </div>
          <div className="CommonTabs">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Overall-Weightage"
                >
                  Overall Weightage
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#Anchor-Risk-Rating"
                >
                  Anchor Risk Rating
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#Financial-Risk"
                >
                  Financial Risk{" "}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#Non-Financial-Risk"
                >
                  Non- Financial Risk &amp; Account Conduct{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="Overall-Weightage">
              <div className="Small-Wrapper">
                <div className="TableList">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Particulars</th>
                        <th>Anchor</th>
                        <th>Financial</th>
                        <th>Non-Financial</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <>
                          <Loader />
                        </>
                      ) : checkArray(
                          overAllWeightageData?.result?.[0]?.weigtageData
                        ) ? (
                        overAllWeightageData?.result?.[0]?.weigtageData?.map(
                          (ele, i) => {
                            return (
                              <>
                                <tr>
                                  <td>{ele?.title}</td>
                                  <td> {ele?.anchorValue} %</td>
                                  <td> {ele?.financialValue} %</td>
                                  <td> {ele?.nonFinancialValue} %</td>

                                  {adminData?.userType == "STAFF" ? (
                                    <>
                                      {adminData?.permission?.some(
                                        (ele) =>
                                          ele.name == "Score-Management" &&
                                          ele.fullAccess == true
                                      ) && (
                                        <>
                                          <td>
                                            <div className="Actions">
                                              <a
                                                className="Green"
                                                onClick={() =>
                                                  showOverAllUpdateModal(
                                                    ele,
                                                    "overAll"
                                                  )
                                                }
                                              >
                                                <i className="fa fa-pencil" />
                                              </a>
                                              {/* <a
                                                className="Red"
                                                // onClick={() => removeAnchorFunc(ele?._id, ele?.parameterId)}
                                              >
                                                <i className="fa fa-trash" />
                                              </a> */}
                                            </div>
                                          </td>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                            <div className="Actions">
                                              <a
                                                className="Green"
                                                onClick={() =>
                                                  showOverAllUpdateModal(
                                                    ele,
                                                    "overAll"
                                                  )
                                                }
                                              >
                                                <i className="fa fa-pencil" />
                                              </a>
                                            
                                              {/* <a
                                                className="Red"
                                                // onClick={() => removeAnchorFunc(ele?._id, ele?.parameterId)}
                                              >
                                                <i className="fa fa-trash" />
                                              </a> */}
                                            </div>
                                          </td>
                                     </>
                                  )}
                                </tr>
                              </>
                            );
                          }
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Anchor-Risk-Rating">
              {/* { */}
                {/* // loader ? (
                //     <>
                //       <Loader />
                //     </>
                //   ) :
                // checkArray(
                //   overAllWeightageData?.result?.[0]?.anchorriskparametersData
                // ) ? (
                //   overAllWeightageData?.result?.[0]?.anchorriskparametersData
                //     .reduce((result, currentObj) => { */}
                {/* //       const group = result.find(
                //         (group) => group.name === currentObj.outsideTitle
                //       );

                //       // If the group exists, add the current object to its "data" array
                //       if (group) { */}
                {/* //         group.data.push(currentObj);
                //       } else {
                //         // If the group doesn't exist, create a new group with the current object
                //         result.push({
                //           name: currentObj.outsideTitle,
                //           data: [currentObj],
                //         });
                //       }
                //       return result;
                //     }, [])
                //     .map((ele, i) => { */}
                    
                          <div className="AccordionBox">
                            <div id="anchorAccordion">
                           {checkArray(anchorRiskListData?.result) ?
                            anchorRiskListData?.result?.map((ele,i) => {
                      return (
                       
                            
                                
                                  <>
                                    <div
                                      className="card"
                                    >
                                      <div
                                        className="card-header"
                                        data-toggle="collapse"
                                        href={`#collapse${i}`}
                                        aria-expanded="false"
                                      >
                                        
                                        <h4>{ele?.title}</h4>


                                        {adminData?.userType == "STAFF" ? (
                                                              <>
                                                                {adminData?.permission?.some(
                                                                  (ele) =>
                                                                    ele.name ==
                                                                      "Score-Management" &&
                                                                    ele.fullAccess ==
                                                                      true
                                                                ) && (
                                                                  <>
                                                                   <a
                                          onClick={() =>
                                            showAddMoreNonFinancialModal(
                                              ele?.anchorId,
                                              ele?._id,
                                              "anchor",
                                              i
                                            )
                                          }
                                        >
                                          + Add More Range
                                        </a>
                                                                  </>
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                              <div>
                                                                <a
                                      onClick={() =>
                                        showAddWeightageModal(
                                          ele,                                        
                                          "anchor",
                                          i
                                        )
                                      }
                                    >
                                     <i className="fa fa-pencil" />
                                    </a>
                                
                                                                <a
                                          onClick={() =>
                                            showAddMoreNonFinancialModal(
                                              ele?.anchorId,
                                              ele?._id,
                                              "anchor",
                                              i
                                            )
                                          }
                                        >
                                          + Add More Range
                                        </a>
                                        </div>
                                                               </>
                                                            )}
                                       
                                      </div>
                                      <div
                                        id={`collapse${i}`}
                                        className="collapse"
                                        data-parent="#anchorAccordion"
                                      >
                                        <div className="card-body">
                                          <div className="TableList">
                                            <table style={{ width: "100%" }}>
                                              <thead>
                                                <tr>
                                                  <th>Range</th>
                                                  <th>Score</th>
                                                  <th>Weightage (Existing)</th>
                                                  <th>
                                                    Weightage (New customer)
                                                  </th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {checkArray(
                                                  ele?.anchorRiskData
                                                ) &&
                                                ele?.anchorRiskData?.map(
                                                    (ele, i) => {
                                                      // console.log("eleleleelele", ele?.is_anchor_rating)
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td>
                                                              {ele?.range}
                                                            </td>
                                                            <td>
                                                              {ele?.score}
                                                            </td>
                                                            <td>
                                                              {ele?.weightage} %
                                                            </td>
                                                            <td>
                                                              {
                                                                ele?.weightageNewCustomer
                                                              }{" "}
                                                              %
                                                            </td>

                                                           
                                                                 <td>
                                                                      <div className="Actions">
                                                                        <a
                                                                          className="Green"
                                                                          onClick={() =>
                                                                            showUpdateModal(
                                                                              ele,
                                                                              "anchor",
                                                                              i
                                                                            )
                                                                          }
                                                                        >
                                                                          <i className="fa fa-pencil" />
                                                                        </a>
                                                                        <a
                                                                          className="Red"
                                                                          onClick={() =>
                                                                            showDeleteModal(
                                                                              ele?._id,
                                                                              ele?.parameterId,
                                                                               ele?.range,
                                                                               "anchorRating"
                                                                            )
                                                                          }
                                                                        >
                                                                          <i className="fa fa-trash" />
                                                                        </a>

                                                                        <label class="CheckBox"> 
                                                        <input type="checkbox"
                                                          name={`${ele?.title}-${i}`}
                                                          // checked={ selectedAnchorId === ele?._id  }
                                                          // || ele?.is_anchor_rating
                                                        checked={ele?.is_anchor_rating}
                                                         
                                                          onChange={(e) => handleAnchorSelect(ele?._id,e.target.checked , ele?.parameterId)}
                                                      // name={`radio-${i}`}  onChange={(e) => dispatch(updateAnchorValueAction({id:ele?._id,is_anchor_rating:e.target.checked }))}
                                                      />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                                      </div>
                                                                    </td>
                                                              
                                                          </tr>
                                                        </>
                                                      )
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                   )
                    })
                              
                    : (
                      <NoDataFound />
                    )
                  }
                            </div>
                          </div>
                      
                
            </div>
            <div className="tab-pane fade" id="Financial-Risk">
              <p className="itaalic">
                *Please note for Financial Risk Parameters, you can only edit
                weightages. Score and ranges are already fixed and is been
                pulled from the JSON file
              </p>
              <div className="AccordionBox">
                <div id="accordion">
                  {checkArray(financeRiskListData?.result) ? (
                    financeRiskListData?.result?.map((ele, i) => {
                      return (
                        <>
                          <div className="card">
                            <div
                              className="card-header"
                              data-toggle="collapse"
                              href={`#collapse${i}`}
                              aria-expanded="false"
                            >
                              <h4>{ele?.title}</h4>
                              <div>
                              <a
                                      onClick={() =>
                                        showAddWeightageModal(
                                          ele,                                        
                                          "finance",
                                          i
                                        )
                                      }
                                    >
                                    <i className="fa fa-pencil" />
                                    </a>

                                    
                              <a
                                          onClick={() =>
                                            showAddMoreFinancialModal(
                                              ele,                                           
                                              "finance",
                                              i
                                            )
                                          }
                                        >
                                          + Add More Range
                                        </a>
                              </div>
                           
                            </div>
                            <div
                              id={`collapse${i}`}
                              className="collapse"
                              data-parent="#accordion"
                            >
                              <div className="card-body">
                                <div className="TableList">
                                  <table style={{ width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <th>Range</th>
                                        <th>Score</th>
                                        <th>Weightage (Existing)</th>
                                        <th>Weightage (New customer)</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loader ? (
                                        <>
                                          <Loader />
                                        </>
                                      ) : checkArray(ele?.financeRiskData) ? (
                                        ele?.financeRiskData?.map((ele, i) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{ele?.range}</td>
                                                <td> {ele?.score}</td>
                                                <td> {ele?.weightage} %</td>
                                                <td>
                                                  {ele?.weightageNewCustomer} %
                                                </td>
                                                <td>
                                                          <div className="Actions">
                                                            <a
                                                              className="Green"
                                                              onClick={() =>
                                                                showUpdateModal(
                                                                  ele,
                                                                  "finance",
                                                                  i
                                                                )
                                                              }
                                                            >
                                                              <i className="fa fa-pencil" />
                                                            </a>
                                                            <a
                                                              className="Red"
                                                              onClick={() =>
                                                                showDeleteModal(
                                                                  ele?._id,
                                                                  ele?.parameterId,
                                                                  ele?.range,
                                                                  "finance"
                                                                )
                                                              }
                                                              // data-target="#DeleteModal"
                                                            >
                                                              <i className="fa fa-trash" />
                                                            </a>
                                                          </div>
                                                        </td>
                                              </tr>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <p>No data found</p>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}

   
              
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Non-Financial-Risk">
              {loader ? (
                <>
                  <Loader />
                </>
              ) : checkArray(
                nonFinancialRiskListData?.result
                ) ? (
                  nonFinancialRiskListData?.result?.reduce(
                  (result, currentObj) => {
                    const group = result.find(
                      (group) => group.name === currentObj.outsideTitle
                    );

                    // If the group exists, add the current object to its "data" array
                    if (group) {
                      group.data.push(currentObj);
                    } else {
                      // If the group doesn't exist, create a new group with the current object
                      result.push({
                        name: currentObj.outsideTitle,
                        data: [currentObj],
                      });
                    }
                    return result;
                  },
                  []
                ).map((ele, i) => {
                  return (
                    <>
                      <div className="AccordionBox">
                        <div id="finacialAccordion">
                          {/* {hasBeenDisplayed && <h5 className="perameter">{ele?.outsideTitle}</h5>} */}
                          <h5 className="perameter">{ele?.name}</h5>
                          {ele?.data?.map((data, i) => {
                            
                            return (
                              <>
                                <div
                                  className="card"
                                  key={i}
                                  // onClick={() => getNonFinancialData(data?._id)}
                                >
                                  <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    href={`#collapse${data?._id}`}
                                    aria-expanded="false"
                                  >
                                    <h4>{data?.title}</h4>
                                    <div>
                                    <a
                                      onClick={() =>
                                        showAddWeightageModal(
                                          data,                                        
                                          "nonFinancial",
                                          i
                                        )
                                      }
                                    >
                                     <i className="fa fa-pencil" />
                                    </a>

                                   
                                                             <a
                                      onClick={() =>
                                        showAddMoreNonFinancialModal(
                                          data?.anchorId,
                                          data?._id,
                                          "nonFinancial",
                                          i
                                        )
                                      }
                                    >
                                      + Add More Range
                                    </a>
                                          
                                    </div>
                                                      
                                                            
                                    
                                  </div>
                                  <div
                                    id={`collapse${data?._id}`}
                                    className="collapse"
                                    data-parent="#finacialAccordion"
                                  >
                                    <div className="card-body">
                                      <div className="TableList">
                                        <table style={{ width: "100%" }}>
                                          <thead>
                                            <tr>
                                              <th>Range</th>
                                              <th>Score</th>
                                              <th>Weightage (Existing)</th>
                                              <th>Weightage (New customer)</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {checkArray(
                                              data?.nonFinanceRiskData
                                                // ?.result?.[0]
                                                // ?.nonFinanceRiskData
                                            ) &&
                                            data?.nonFinanceRiskData?.map(
                                                (ele, i) => {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>{ele?.range}</td>
                                                        <td>{ele?.score}</td>
                                                        <td>
                                                          {ele?.weightage} %
                                                        </td>
                                                        <td>
                                                          {
                                                            ele?.weightageNewCustomer
                                                          }{" "}
                                                          %
                                                        </td>
                                                        {adminData?.userType ==
                                                            "STAFF" ? (
                                                              <>
                                                                {adminData?.permission?.some(
                                                                  (ele) =>
                                                                    ele.name ==
                                                                      "Score-Management" &&
                                                                    ele.fullAccess == true
                                                                ) && (
                                                                  <>
                                                                        <td>
                                                          <div className="Actions">
                                                            <a
                                                              className="Green"
                                                              onClick={() =>
                                                                showUpdateModal(
                                                                  ele,
                                                                  "nonFinancial",
                                                                  i
                                                                )
                                                              }
                                                            >
                                                              <i className="fa fa-pencil" />
                                                            </a>
                                                            <a
                                                              className="Red"
                                                              onClick={() =>
                                                                showDeleteModal(
                                                                
                                                                  ele?._id,
                                                                  ele?.parameterId,
                                                                  ele?.range,
                                                                  "nonFinancial"
                                                                )
                                                              }
                                                              // data-target="#DeleteModal"
                                                            >
                                                              <i className="fa fa-trash" />
                                                            </a>
                                                          </div>
                                                        </td>
                                                                  </>
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                <td>
                                                          <div className="Actions">
                                                            <a
                                                              className="Green"
                                                              onClick={() =>
                                                                showUpdateModal(
                                                                  ele,
                                                                  "nonFinancial",
                                                                  i
                                                                )
                                                              }
                                                            >
                                                              <i className="fa fa-pencil" />
                                                            </a>
                                                            <a
                                                              className="Red"
                                                              onClick={() =>
                                                                showDeleteModal(
                                                                  ele?._id,
                                                                  ele?.parameterId,
                                                                  ele?.range,
                                                                  "nonFinancial"
                                                                )
                                                              }
                                                              // data-target="#DeleteModal"
                                                            >
                                                              <i className="fa fa-trash" />
                                                            </a>
                                                          </div>
                                                        </td>
                                                              
                                                                </>
                                                            )} 
                                                      </tr>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addMoreModal}
        onHide={hideAddMoreNonFinancialModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddMoreNonFinancialModal}
          >
            ×
          </a>
          <div class="Category">
            <a
              href="javascript:void(0);"
              class="CloseModal"
              onClick={hideAddMoreNonFinancialModal}
            >
              ×
            </a>
            <h3>Add Range</h3>

            <div class="form-group">
              <label>Enter Range Title</label>
              <input
                type="text"
                placeholder="Enter Range Title"
                class="form-control"
                name="range"
                value={range}
                onChange={handleInput}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.rangeError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Score</label>
              <input
                type="text"
                placeholder="Enter Score"
                class="form-control"
                name="score"
                value={score}
                onChange={(e) => handleInputNumber(e,"add")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.scoreError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Weightage (Existing) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage in %"
                class="form-control"
                name="weightage"
                value={weightage}
                max={100}
                onChange={(e) =>handleInputNumber(e, "weightage")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.weightageError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Weightage (New customer) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage (New customer) in %"
                class="form-control"
                name="weightageNewCustomer"
                value={weightageNewCustomer}
                max={100}
                onChange={(e) =>handleInputNumber(e, "weightageNewCustomer")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.weightageNewCustomerError}
              </span>
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.finalError}
              </span>
            </div>
            
           
            <div class="newBtn">
              <a class="Button" onClick={addNonFinancialFunc}>
                Add
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>




      <Modal
        show={addMoreFinancialModal}
        onHide={hideAddMoreFinancialModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddMoreFinancialModal}
          >
            ×
          </a>
          <div class="Category">
            <a
              href="javascript:void(0);"
              class="CloseModal"
              onClick={hideAddMoreFinancialModal}
            >
              ×
            </a>
            <h3>Add Range</h3>

            <div class="form-group">
              <label>Enter Range Title</label>
              <input
                type="text"
                placeholder="Enter Range Title"
                class="form-control"
                name="range"
                value={range}
                onChange={handleInput}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.rangeError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Score</label>
              <input
                type="text"
                placeholder="Enter Score"
                class="form-control"
                name="score"
                value={score}
                onChange={handleInputNumber}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.scoreError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Weightage (Existing) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage in %"
                class="form-control"
                name="weightage"
                value={weightage}
                max={100}
                onChange={handleInputNumber}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.weightageError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Weightage (New customer) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage (New customer) in %"
                class="form-control"
                name="weightageNewCustomer"
                value={weightageNewCustomer}
                max={100}
                onChange={handleInputNumber}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.weightageNewCustomerError}
              </span>
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.finalError}
              </span>
            </div>
            
           
            <div class="newBtn">
              <a class="Button" onClick={addNonFinancialFunc}>
                Add
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateModal}
        onHide={hideUpdateModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateModal}
          >
            ×
          </a>
          <div class="Category">
            <h3>Update Range</h3>

            <div class="form-group">
              <label>Enter Range Title</label>
              <input
                type="text"
                placeholder="Enter Range Title"
                class="form-control"
                name="range"
                value={range}
                onChange={handleInput}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.rangeError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Score</label>
              <input
                type="text"
                placeholder="Enter Score"
                class="form-control"
                name="score"
                value={score}
                onChange={(e) => handleInputNumber(e,score)}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.scoreError}
              </span>
            </div>

            {/* <div class="form-group">
              <label>Enter Weightage (Existing) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage in %"
                class="form-control"
                name="weightage"
                value={weightage}
                
                onChange={(e) =>handleInputNumber(e,"edit")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.weightageError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Weightage (New customer) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage (New customer) in %"
                class="form-control"
                name="weightageNewCustomer"
                value={weightageNewCustomer}
                // max={100}
                onChange={(e) =>handleInputNumber(e,"edit")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.weightageNewCustomerError}
              </span>
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.finalError}
              </span>
            </div> */}

            <div class="newBtn">
              <a class="Button" onClick={updateListFunc}>
                Update
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateOverAllModal}
        onHide={hideOverAllModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideOverAllModal}
          >
            ×
          </a>
          <div class="Category">
            <h3>Update Range</h3>

            <div class="form-group">
              <label>Enter Title</label>
              <input
                type="text"
                placeholder="Enter Range Title"
                class="form-control"
                name="title"
                value={title}
                onChange={handleInput}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.titleError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Anchor Value</label>
              <input
                type="text"
                placeholder="Enter Score"
                class="form-control"
                name="anchorValue"
                value={anchorValue}
                onChange={handleInputNumber}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.anchorValueError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Financial Value</label>
              <input
                type="text"
                placeholder="Enter Weightage in %"
                class="form-control"
                name="financialValue"
                value={financialValue}
                max={100}
                onChange={handleInputNumber}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.financialValueError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Non Financial Value</label>
              <input
                type="text"
                placeholder="Enter Weightage (New customer) in %"
                class="form-control"
                name="nonFinancialValue"
                value={nonFinancialValue}
                max={100}
                onChange={handleInputNumber}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.nonFinancialValueError}
              </span>
              <span style={{ color: "red", fontSize: "14px" }}>
                {nonFinancialError?.finalError}
              </span>
            </div>

            <div class="newBtn">
              <a class="Button" onClick={updateOverAllFunc}>
                Update
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={deleteModal}
        onHide={hideDeleteModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
     

<div className="Category">
  <button onClick={hideDeleteModal} className="CloseModal">×</button>
  <h3>Delete</h3>
  <figure>
    <img src="./static/media/deleteAlert.488dad788fffd1c04acd.png"   width={50}/>
  </figure>
  <p>Are you sure you want to delete this {modalName} rating  ?</p>
  <h4>
  <button className="Button" onClick={modalState === "nonFinancial" ?  removeNonFinanceFunc : modalState === "finance" ?  removeFinanceFunc : removeAnchorFunc}>Delete</button>   &nbsp;  &nbsp; 
    <button onClick={hideDeleteModal} className="Button">Cancel</button> 
   
  </h4>
</div>


        </Modal.Body>
      </Modal>


      <Modal
        show={addWeightageModal}
        onHide={hideAddWeightageModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
          <div class="Category">
            <a
              href="javascript:void(0);"
              class="CloseModal"
              onClick={hideAddWeightageModal}
            >
              ×
            </a>
            <h3>Update Weightage</h3>

           

            <div class="form-group">
              <label>Enter Weightage (Existing) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage in %"
                class="form-control"
                name="weightage"
                value={weightage}
                max={100}
                onChange={(e) =>handleInputNumber(e,  "edit")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {weightageError?.weightageError}
              </span>
            </div>

            <div class="form-group">
              <label>Enter Weightage (New) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage in %"
                class="form-control"
                name="weightageNewCustomer"
                value={weightageNewCustomer}
                max={100}
                onChange={(e) =>handleInputNumber(e, "edit")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
              {weightageError?.newWeightageError}
              </span>
            </div>

         
            
           
            <div class="newBtn">
              <a class="Button" onClick={updateWeigtageFunc}>
                Update Weightage
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* <Modal
        show={addNewWeightageModal}
        onHide={hideAddMewWeightageModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
          <div class="Category">
            <a
              href="javascript:void(0);"
              class="CloseModal"
              onClick={hideAddMewWeightageModal}
            >
              ×
            </a>
            <h3>Update New Weightage</h3>

           

            <div class="form-group">
              <label>Enter Weightage (New) in %</label>
              <input
                type="text"
                placeholder="Enter Weightage in %"
                class="form-control"
                name="weightageNewCustomer"
                value={weightageNewCustomer}
                max={100}
                onChange={(e) =>handleInputNumber(e, "weightage")}
              />
              <span style={{ color: "red", fontSize: "14px" }}>
                {newWeightageError}
              </span>
            </div>

         
            
           
            {/* <div class="newBtn"> */}
              {/* <a class="Button" onClick={updateNewWeigtageFunc}>
                Update Weightage
              </a>
            </div> 
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default ScoreManagementDetails;
