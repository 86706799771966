// let Url = "http://3.109.73.139:3000/api/v1/";

import axios from "axios";
import { authAction } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config";

//  ONE -------------- ADMIN LOGIN --------------  ONE//

export const adminLoginInitiate = () => ({
  type: authAction.ADMIN_LOGIN_INITIATE,
});

export const adminLoginSuccess = (data) => ({
  type: authAction.ADMIN_LOGIN_SUCCESS,
  payload: data,
});

export const adminLoginFailure = (data) => ({
  type: authAction.ADMIN_LOGIN_FAILURE,
  payload: data,
});

export function adminLoginAction(payload) {
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(adminLoginInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/adminLogin`, payload)
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
           window.localStorage.setItem("sgfinserv", JSON.stringify(response.data.activeUser));
            resolve(data);
            dispatch(adminLoginSuccess(data));
           
          } else {
            dispatch(adminLoginFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(adminLoginFailure(err));
          reject(err);
        });
    });
  };
}

// TWO -----------ADMIN DETAILS -------------- TWO //

export const adminDetailsInitate = () => ({
  type: authAction.ADMIN_DETAILS_INITIATE,
});

export const adminDetailsSuccess = (data) => ({
  type: authAction.ADMIN_DETAILS_SUCCESS,
  payload: data,
});

export const adminDetailsFailure = (data) => ({
  type: authAction.ADMIN_DETAILS_FAILURE,
  payload: data,
});

export function adminDetailsAction(payload) {
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    dispatch(adminDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/adminDetails`, payload,
        {
          headers: { Authorization: `${token}` },
        }
        )
        .then((response) => {
          const data = response.data;
          // console.log(data)
          if (data.status && data.status === 200) {
            // localStorage.setItem("adminData1",JSON.stringify(data))
            // console.log("adminDetails", response);
            resolve(response);
            dispatch(adminDetailsSuccess(data));
          } else {
            dispatch(adminDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(adminDetailsFailure(err));
          reject(err);
        });
    });
  };
}

// THREE  ----------- SEND OTP DETAILS --------------- THREE

export const sendOTPInitate = () => ({
  type: authAction.SEND_OTP_INITIATE,
});

export const sendOTPSuccess = (data) => ({
  type: authAction.SEND_OTP_SUCCESS,
  payload: data,
});

export const sendOTPFailure = (data) => ({
  type: authAction.SEND_OTP_FAILURE,
  payload: data,
});

export function sendOtpOnEmailAction(payload) {
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    dispatch(sendOTPInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/sentEmail`, payload,
        {
          headers: { Authorization: `${token}` },
        },
         
         )
        .then((response) => {
          const data = response.data;
          // console.log("response", response);
          if (response && response.status === 200) {
            // //console.log("SEND-OTP-DETAILS", data);

            dispatch(sendOTPSuccess(data));
            resolve(data);
          } else {
            dispatch(sendOTPFailure(data));
            reject(data)
          }
        })
        .catch((err) => {
          dispatch(sendOTPFailure(err));
          reject(err);
        });
    });
  };
}

// FOUR ------------ VERIFY OTP ---------------- FOUR//

export const verifyOTPInitate = () => ({
  type: authAction.VERIFY_OTP_INITIATE,
});

export const verifyOTPSuccess = (data) => ({
  type: authAction.VERIFY_OTP_SUCCESS,
  payload: data,
});

export const verifyOTPFailure = (data) => ({
  type: authAction.VERIFY_OTP_FAILURE,
  payload: data,
});

export const verifyOtpAction = (payload) => {
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    dispatch(verifyOTPInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/otpVerify`, payload,
        {
          headers: { Authorization: `${token}` },
        }
        )
        .then((response) => {
          const data = response.data;
          if (response && response.status == 200) {
            // console.log("verifyOTP,", data);
            dispatch(verifyOTPSuccess(data));
            resolve(data);
          } else {
            dispatch(verifyOTPFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(verifyOTPFailure(err));
          reject(err);
        });
    });
  };
};

// FIVE ------------  FORGET PASSWORD INITATE ---------------- FIVE//

export const forgetPasswordInitate = () => ({
  type: authAction.FORGET_PASSWORD_INITIATE,
});

export const forgetPasswordSuccess = (data) => ({
  type: authAction.FORGET_PASSWORD_SUCCESS,
  payload: data,
});

export const forgetPasswordFailure = (data) => ({
  type: authAction.FORGET_PASSWORD_FAILURE,
  payload: data,
});

export function forgetPassword(payload) {
  return (dispatch) => {
    dispatch(forgetPasswordInitate());

    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/setPassword`, payload)
        .then((response) => {
          const data = response.data;
          if (response && response.status == 200) {
            // console.log("forgetPassword", data);
            dispatch(forgetPasswordSuccess(data));
            resolve(data);
          } else {
            dispatch(forgetPasswordFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(forgetPasswordFailure(err));
          reject(err);
        });
    });
  };
}

// SIX ------------  CHANGE PASSWORD  ---------------- SIX//

export const changePasswordInitate = () => ({
  type: authAction.FORGET_PASSWORD_INITIATE,
});

export const changePasswordSuccess = (data) => ({
  type: authAction.FORGET_PASSWORD_SUCCESS,
  payload: data,
});

export const changePasswordFailure = (data) => ({
  type: authAction.FORGET_PASSWORD_FAILURE,
  payload: data,
});

export function changePassword(payload) {
  const token = isLoggedIn("adminData1")
  // console.log("token-----" , token)
  return (dispatch) => {
    dispatch(changePasswordInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/changePassword`, payload , {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (response && response.status === 200) {
            dispatch(changePasswordSuccess(data));
            // console.log("change Password ", data);
            resolve(data);
          } else {
            dispatch(changePasswordFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(changePasswordFailure(err));
          reject(err);
        });
    });
  };
}

// SEVEN --------------ADMIN LOGOUT------------------ SEVEN//

export const adminLogoutInitate = () => ({
  type: authAction.FORGET_PASSWORD_INITIATE,
});

export const adminLogoutSuccess = (data) => ({
  type: authAction.FORGET_PASSWORD_SUCCESS,
  payload: data,
});

export const adminLogoutFailure = (data) => ({
  type: authAction.FORGET_PASSWORD_FAILURE,
  payload: data,
});

export function adminLogout(payload) {
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    dispatch(adminDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/adminLogout`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data && data.status == 200) {
            dispatch(adminLoginSuccess(data));
            resolve(data);
            // console.log("adminLogout", data);
          } else {
            dispatch(adminLogoutFailure(data));
            reject(response);
          }
        })
        .catch((err) => {
          dispatch(adminLogoutFailure(err));
          reject(err);
        });
    });
  };
}


// // --------------DASHBOARD ----------//
export const dashobardInitiate = () => ({
  type: authAction.DASHBOARD_INITITATE,
});

export const dashboardSuccess = (data) => ({
  type: authAction.DASHBOARD_SUCCESS,
  payload: data,
});

export const dashboardFailure = (data) => ({
  type: authAction.DASHBOARD_SUCCESS,
  payload: data,
});

export function dashobardAction(payload) {
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    dispatch(dashobardInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/count`,  {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          console.log("response", response);
          const data = response;
          if (data.status && data.status == 200) {
            console.log("adminLogin", response);
            resolve(response.data);
            dispatch(dashboardSuccess(data.data));
          } else {
            dispatch(dashboardFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(dashboardFailure(err));
          reject(err);
        });
    });
  };
}





// --------------DASHBOARD ----------//
export const stateListInitiate = () => ({
  type: authAction.STATE_LIST_INITIATE,
});

export const stateListSuccess = (data) => ({
  type: authAction.STATE_LIST_SUCCESS,
  payload: data,
});

export const stateListFailure = (data) => ({
  type: authAction.STATE_LIST_SUCCESS,
  payload: data,
});

export function stateListAction(payload) {
  const token = isLoggedIn()
  console.log("token", token)
  return (dispatch) => {
    dispatch(stateListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/stateList?countryCode=IN`,  {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
            // console.log("adminLogin", response);
            resolve(response);
            dispatch(stateListSuccess(data));
          } else {
            dispatch(stateListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(stateListFailure(err));
          reject(err);
        });
    });
  };
}



// --------------DASHBOARD ----------//
export const cityListInitiate = () => ({
  type: authAction.CITY_LIST_INITIATE,
});

export const cityListSuccess = (data) => ({
  type: authAction.CITY_LIST_SUCCESS,
  payload: data,
});

export const cityListFailure = (data) => ({
  type: authAction.CITY_LIST_SUCCESS,
  payload: data,
});

export function cityListAction(id) {
  const token = isLoggedIn()
  // console.log("token", token)
  return (dispatch) => {
    dispatch(cityListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/cityList?countryCode=IN&stateCode=${id}`,  {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
            // console.log("adminLogin", response);
            resolve(response);
            dispatch(cityListSuccess(data));
          } else {
            dispatch(cityListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(cityListFailure(err));
          reject(err);
        });
    });
  };
}





// --------------DASHBOARD ----------//
export const getTopListInitiate = () => ({
  type: authAction.TOP_CLIENT_LIST_INITIATE,
});

export const getTopListSuccess = (data) => ({
  type: authAction.TOP_CLIENT_LIST_SUCCESS,
  payload: data,
});

export const getTopListFailure = (data) => ({
  type: authAction.TOP_CLIENT_LIST_SUCCESS,
  payload: data,
});

export function getTopListAction(id) {
  const token = isLoggedIn()
  // console.log("token", token)
  return (dispatch) => {
    dispatch(getTopListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/topClientList`,  {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
            // console.log("adminLogin", response);
            resolve(response.data);
            dispatch(getTopListSuccess(data));
          } else {
            dispatch(getTopListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getTopListFailure(err));
          reject(err);
        });
    });
  };
}


export const getLowestListInitiate = () => ({
  type: authAction.LOWEST_CLIENT_LIST_INITIATE,
});

export const getLowestListSuccess = (data) => ({
  type: authAction.LOWEST_CLIENT_LIST_SUCCESS,
  payload: data,
});

export const getLowestListFailure = (data) => ({
  type: authAction.LOWEST_CLIENT_LIST_SUCCESS,
  payload: data,
});

export function getLowestListAction(id) {
  const token = isLoggedIn()
  // console.log("token", token)
  return (dispatch) => {
    dispatch(getLowestListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/lowestClientList`,  {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
            // console.log("adminLogin", response);
            resolve(response.data);
            dispatch(getLowestListSuccess(data));
          } else {
            dispatch(getLowestListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getLowestListFailure(err));
          reject(err);
        });
    });
  };
}


