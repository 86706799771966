import axios from "axios";
import { staffManagementAction } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config";


export const addStaffInitiate = () => ({
    type: staffManagementAction.ADD_STAFF_INITIATE,
  });
  
  export const addStaffSuccess = (data) => ({
    type: staffManagementAction.ADD_STAFF_SUCCESS,
    payload: data,
  });
  
  export const addStaffFailure = (data) => ({
    type: staffManagementAction.ADD_STAFF_FAILURE,
    payload: data,
  });
  
  export function addstaffManagementAction(payload) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(addStaffInitiate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}admin/addStaff`, payload, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(addStaffSuccess(data));
             
            } else {
              dispatch(addStaffFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(addStaffFailure(err));
            reject(err);
          });
      });
    };
  }
  

  
export const staffListInitiate = () => ({
    type: staffManagementAction.STAFF_LIST_INITIATE,
  });
  
  export const staffListSuccess = (data) => ({
    type: staffManagementAction.STAFF_LIST_SUCCESS,
    payload: data,
  });
  
  export const staffListFailure = (data) => ({
    type: staffManagementAction.STAFF_LIST_FAILURE,
    payload: data,
  });
  
  export function staffListAction(search, pageNumber) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(staffListInitiate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/staffList?search=${search? search:""}&page=${pageNumber ? pageNumber :""}`, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(staffListSuccess(data));
             
            } else {
              dispatch(staffListFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("errrrrrrrr",err);
            if(err.response.data.code === 404){
              resolve(err);
              dispatch(staffListSuccess(err));
            }else{
              dispatch(staffListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  


  
export const removeStaffInitiate = () => ({
  type: staffManagementAction.REMOVE_STAFF_INITIATE,
});

export const removeStaffSuccess = (data) => ({
  type: staffManagementAction.REMOVE_STAFF_SUCCESS,
  payload: data,
});

export const removeStaffFailure = (data) => ({
  type: staffManagementAction.REMOVE_STAFF_FAILURE,
  payload: data,
});

export function removestaffManagementAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(removeStaffInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/removeStaff`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(removeStaffSuccess(data));
           
          } else {
            dispatch(removeStaffFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeStaffFailure(err));
          reject(err);
        });
    });
  };
}




export const updateStaffInitiate = () => ({
  type: staffManagementAction.UPDATE_STAFF_INITIATE,
});

export const updateStaffSuccess = (data) => ({
  type: staffManagementAction.UPDATE_STAFF_SUCCESS,
  payload: data,
});

export const updateStaffFailure = (data) => ({
  type: staffManagementAction.UPDATE_STAFF_FAILURE,
  payload: data,
});

export function updatestaffManagementAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateStaffInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editStaff`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateStaffSuccess(data));
           
          } else {
            dispatch(updateStaffFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateStaffFailure(err));
          reject(err);
        });
    });
  };
}



export const staffDetailsInitiate = () => ({
  type: staffManagementAction.STAFF_DETAILS_INITIATE,
});

export const staffDetailsSuccess = (data) => ({
  type: staffManagementAction.STAFF_DETAILS_SUCCESS,
  payload: data,
});

export const staffDetailsFailure = (data) => ({
  type: staffManagementAction.STAFF_DETAILS_FAILURE,
  payload: data,
});

export function staffDetailsAction(id) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(staffDetailsInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/staffDetails?id=${id}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(staffDetailsSuccess(data));
           
          } else {
            dispatch(staffDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(staffDetailsFailure(err));
          reject(err);
        });
    });
  };
}




export const changeStaffStatusInitiate = () => ({
  type: staffManagementAction.STAFF_STATUS_CHANGE_INITIATE,
});

export const changeStaffStatusSuccess = (data) => ({
  type: staffManagementAction.STAFF_STATUS_CHANGE_SUCCESS,
  payload: data,
});

export const changeStaffStatusFailure = (data) => ({
  type: staffManagementAction.STAFF_STATUS_CHANGE_FAILURE,
  payload: data,
});

export function changeStaffStatusAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(changeStaffStatusInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/statusStaff`,payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(changeStaffStatusSuccess(data));
           
          } else {
            dispatch(changeStaffStatusFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(changeStaffStatusFailure(err));
          reject(err);
        });
    });
  };
}



