import axios from "axios";
import { clientManagementAction } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config";
import { multiPartData } from "../../utils/uploadFile";



  

  
export const clientListInitiate = () => ({
    type: clientManagementAction.CLIENT_LIST_INITIATE,
  });
  
  export const clientListSuccess = (data) => ({
    type: clientManagementAction.CLIENT_LIST_SUCCESS,
    payload: data,
  });
  
  export const clientListFailure = (data) => ({
    type: clientManagementAction.CLIENT_LIST_FAILURE,
    payload: data,
  });
  
  export function clientListAction(search, startDate, endDate,timeFrame,pageNumber, limit) {
    const token = isLoggedIn("adminData1")
        // console.log("===========>", payload)
    return (dispatch) => {
      dispatch(clientListInitiate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/clientList?search=${search? search:""}&startDate=${startDate ? startDate :""}&endDate=${endDate ? endDate :""}&timeframe=${timeFrame ? timeFrame:""}&page=${pageNumber ? pageNumber :""}&limit=${limit ? limit : ""}`, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => {
            // console.log("response", response);
            const data = response.data;
            if (response.status && response.status === 200) {
           
              resolve(data);
              dispatch(clientListSuccess(data));
             
            } else {
              dispatch(clientListFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("errrrrrrrr",err);
            if(err.response.data.code === 404){
              resolve(err);
              dispatch(clientListSuccess(err));
            }else{
              dispatch(clientListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }







  
  
export const unapprovedListInitiate = () => ({
  type: clientManagementAction.UNAPPROVED_LIST_INITIATE,
});

export const unapprovedListSuccess = (data) => ({
  type: clientManagementAction.UNAPPROVED_LIST_SUCCESS,
  payload: data,
});

export const unapprovedListFailure = (data) => ({
  type: clientManagementAction.UNAPPROVED_LIST_FAILURE,
  payload: data,
});

export function unapprovedListAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(unapprovedListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/pendingClientList`, {
          headers: { Authorization: `${token}` },
          params:payload,
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(unapprovedListSuccess(data));
           
          } else {
            dispatch(unapprovedListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(unapprovedListSuccess(err));
          }else{
            dispatch(unapprovedListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}

  


export const changeRatingStatusInitiate = () => ({
  type: clientManagementAction.RATING_STATUS_INITIATE,
});

export const changeRatingStatusSuccess = (data) => ({
  type: clientManagementAction.RATING_STATUS_SUCCESS,
  payload: data,
});

export const changeRatingStatusFailure = (data) => ({
  type: clientManagementAction.RATING_STATUS_FAILURE,
  payload: data,
});

export function changeRatingStatusAction(status) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(changeRatingStatusInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/approvedStatus`, status, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(changeRatingStatusSuccess(data));
           
          } else {
            dispatch(changeRatingStatusFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(changeRatingStatusSuccess(err));
          }else{
            dispatch(changeRatingStatusFailure(err));
            reject(err);
          }
         
        });
    });
  };
}

  


  




export const updateClientInitiate = () => ({
  type: clientManagementAction.UPDATE_CLIENT_INITIATE,
});

export const updateClientSuccess = (data) => ({
  type: clientManagementAction.UPDATE_CLIENT_SUCCESS,
  payload: data,
});

export const updateClientFailure = (data) => ({
  type: clientManagementAction.UPDATE_CLIENT_FAILURE,
  payload: data,
});

export function updateclientManagementAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateClientInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/editClient`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateClientSuccess(data));
           
          } else {
            dispatch(updateClientFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateClientFailure(err));
          reject(err);
        });
    });
  };
}



export const clientDetailsInitiate = () => ({
  type: clientManagementAction.CLIENT_DETAILS_INITIATE,
});

export const clientDetailsSuccess = (data) => ({
  type: clientManagementAction.CLIENT_DETAILS_SUCCESS,
  payload: data,
});

export const clientDetailsFailure = (data) => ({
  type: clientManagementAction.CLIENT_DETAILS_FAILURE,
  payload: data,
});

export function clientDetailsAction(id,anchorId) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(clientDetailsInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/clientDetails?id=${id}&anchorId=${anchorId??""}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(clientDetailsSuccess(data));
           
          } else {
            dispatch(clientDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(clientDetailsFailure(err));
          reject(err);
        });
    });
  };
}




export const changeClientStatusInitiate = () => ({
  type: clientManagementAction.CLIENT_STATUS_CHANGE_INITIATE,
});

export const changeClientStatusSuccess = (data) => ({
  type: clientManagementAction.CLIENT_STATUS_CHANGE_SUCCESS,
  payload: data,
});

export const changeClientStatusFailure = (data) => ({
  type: clientManagementAction.CLIENT_STATUS_CHANGE_FAILURE,
  payload: data,
});

export function changeClientStatusAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(changeClientStatusInitiate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}admin/statusClient`,payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(changeClientStatusSuccess(data));
           
          } else {
            dispatch(changeClientStatusFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(changeClientStatusFailure(err));
          reject(err);
        });
    });
  };
}




export const getNonFinanceInitiate = () => ({
  type: clientManagementAction.GET_NON_FINANCE_DETAILS_INITIATE,
});

export const getNonFinanceSuccess = (data) => ({
  type: clientManagementAction.GET_NON_FINANCE_DETAILS_SUCCESS,
  payload: data,
});

export const getNonFinanceFailure = (data) => ({
  type: clientManagementAction.GET_NON_FINANCE_DETAILS_FAILURE,
  payload: data,
});

export function getNonFinanceAction(search) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(getNonFinanceInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/nonFinanceRiskDataForRating?anchorId=${search? search:""}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(getNonFinanceSuccess(data));
           
          } else {
            dispatch(getNonFinanceFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(getNonFinanceSuccess(err));
          }else{
            dispatch(getNonFinanceFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const addNonFinanceRatingInitiate = () => ({
  type: clientManagementAction.ADD_NONFINANCIAL_RATING_INITIATE,
});

export const addNonFinanceRatingSuccess = (data) => ({
  type: clientManagementAction.ADD_NONFINANCIAL_RATING_SUCCESS,
  payload: data,
});

export const addNonFinanceRatingFailure = (data) => ({
  type: clientManagementAction.ADD_NONFINANCIAL_RATING_FAILURE,
  payload: data,
});

export function addNonFinanceRatingAction(search) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(addNonFinanceRatingInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/addRating`, search, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(addNonFinanceRatingSuccess(data));
           
          } else {
            dispatch(addNonFinanceRatingFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(addNonFinanceRatingSuccess(err));
          }else{
            dispatch(addNonFinanceRatingFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const getFinanceListInitiate = () => ({
  type: clientManagementAction.GET_FINANCE_DETAILS_INITIATE,
});

export const getFinanceListSuccess = (data) => ({
  type: clientManagementAction.GET_FINANCE_DETAILS_SUCCESS,
  payload: data,
});

export const getFinanceListFailure = (data) => ({
  type: clientManagementAction.GET_FINANCE_DETAILS_FAILURE,
  payload: data,
});

export function getFinanceListAction(search) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(getFinanceListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/financeRiskDataNew?anchorId=${search?search:""}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(getFinanceListSuccess(data));
           
          } else {
            dispatch(getFinanceListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(getFinanceListSuccess(err));
          }else{
            dispatch(getFinanceListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const getScoreInitiate = () => ({
  type: clientManagementAction.GET_SCORE_INITIATE,
});

export const getScoreSuccess = (data) => ({
  type: clientManagementAction.GET_SCORE_SUCCESS,
  payload: data,
});

export const getScoreFailure = (data) => ({
  type: clientManagementAction.GET_SCORE_FAILURE,
  payload: data,
});

export function getScoreAction(id) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(getScoreInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/getScoreById?id=${id}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(getScoreSuccess(data));
           
          } else {
            dispatch(getScoreFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(getScoreSuccess(err));
          }else{
            dispatch(getScoreFailure(err));
            reject(err);
          }
         
        });
    });
  };
}



export const updateNonFinancialInitiate = () => ({
  type: clientManagementAction.UPDATE_NON_FINANCIAL_RATING_INITIATE,
});

export const updateNonFinancialSuccess = (data) => ({
  type: clientManagementAction.UPDATE_NON_FINANCIAL_RATING_SUCCESS,
  payload: data,
});

export const updateNonFinancialFailure = (data) => ({
  type: clientManagementAction.UPDATE_NON_FINANCIAL_RATING_FAILURE,
  payload: data,
});

export function updateNonFinancialManagementAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateNonFinancialInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/updateNonFinanceRating`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateNonFinancialSuccess(data));
           
          } else {
            dispatch(updateNonFinancialFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateNonFinancialFailure(err));
          reject(err);
        });
    });
  };
}




export const updateFinancialInitiate = () => ({
  type: clientManagementAction.UPDATE_FINANCIAL_RATING_INITIATE,
});

export const updateFinancialSuccess = (data) => ({
  type: clientManagementAction.UPDATE_FINANCIAL_RATING_SUCCESS,
  payload: data,
});

export const updateFinancialFailure = (data) => ({
  type: clientManagementAction.UPDATE_FINANCIAL_RATING_FAILURE,
  payload: data,
});

export function updateFinancialManagementAction(payload) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(updateFinancialInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/updateFinanceRating`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(updateFinancialSuccess(data));
           
          } else {
            dispatch(updateFinancialFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateFinancialFailure(err));
          reject(err);
        });
    });
  };
}




export function downloadReportAction(payload) {
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/reportGen`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {        
            resolve(data);         
          } else {         
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}




export function uploadBulkDataAction(payload) {
  const updatedData = multiPartData({ files: payload.files, ...payload })
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/uploadFile`, updatedData, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {        
            resolve(data);         
          } else {         
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}


export function clientDownloadListAction(search, startDate, endDate,timeFrame,pageNumber, limit) {
  const token = isLoggedIn("adminData1")
      // console.log("===========>", payload)
  return (dispatch) => {
    dispatch(clientListInitiate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/clientList?search=${search? search:""}&startDate=${startDate ? startDate :""}&endDate=${endDate ? endDate :""}&timeframe=${timeFrame ? timeFrame:""}&page=${pageNumber ? pageNumber :""}&limit=${limit ? limit : ""}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {
         
            resolve(data);
            dispatch(clientListSuccess(data));
           
          } else {
            dispatch(clientListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrrrrrrr",err);
          if(err.response.data.code === 404){
            resolve(err);
            dispatch(clientListSuccess(err));
          }else{
            dispatch(clientListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export function uploadJSON(payload) {
  const updatedData = multiPartData({ files: payload.files, ...payload })
  const token = isLoggedIn("adminData1")
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}admin/uploadJson`, updatedData, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // console.log("response", response);
          const data = response.data;
          if (response.status && response.status === 200) {        
            resolve(data);         
          } else {         
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}