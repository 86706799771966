
import './App.css';
import React, { useEffect } from "react";
import "./App.css";
import {
  Route,
  HashRouter,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from './Components/login';
import Loginforgot from './Components/login-forgot';
import Changepassword from './Components/create-password';
import Header from './Components/Header';
import Sidenav from './Components/Sidenav';
import Dashboard from './Components/dashboard';
import { isLoggedIn } from './utils/tokenCheck';
import Anchoradd from './Components/anchor-add';
import Anchoredit from './Components/anchor-edit';
import Anchordetails from './Components/anchor-details';
import Anchormanagement from './Components/anchor-management';
import Anchorpendingaccess from './Components/anchor-pending-access';
import Anchorpendingapproval from './Components/anchor-pending-approval';
import Anchorpendingview from './Components/anchor-pending-view';
import Clientfinancialrating from './Components/client-financial-rating';
import Clientmanagement from './Components/client-management';
import CientNonfinancialRating from './Components/client-nonfinancial-rating';
import Clientscorependingdetails from './Components/client-score-pending-details';
import ClientScorePending from './Components/client-score-pending';
import ClientScoringDetails from './Components/client-scoring-details';
import Clientscoringfinancial from './Components/client-scoring-financial';
import Clientscoringnonfinancial from './Components/client-scoring-non-financial';
import Clientstartscoring from './Components/client-start-scoring';
import Clientviewscoredetailapprove from './Components/client-view-score-detail-approve';
import Clientviewscoredetails from './Components/client-view-score-details';
import Clientviewscore from './Components/client-view-score';
import Loginverification from './Components/login-verification';
import Staffmanagment from './Components/staff-managment';
import Staffmanagmentadd from './Components/staff-managment-add';
import StaffManagementdetails from './Components/staff-management-details';
import StaffManagmentEdit from './Components/staff-managment-edit';
import Scoremanagement from './Components/score-management';
import ScoreManagementDetails from './Components/score-management-details';
import Loginconfirm from './Components/login-confirm';
import Setting from './Components/setting';
import SubAdminDashboard from './Components/subAdminDashboard';
import Anchoruserlist from './Components/anchor-user-list';
import ClientAnchorrating from './Components/client-anchor-rating';
function PrivateRoute({ component: Component, userType, ...rest }) {
  let isLogged = false;
  const checkAuth = () => {
    return isLoggedIn("ownerLogin");
  }

  if (checkAuth()) {
    isLogged = true;
  }
  return isLogged ? <Component checkAuth={checkAuth} /> : <Navigate to="/" />;
}

function App() {
  return (
    <React.Fragment>
      
    <ToastContainer />
  
    <HashRouter>
      <Routes>
        
        <Route path="/" element={<Login />} />
        <Route path="/login-forgot" element={<Loginforgot />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login-verification" element={<Loginverification />} />
        <Route path="/login-confirm" element={<Loginconfirm />} />
        <Route path="/change-password" element={<Changepassword/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/anchor-add" element={<Anchoradd />} />
        <Route path="/anchor-edit" element={<Anchoredit />} />
        <Route path="/anchor-details" element={<Anchordetails />} />
        <Route path="/anchor-management" element={<Anchormanagement />} />
        <Route path="/anchor-user-list" element={<Anchoruserlist />} />
        <Route path="/anchor-pending-access" element={<Anchorpendingaccess />} />
        <Route path="/anchor-pending-approval" element={<Anchorpendingapproval />} />
        <Route path="/anchor-pending-view" element={<Anchorpendingview />} />
        <Route path="/client-financial-rating" element={<Clientfinancialrating />} />
        <Route path="/client-management" element={<Clientmanagement/>} />
        <Route path="/client-nonfinancial-rating" element={<CientNonfinancialRating/>} />
        <Route path="/client-anchor-rating" element={<ClientAnchorrating />} />
        <Route path="/client-score-pending-details" element={<Clientscorependingdetails />} />
        <Route path="/client-score-pending" element={<ClientScorePending />} />
        <Route path="/client-scoring-details" element={<ClientScoringDetails />} />
        <Route path="/client-scoring-financial" element={<Clientscoringfinancial />} />
        <Route path="/client-scoring-non-financial" element={<Clientscoringnonfinancial />} />
        <Route path="/client-start-scoring" element={<Clientstartscoring />} />
        <Route path="/client-view-score-detail-approve" element={<Clientviewscoredetailapprove />} />
        <Route path="/client-view-score-details" element={<Clientviewscoredetails />} />
        <Route path="/client-view-score" element={<Clientviewscore />} />
        <Route path="/staff-management" element={<Staffmanagment />} />
        <Route path="/staff-details" element={<StaffManagementdetails />} />
        
        <Route path="/staff-management-add" element={<Staffmanagmentadd />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/staff-management-edit" element={<StaffManagmentEdit />} />
        <Route path="/subadmin-dashboard" element={<SubAdminDashboard />} />
        <Route path="/score-management" element={<Scoremanagement />} />
        <Route path="/score-management-details" element={<ScoreManagementDetails />} />
      </Routes>
    </HashRouter>
  </React.Fragment>
  );
}

export default App;
