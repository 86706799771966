import { anchorAction, authAction, clientManagementAction, scoreManagementAction,staffManagementAction } from "../actionTypes";

const initialState = {
  stateListData: [],
   cityListData:[],
  loader: false,
  loginStatus: false,
  adminDetails: {},
  anchorListData:[],
  userListData:[],
  anchorDropdownData:[],
  overAllWeightageData:[],
  anchorRiskListData:[],
  nonFinancialRiskListData:[],
  clientListData:[],
  unapprovedListData:[],
  financialRiskListData:[],
  topListData:[],
  lowestListData:[],
  anchorUserListData:[],
  
};

export default function mainReducer(state = initialState, { type, payload }) {
  switch (type) {
    case authAction.STATE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        stateListData: {},
      };
    }
    case authAction.STATE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        stateListData: payload,
      };
    }
    case authAction.STATE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        stateListData: payload.data,
      };
    }

    case authAction.CITY_LIST_INITIATE: {
        return {
          ...state,
          loader: true,
          cityListData: {},
        };
      }
      case authAction.CITY_LIST_SUCCESS: {
        return {
          ...state,
          loader: false,
          cityListData: payload,
        };
      }
      case authAction.CITY_LIST_FAILURE: {
        return {
          ...state,
          loader: false,
          cityListData: payload.data,
        };
      }

      
    case anchorAction.ANCHOR_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        anchorListData: {},
      };
    }
    case anchorAction.ANCHOR_LIST_SUCCESS: {
      console.log("payload", payload.data);
      // window.localStorage.setItem("adminData", JSON.stringify(payload.data));
      return {
        ...state,
        loader: false,
        anchorListData: payload,
      };
    }
    case anchorAction.ANCHOR_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        anchorListData: payload.data,
      };
    }


    case anchorAction.USER_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        userListData: [],
      };
    }
    case anchorAction.USER_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        userListData: payload,
      };
    }
    case anchorAction.USER_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        userListData: payload.data,
      };
    }

    
    case anchorAction.ANCHOR_DROPDOWN_INITIATE: {
      return {
        ...state,
        loader: true,
        anchorDropdownData: [],
      };
    }
    case anchorAction.ANCHOR_DROPDOWN_SUCCESS: {
      return {
        ...state,
        loader: false,
        anchorDropdownData: payload,
      };
    }
    case anchorAction.ANCHOR_DROPDOWN_FAILURE: {
      return {
        ...state,
        loader: false,
        anchorDropdownData: payload.data,
      };
    }

    
    
    case staffManagementAction.STAFF_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        staffListData: [],
      };
    }
    case staffManagementAction.STAFF_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        staffListData: payload,
      };
    }
    case staffManagementAction.STAFF_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        staffListData: payload.data,
      };
    }





    case scoreManagementAction.NON_FINANCIAL_RISK_LIST_INITIATE: {
      return {
        ...state,
        // loader: true,
        nonFinancialRiskListData: [],
      };
    }
    case scoreManagementAction.NON_FINANCIAL_RISK_LIST_SUCCESS: {
    
      return {
        ...state,
        // loader: false,
        nonFinancialRiskListData: payload,
      };
    }
    case scoreManagementAction.NON_FINANCIAL_RISK_LIST_FAILURE: {
      return {
        ...state,
        // loader: false,
        nonFinancialRiskListData: payload.data,
      };
    }



    case scoreManagementAction.OVERALL_WEIGHTAGE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        overAllWeightageData: [],
      };
    }
    case scoreManagementAction.OVERALL_WEIGHTAGE_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        overAllWeightageData: payload,
      };
    }
    case scoreManagementAction.OVERALL_WEIGHTAGE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        overAllWeightageData: payload.data,
      };
    }



    case scoreManagementAction.ANCHOR_RISK_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        anchorRiskListData: [],
      };
    }
    case scoreManagementAction.ANCHOR_RISK_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        anchorRiskListData: payload,
      };
    }
    case scoreManagementAction.ANCHOR_RISK_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        anchorRiskListData: payload.data,
      };
    }


    
    case clientManagementAction.CLIENT_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        clientListData: [],
      };
    }
    case clientManagementAction.CLIENT_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        clientListData: payload,
      };
    }
    case clientManagementAction.CLIENT_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        clientListData: payload.data,
      };
    }


     
    case anchorAction.ANCHOR_USER_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        anchorUserListData: [],
      };
    }
    case anchorAction.ANCHOR_USER_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        anchorUserListData: payload,
      };
    }
    case anchorAction.ANCHOR_USER_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        anchorUserListData: payload.data,
      };
    }


    case clientManagementAction.GET_NON_FINANCE_DETAILS_INITIATE: {
      return {
        ...state,
        loader: true,
        nonFinancialDetailsData: [],
      };
    }
    case clientManagementAction.GET_NON_FINANCE_DETAILS_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        nonFinancialDetailsData: payload,
      };
    }
    case clientManagementAction.GET_NON_FINANCE_DETAILS_FAILURE: {
      return {
        ...state,
        loader: false,
        nonFinancialDetailsData: payload.data,
      };
    }


    case clientManagementAction.UNAPPROVED_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        unapprovedListData: [],
      };
    }
    case clientManagementAction.UNAPPROVED_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        unapprovedListData: payload,
      };
    }
    case clientManagementAction.UNAPPROVED_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        unapprovedListData: payload.data,
      };
    }

   
    case clientManagementAction.GET_FINANCE_DETAILS_INITIATE: {
      return {
        ...state,
        loader: true,
        financeRiskListData: [],
      };
    }
    case clientManagementAction.GET_FINANCE_DETAILS_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        financeRiskListData: payload,
      };
    }
    case clientManagementAction.GET_FINANCE_DETAILS_FAILURE: {
      return {
        ...state,
        loader: false,
        financeRiskListData: payload.data,
      };
    }


    case authAction.TOP_CLIENT_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        topListData: [],
      };
    }
    case authAction.TOP_CLIENT_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        topListData: payload,
      };
    }
    case authAction.TOP_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        topListData: payload.data,
      };
    }


    case authAction.LOWEST_CLIENT_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        lowestListData: [],
      };
    }
    case authAction.LOWEST_CLIENT_LIST_SUCCESS: {
    
      return {
        ...state,
        loader: false,
        lowestListData: payload,
      };
    }
    case authAction.LOWEST_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        lowestListData: payload.data,
      };
    }

   

    default:
      return state;
  }
}
