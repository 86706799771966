import { toast } from "react-toastify";

export const errorToast=(msg)=>{
    toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3500
    });
}

export const sucessToast=(msg)=>{
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3500
    });
}