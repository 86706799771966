import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidenav from './Sidenav'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { anchorDetailsAction, changeUserStatusAction } from '../redux/actions/anchorAction';
import Loader from './Loader';
// import deleteAlert from '../assets/images/deleteAlert.png'
import { checkArray } from '../utils/CheckType';
import { sucessToast } from '../utils/toast';
import { Modal } from 'react-bootstrap';
// import PDF from "../assets/images/PDF.png"
const init = {
  companyName:"",
  natureOfBuisness: "",
  anchor_number:"",
  cnnNumber: "",
  companyAddress: "",
  stateName: "",
  city: "",
  pinCode: "",
  contactPersonName: "",
  contactPersonNumber: "",
  contactPersonEmail: "",
  designation: "",
  gstNumber: "",
  gstFront: "",
  gstBack: "",
  panNumber: "",
  panCertificate: "" ,
  errors:{},
  userAccessListData:[],
  blockModal:false,unblockModal:false, modalId:"", status:"",
  deleteModal:false,
}
function Anchordetails() {
  const [iState,updateState]= useState(init);
  const {state } = useLocation();
  const dispatch = useDispatch();
  console.log("statetetete", state)
  const {
    companyName,
    natureOfBuisness,
    cnnNumber,
    companyAddress,
    stateName,
    city,
    pinCode,
    contactPersonName,
    contactPersonNumber,
    contactPersonEmail,
    designation,
    gstNumber,
    gstFront,
    gstBack,
    panNumber,
    panCertificate,
    anchor_number,
    createdAt,
    errors,
    userAccessListData,
    blockModal,unblockModal, modalId, status,
    deleteModal,
  } = iState
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(anchorDetailsAction(state)).then((res) => {
      // console.log("ressssssss", res)
      const { companyName, anchor_number ,natureOfBuisness, createdAt,cnnNumber, companyAddress, state, city, pinCode, contactPersonName, contactPersonNumber, contactPersonEmail, designation, gstNumber, gstFront, gstBack, panNumber, panCertificate, userData } = res?.data?.[0];
      const updatedState = {
        companyName,
        natureOfBuisness,
        cnnNumber,
        anchor_number,
        companyAddress,
        stateName: state, // assuming state is mapped to stateName
        city,
        pinCode,
        contactPersonName,
        contactPersonNumber,
        contactPersonEmail,
        designation,
        gstNumber,
        gstFront,
        gstBack,
        panNumber,
        panCertificate,
        errors: {},
        createdAt,
        userAccessListData:userData
      };

      updateState(updatedState);
    })
  },[])
  
  const removeAnchorFunc = () => {
    dispatch(changeUserStatusAction({id :modalId, status:"DELETED"})).then((res) => {
      console.log("ressss",res)
      sucessToast("User removed successfully")
      dispatch(anchorDetailsAction(state)).then((res) => {
        // console.log("ressssssss", res)
        const { companyName, anchor_number ,natureOfBuisness, createdAt,cnnNumber, companyAddress, state, city, pinCode, contactPersonName, contactPersonNumber, contactPersonEmail, designation, gstNumber, gstFront, gstBack, panNumber, panCertificate, userData } = res?.data?.[0];
        const updatedState = {
          companyName,
          natureOfBuisness,
          cnnNumber,
          anchor_number,
          companyAddress,
          stateName: state, // assuming state is mapped to stateName
          city,
          pinCode,
          contactPersonName,
          contactPersonNumber,
          contactPersonEmail,
          designation,
          gstNumber,
          gstFront,
          gstBack,
          panNumber,
          panCertificate,
          errors: {},
          createdAt,
          userAccessListData:userData
        };
  
        updateState(updatedState);
      })
      hideDeleteModal()
    })

  }


  const changeAnchorStatusFunc = ( ) => {
    dispatch(changeUserStatusAction({id:modalId, status:"INACTIVE"})).then((res) => {
      console.log("ressss",res)
      sucessToast("Status changed successfully")
      dispatch(anchorDetailsAction(state)).then((res) => {
        // console.log("ressssssss", res)
        const { companyName, anchor_number ,natureOfBuisness, createdAt,cnnNumber, companyAddress, state, city, pinCode, contactPersonName, contactPersonNumber, contactPersonEmail, designation, gstNumber, gstFront, gstBack, panNumber, panCertificate, userData } = res?.data?.[0];
        const updatedState = {
          companyName,
          natureOfBuisness,
          cnnNumber,
          anchor_number,
          companyAddress,
          stateName: state, // assuming state is mapped to stateName
          city,
          pinCode,
          contactPersonName,
          contactPersonNumber,
          contactPersonEmail,
          designation,
          gstNumber,
          gstFront,
          gstBack,
          panNumber,
          panCertificate,
          errors: {},
          createdAt,
          userAccessListData:userData
        };
  
        updateState(updatedState);
      })
      hideBlockModal()
      hideUnblockModal()
    })
  }


  
  const showBlockModal = (_id, status) => {
    updateState({...iState, blockModal:true, modalId:_id, status:status})
  }

  
  const hideBlockModal = () => {
    updateState({...iState, blockModal:false})
  }


  const showUnblockModal = (_id, status) => {
    updateState({...iState, blockModal:true, modalId:_id, status:status})
  }

  
  const hideUnblockModal = () => {
    updateState({...iState, blockModal:false})
  }

  const showDeleteModal = (_id) => {
    updateState({...iState, deleteModal:true, modalId:_id})
  }
  
  
  const hideDeleteModal = () => {
    updateState({...iState, deleteModal:false, modalId:""})
  }


  const goToAnchorAccessFunc = (id) => {
    let data = {
       _id:id,
       state:"anchorDetails",
       anchorId:state

    }
    navigate(`/anchor-pending-access`, {state:data})
  }
  return (
   <>
   <Header/>
   <Sidenav/>
   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="TitleBox">
        <h4 className="Title">
          <Link to="/anchor-management" className="backArrow" state={state}>
            <i className="fa fa-arrow-left" aria-hidden="true" />
          </Link>{" "}
          Anchor Details
        </h4>
      </div>
      <div className="BusinessPersonal">
        <figure>
          <img src="images/Avatar-1.png" />
        </figure>
        <figcaption>
          <h3>{companyName}</h3>
          <p>
            <strong>Anchor ID : </strong> {anchor_number}
          </p>
        </figcaption>
        <div className="Actions">
          <Link className="Button" to="/anchor-edit" state={state}>
            Edit
            <i className="fa fa-pencil" />
          </Link>
        </div>
      </div>
    </div>
    <div className="InformationBox">
      <h4>Basic Details</h4>
      <div className="Information">
        <article>
          <aside>
            <p>
              <strong>Anchor ID </strong> <span>{anchor_number}</span>
            </p>
            <p>
              <strong>Anchor Name </strong>{" "}
              <span> {companyName}</span>
            </p>
            <p>
              <strong>Anchor Address</strong>{" "}
              <span>{companyAddress}, {city}</span>
            </p>
            <p>
              <strong>Contact Number</strong> <span>{contactPersonNumber}</span>
            </p>
          </aside>
          <aside>
            <p>
              <strong>Email Address</strong> <span>{contactPersonEmail}</span>
            </p>
            <p>
              <strong>Nature of Business</strong> <span> {natureOfBuisness}</span>
            </p>
            <p>
              <strong>Date Registered on</strong> <span>{createdAt?.split("T")[0]}</span>
            </p>
          </aside>
        </article>
      </div>
    </div>
    <div className="TopTittle">
      <h4>Documents Uploaded </h4>
    </div>
    <div className="row">
      <div className="col-sm-6">
        <div className="InformationBox">
          <h4>Pan Card Details </h4>
          <div className="Information InformationDocumnet">
            <article>
              <aside>
                <p>
                  <strong>Pan Card Number</strong>
                  <span>{panNumber}</span>
                </p>
                {/* <p>
                  <strong>Name as per Pan Card</strong>
                  <span>Mr. john </span>
                </p> */}
              </aside>
              <aside>
                <div className="DocumnetBox">
                  <figcaption>
                    <span>
                    {panCertificate ? 
                    panCertificate.split(".")[panCertificate.split(".").length -1] == "pdf" ? <>
                  
                    <a href={panCertificate} target='_blank'>  <img src="images/PDF.png"/></a>
                    </>: <><img src={panCertificate}/></>
                   : <>
                  </>}
                    </span>
                    <label>Front</label>
                  </figcaption>
                 
                </div>
              </aside>
            </article>
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="InformationBox">
          <h4>GSTIN</h4>
          <div className="Information InformationDocumnet">
            <article>
              <aside>
                <p>
                  <strong>Document Number</strong>
                  <span>{gstNumber}</span>
                </p>
                <p>
                  <strong>CIN Number</strong>
                  <span>{cnnNumber}</span>
                </p>
              </aside>
              <aside>
                <div className="DocumnetBox">
                  <figcaption>
                    <span>
                    {gstFront ? 
                    gstFront.split(".")[gstFront.split(".").length -1] == "pdf" ? <>
                  
                    <a href={gstFront} target='_blank'>  <img src="images/PDF.png"/></a>
                    </>: <><img src={gstFront}/></>
                   : <>
                  </>}
                      {/* <img src={gstFront} /> */}
                    </span>
                    <label>Front</label>
                  </figcaption>
                  <figcaption>
                    <span>
                    {gstBack ? 
                    gstBack.split(".")[gstBack.split(".").length -1] == "pdf" ? <>
                  
                    <a href={gstBack} target='_blank'>  <img src="images/PDF.png"/></a>
                    </>: <><img src={gstBack}/></>
                   : <>
                  </>}
                      {/* <img src={gstBack}/> */}
                    </span>
                    <label>Back</label>
                  </figcaption>
                </div>
              </aside>
            </article>
          </div>
        </div>
      </div>
    </div>
    <div className="TopTittle">
      <h4>User Access</h4>
    </div>
    <div className="Small-Wrapper">
      {/* <div className="Filter">
        <div className="form-group">
          <label>Search</label>
          <input type="text" className="form-control" placeholder="Search" name='sear' />
        </div>
      </div> */}
      <div className="TableList">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Sl. No</th>
              <th>User ID</th>
              <th>User Name</th>
              <th>Contact Number</th>
              <th>Email Address</th>
              <th>Registraion Date</th>
              <th>Anchor Access</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {false ? <>
            <Loader/>
            </>
            :

            checkArray(userAccessListData) ? 
            userAccessListData?.map((ele,i) => {  
                    
              return(
                <>
  <tr>
              <td>{i+1}</td>
              <td>{ele?.user_number}</td>
              <td>{ele?.name}</td>
              <td>{ele?.phoneNumber}</td>
              <td>{ele?.email}</td>
              <td>{ele?.createdAt?.split("T")[0]}</td>
              {/* <td>{ele?.user_number}</td> */}
              <td>
                <a  onClick ={() =>goToAnchorAccessFunc(ele?._id)}className="view">
                  View
                </a>
              </td>
              <td>
                <div className="Actions">
                {ele?.userStatus === "ACTIVE" ? (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={true}
                                onChange={() =>
                                  showBlockModal(ele?._id, "INACTIVE")
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          ) : (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={false}
                                onChange={() =>
                                  showUnblockModal(ele?._id, "ACTIVE")
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          )}
                  {/* <a class="Green" href="anchor-edit.html">
                                      <i class="fa fa-pencil"></i>
                                  </a> */}
                                  <a
                    className="Red"
                     onClick={() => showDeleteModal(ele?._id)}
                  >
                    <i className="fa fa-trash" />
                  </a>
                </div>
              </td>
            </tr>
                </>
              )
            })
            : <p>No data found</p>
            }
          
       
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<Modal
        show={blockModal}
        onHide={hideBlockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideBlockModal}>
    ×
  </a>
  <h3>Are you sure you want to Block ? </h3>
  <div className="newBtn">
    <button
      className="Button active"
       onClick={() =>changeAnchorStatusFunc("ACTIVE")}
    >
      Yes
    </button>  &nbsp;  &nbsp; 
    <button className="Button" onClick={hideBlockModal}>
      No
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>


            
<Modal
        show={unblockModal}
        onHide={hideUnblockModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
        <div className="Category">
  <a href="javascript:void(0);" className="CloseModal" onClick={hideUnblockModal}>
    ×
  </a>
  <h3>Are you sure you want to Unblock ? </h3>
  <div className="newBtn">
    <button
      className="Button"
       onClick={() =>changeAnchorStatusFunc("INACTIVE")}
    >
      Yes
    </button>  &nbsp;  &nbsp; 
    <button className="Button" onClick={hideUnblockModal}>
      No
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>



      <Modal
        show={deleteModal}
        onHide={hideDeleteModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
         
     

<div className="Category">
  <button onClick={hideDeleteModal} className="CloseModal">×</button>
  <h3>Delete</h3>
  <figure>
    <img src="images/deleteAlert.png"  width={50}/>
  </figure>
  <p>Are you sure you want to Delete this Anchor ?</p>
  <h4>
  <button className="Button" onClick={removeAnchorFunc}>Delete</button>   &nbsp;  &nbsp; 
    <button onClick={hideDeleteModal} className="Button">Cancel</button> 
   
  </h4>
</div>


        </Modal.Body>
      </Modal>
   </>
  )
}

export default Anchordetails